import styles from "../MedicalNotes/MedicalNotes.module.scss";
import Input from "../../atoms/Input";
import Button from "../../atoms/Button";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { trpc } from "../../../utils/trpc";
import { useMemo, useState } from "react";
import Modal from "../Modal";
import FormHeader from "../../atoms/FormHeader";
import { raiseException } from "../../../utils/raiseException";
import { setInfo } from "../../../store/slices/infoSlice";
import { queryClient } from "../../../index";
import { useAppDispatch } from "../../../customHooks/reduxHooks";
import dayjs from "dayjs";

interface Props {
  patient?: any;
}

interface DocumentData {
  id: string;
  patientId: string;
  therapistId: string;
  when: string;
  type: string;
  content: string | null;
  fileLocation: string | null;
  filename: string | null;
}

export const MedicalNotes = ({patient}: Props) => {
  const [isModalUpload, setIsModalUpload] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>('');
  const [selectedYear, setSelectedYear] = useState<string>('');
  const [selectedMonth, setSelectedMonth] = useState<string>('');
  const [isModal, setIsModal] = useState<any>('');

  const dispatch = useAppDispatch();

  const addMedicalDocument = trpc.mainService.medicalDocuments.addMedicalDocument.useMutation();
  const deleteMedicalDocument = trpc.mainService.medicalDocuments.deleteMedicalDocument.useMutation();
  const getMedicalDocuments = trpc.mainService.medicalDocuments.getMedicalDocuments.useQuery(
    {therapistId: patient.therapistId, patientId: patient.id}
  ).data;

  const handleDocumentUpload = () => {
    setIsModalUpload(true)
  };

  const data: DocumentData[] = getMedicalDocuments?.documents?.note || [];
  

  const updateMedicalNotes = async () => {
    await addMedicalDocument.mutateAsync({
      therapistId: patient.therapistId,
      patientId: patient.id,
      type: 'note',
      content: notes,
    }).then(() => {
      queryClient.removeQueries();
      setIsModalUpload(false);
      setNotes('');
      dispatch(
        setInfo({
          text: "La nota è stata creata con successo",
          variant: "confirm",
        })
      );
    })
      .catch((e) => {
        raiseException(e);
        dispatch(
          setInfo({
            variant: "error",
            text: 'Si è verificato un errore durante la creazione della nota',
            title: "Error",
          })
        );
      });
  }

  const deleteMedicalNotes = async (id: string) => {
    await deleteMedicalDocument.mutateAsync({
      id: id
    }).then(() => {
      queryClient.removeQueries();
      setIsModal('');
      dispatch(
        setInfo({
          text: "La nota è stata eliminata con successo",
          variant: "confirm",
        })
      );
    })
      .catch((e) => {
        raiseException(e);
        dispatch(
          setInfo({
            variant: "error",
            text: 'Si è verificato un errore durante l\'eliminazione di una nota',
            title: "Error",
          })
        );
      });
  }

  const groupedDocuments = useMemo(() => {
    return data.reduce((acc: Record<string, DocumentData[]>, document: DocumentData) => {
      const date = dayjs(document.when);
      const monthYear = date.format('YYYY-MM');
      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }
      acc[monthYear].push(document);
      return acc;
    }, {});
  }, [data]);

  const documentsToShow = useMemo(() => {
    const sortedDocuments = Object.entries(groupedDocuments).reduce((acc, [, documents]) => {
      const [year, month] = documents[0].when.split('-');
      if (
        (!selectedYear || selectedYear === year) &&
        (!selectedMonth || selectedMonth === month)
      ) {
        acc.push(...documents);
      }
      return acc;
    }, [] as DocumentData[]);
    return sortedDocuments.sort((a, b) => dayjs(b.when).valueOf() - dayjs(a.when).valueOf());
  }, [groupedDocuments, selectedYear, selectedMonth]);

  const years = useMemo(() => Array.from(new Set(data?.map(doc => dayjs(doc.when).format('YYYY')))), [data]);
  const months = useMemo(() => Array.from(new Set(data?.map(doc => dayjs(doc.when).format('MM')))), [data]);

  return (
    <div className={styles.medicalNotesWrapper}>
      {isModalUpload ?
        <Modal close={() => {
          setIsModalUpload(false);
        }} modal>
          <FormHeader
            title="Nuova Nota"
            closeModal={() => {
              setIsModalUpload(false);
            }}
            closeButton
          />
          <Input
            placeholder="Nuova Nota"
            textarea
            value={notes}
            name="text"
            onChange={(e) => setNotes(e.target.value)}
          />
          <Button disabled={notes.length < 1} className="small" variant="secondary" leftIcon={<PencilSquareIcon/>}
                  onClick={updateMedicalNotes}>
            Salva
          </Button>
        </Modal> : isModal ?
          <Modal modal subClassName="max-width-40" close={() => {
            setIsModal('');
          }}>
            <FormHeader
              title="Siete sicuri di voler eliminare la nota?"
              closeModal={() => {
                setIsModal('');
              }}
              closeButton
            />
            <div className={styles.buttonsContainer}>
              <Button className={styles.medical} onClick={() => setIsModal('')}
                      variant="secondary">No, non voglio</Button>
              <Button className={styles.medical} onClick={async () => deleteMedicalNotes(isModal)}
              >Sì, voglio eliminare</Button>
            </div>
          </Modal> :
          <>
            <div className={styles.filters}>
              {data.length !== 0 &&
                <div className={`${styles.inputsWrapper} 'display-none'`}>
                  <Input
                    placeholder="Mese"
                    select
                    medical
                    options={[{
                      name: 'Tutti i mesi',
                      value: ''
                    }, ...months.map(month => ({
                      name: dayjs().month(parseInt(month, 10) - 1).format('MMMM').charAt(0).toUpperCase() + dayjs().month(parseInt(month, 10) - 1).format('MMMM').slice(1),
                  value: month
                }))]}
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
              />
              <Input
                placeholder="Anno"
                select
                medical
                options={[{name: "Tutti gli anni", value: ""}, ...years.map(year => ({name: year, value: year}))]}
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
              />
            </div>
            }
              <Button className="smallFilter" variant="secondary" leftIcon={<PencilSquareIcon/>}
                    onClick={handleDocumentUpload}>
              Nuova Nota
            </Button>
          </div>
          {data.length === 0 ? <div className={styles.noDocuments}>Non ci sono note</div> :
            <div className={styles.contentWrapper}>
            {documentsToShow && documentsToShow.map(({content, when, id}, index) => (
              <div className={styles.note} key={index}>
                <div className={styles.dateContainer}>
                  <div className={styles.dateWrapper}>
                    <div className={styles.name}>
                      {patient.given_name} {patient.family_name}
                    </div>
                    <div className={styles.date}>{dayjs(when).format('DD/MM/YYYY')}</div>
                  </div>
                  <div onClick={() => setIsModal(id)} className={styles.icon}><TrashIcon/></div>
                </div>
                <div className={styles.content}>{content}</div>
              </div>
            ))}
          </div>
          }
        </>
      }
    </div>
  )
};
