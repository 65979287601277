import {
  AdjustmentsHorizontalIcon,
  CreditCardIcon,
  IdentificationIcon,
  PlayIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/atoms/Button";
import UserPersonalInfo from "../../components/organisms/UserPersonalInfo";
import { trpc } from "../../utils/trpc";
import styles from "./UserProfile.module.scss";
import UserPaymentMethod from "../../components/organisms/UserPaymentMethod";
import UserPreferences from "../../components/organisms/UserPreferences";
import TherapistPreferences from "components/organisms/TherapistPreferences";
import TherapistPersonalInfo from "components/organisms/TherapistPersonalInfo";
import TherapistReview from "components/organisms/TherapistReview";
import TherapistPayment from "components/organisms/TherapistPayment";
import TherapistBillingDetails from "components/organisms/TherapistBillingDetails";
import { useEffect, useState } from "react";
import routes from "routes";
import { queryClient } from "index";
import { analyticsService } from "../../utils/analyticsService";

export const UserProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const [mobileSubMenuIsVisible, setMobileSubMenuIsVisible] = useState(true);

  useEffect(() => {
    analyticsService({'event': 'virtualPageview', 'pageUrl': '/dashboard/profilo', 'pageTitle': 'Profile'});
  }, []);

  const userInfo =
    trpc.mainService.user.getCurrentUserInfo.useQuery().data?.user;

  const getPathElement = () => {
    if (userInfo?.role === "patient") {
      switch (pathname) {
        case routes.ProfileSection.links.info:
          return <UserPersonalInfo />;
        case routes.ProfileSection.links.payment:
          return <UserPaymentMethod />;
        case routes.ProfileSection.links.preferences:
          return <UserPreferences />;
        default:
          return <UserPersonalInfo />;
      }
    } else {
      switch (pathname) {
        case routes.ProfileSection.links.info:
          return <TherapistPersonalInfo />;
        case routes.ProfileSection.links.payment:
          return <TherapistPayment />;
        case routes.ProfileSection.links.preferences:
          return <TherapistPreferences userInfo={userInfo}/>;
        case routes.ProfileSection.links.billing:
          return <TherapistBillingDetails />;
        case routes.ProfileSection.links.reviews:
          return <TherapistReview />;
        default:
          return <TherapistPersonalInfo />;
      }
    }
  };

  useEffect(() => {
    setMobileSubMenuIsVisible(pathname === routes.Profile.path);
  }, [pathname]);

  return (
    <div className={styles.userProfile}>
      {userInfo && (
        <div
          className={`${styles.sections} ${
            mobileSubMenuIsVisible && styles.visibleSection
          }`}
        >
          <div className={styles.topPart}>
            <div className={styles.userName}>
              {userInfo?.given_name} {userInfo?.family_name}
            </div>
            <div className={styles.userMail}>{userInfo?.email}</div>
            <Button
              onClick={() => {
                // document.cookie = `accessToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
                // document.cookie = `refreshToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
                localStorage.removeItem("accessToken");
                localStorage.removeItem("greyBlock");
                localStorage.removeItem("refreshToken");
                localStorage.removeItem("storedChat");
                localStorage.removeItem("userId");
                navigate(routes.Login.path);
                queryClient.removeQueries();
              }}
              variant="tertiary"
            >
              Esci
            </Button>
          </div>
          <div className={styles.bottomPart}>
            {userInfo?.role === "patient" ? (
              <ul>
                <li
                  className={
                    pathname === routes.ProfileSection.links.info ||
                    pathname === routes.Profile.path
                      ? styles.active
                      : ""
                  }
                  onClick={() => {
                    navigate(routes.ProfileSection.links.info);
                    setMobileSubMenuIsVisible(false);
                  }}
                >
                  <UserCircleIcon className={styles.icon} />
                  Informazioni personali
                </li>
                <li
                  className={
                    pathname === routes.ProfileSection.links.payment
                      ? styles.active
                      : ""
                  }
                  onClick={() => {
                    navigate(routes.ProfileSection.links.payment);
                    setMobileSubMenuIsVisible(false);
                  }}
                >
                  <CreditCardIcon className={styles.icon} />
                  Metodo di pagamento
                </li>
                <li
                  className={
                    pathname === routes.ProfileSection.links.preferences
                      ? styles.active
                      : ""
                  }
                  onClick={() => {
                    navigate(routes.ProfileSection.links.preferences);
                    setMobileSubMenuIsVisible(false);
                  }}
                >
                  <AdjustmentsHorizontalIcon className={styles.icon} />
                  Gestione preferenze
                </li>
              </ul>
            ) : (
              userInfo?.role === "therapist" && (
                <ul>
                  <li
                    className={
                      pathname === routes.ProfileSection.links.preferences
                        ? styles.active
                        : ""
                    }
                    onClick={() => {
                      navigate(routes.ProfileSection.links.preferences);
                      setMobileSubMenuIsVisible(false);
                    }}
                  >
                    <AdjustmentsHorizontalIcon className={styles.icon} />
                    Gestione preferenze
                  </li>
                  <li
                    className={
                      pathname === routes.ProfileSection.links.info ||
                      pathname === routes.Profile.path
                        ? styles.active
                        : ""
                    }
                    onClick={() => {
                      navigate(routes.ProfileSection.links.info);
                      setMobileSubMenuIsVisible(false);
                    }}
                  >
                    <UserCircleIcon className={styles.icon} />
                    Informazioni personali
                  </li>
                  {/* <li
                    className={
                      pathname === routes.ProfileSection.links.reviews
                        ? styles.active
                        : ""
                    }
                    onClick={() => {
                      navigate(routes.ProfileSection.links.reviews);
                      setMobileSubMenuIsVisible(false);
                    }}
                  >
                    <StarIcon className={styles.icon} />
                    Recensioni ricevute
                  </li> */}
                  <li
                    className={
                      pathname === routes.ProfileSection.links.billing
                        ? styles.active
                        : ""
                    }
                    onClick={() => {
                      navigate(routes.ProfileSection.links.billing);
                      setMobileSubMenuIsVisible(false);
                    }}
                  >
                    <IdentificationIcon className={styles.icon} />
                    Dati di fatturazione
                  </li>
                  <li
                    className={
                      pathname === routes.ProfileSection.links.payment
                        ? styles.active
                        : ""
                    }
                    onClick={() => {
                      navigate(routes.ProfileSection.links.payment);
                      setMobileSubMenuIsVisible(false);
                    }}
                  >
                    <CreditCardIcon className={styles.icon} />
                    Pagamenti
                  </li>
                </ul>
              )
            )}
            {userInfo?.role && userInfo?.role === "patient" && (
              <div
                className={styles.reapeatOnboarding}
                onClick={() => {
                  navigate("/dashboard/chat", { state: { onboarding: true } });
                }}
              >
                <div className={styles.icon}>
                  <PlayIcon />
                </div>
                Ripeti onboarding
              </div>
            )}
          </div>
        </div>
      )}
      <div
        className={`${styles.infoContainer} ${
          !mobileSubMenuIsVisible && styles.infoContainerIsVisible
        }`}
      >
        {userInfo?.role && getPathElement()}
      </div>
    </div>
  );
};
