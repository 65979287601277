import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Button from "../../components/atoms/Button";
import Input from "../../components/atoms/Input";
import GenericForm from "../../components/molecules/GenericForm";
import Modal from "../../components/organisms/Modal";
import {useAppDispatch} from "../../customHooks/reduxHooks";
import {setInfo} from "../../store/slices/infoSlice";
import {trpc} from "../../utils/trpc";
import styles from "./ChooseTherapist.module.scss";
import {useFormik} from "formik";
import * as yup from "yup";
import routes from "routes";
import {queryClient} from "index";
import { analyticsService } from "../../utils/analyticsService";
import chatting from "../../theme/images/sceltaPsicologo1.webp";
import interview from "../../theme/images/sceltaPsicologo2.webp";
import payment from "../../theme/images/sceltaPsicologo3.webp";
import meeting from "../../theme/images/sceltaPsicologo4.webp";
import { raiseException } from "../../utils/raiseException";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { deleteCookie, getTrackingCampaignUtmData } from "../../utils/cookies";

export const ChooseTherapist = ({className}: any) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const {state} = location;

	const [modalToggle, setModalToggle] = useState(false);
	const [changeEmail, setChangeEmail] = useState(false);
	const [newEmail, setNewEmail] = useState("");
	const [loadingToggle, setLoadingToggle] = useState(false);
	const [loading, setLoading] = useState(true);
	const [isChangeEmailLoading, setIsChangeEmailLoading] = useState(false);
	const [therapistsToggle, setTherapistsToggle] = useState(true);
	const [therapist, setTherapist] = useState<any>(null);

	const user: any = state

	const resendCode =
		trpc.mainService.user.resendEmailVerificationCode.useMutation();
	const changeUserEmail = trpc.mainService.user.changeEmail.useMutation();
	const therapistNotFoundEmail = trpc.mainService.user.notFoundTherapistEmail.useMutation();

	const chooseTherapists =
		trpc.mainService.patient.chooseTherapist.useMutation();

	const getCurrentUserInfoResponse =
		trpc.mainService.user.getCurrentUserInfo.useQuery();

	const getSuggestedTherapist =
		trpc.mainService.patient.getSuggestedTherapists.useQuery(undefined, {
			enabled: false,
		});

	const updateTrackingCampaign =
		trpc.mainService.tracking.updateTrackingCampaign.useMutation();

	const isPsychiatry = getCurrentUserInfoResponse?.data?.user.therapyType === "psychiatry"

	const formikChangeEmail = useFormik({
		initialValues: {
			email: "",
			repeatEmail: "",
		},
		validationSchema: yup.object().shape({
			email: yup
				.string()
				.trim()
				.matches(
					/^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i,
					"L'email inserita ha un formato non valido"
				)
				.required("L'email è un campo obbligatorio"),
			repeatEmail: yup
				.string()
				.trim()
				.matches(
					/^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i,
					"L'email inserita ha un formato non valido"
				)
				.oneOf([yup.ref("email")], "Le email non combaciano")
				.required("Devi reinserire l'email per poter continuare"),
		}),
		onSubmit: (values, actions) => {
			setIsChangeEmailLoading(true);
			changeUserEmail
				.mutateAsync({
					email: values.email,
				})
				.then(() => {
					setChangeEmail(false);
					setNewEmail(values.email);
					navigate(location.pathname, {
						state: {
							email: values.email,
						},
					});
					setIsChangeEmailLoading(false);
				})
				.catch((e) => {
					raiseException(e);
					if (e?.shape?.errorCode === "EmailAlreadyExists") {
						formikChangeEmail.setErrors({
							email: " ",
							repeatEmail: "L'email inserita è già in uso",
						});
					} else {
						dispatch(
							setInfo({
								variant: "error",
								text: "Si è verificato un errore",
							})
						);
					}
					setIsChangeEmailLoading(false);
				});
		},
	});

  useEffect(() => {
    analyticsService({'event': 'virtualPageview', 'pageUrl': '/pagina-di-benvenuto', 'pageTitle': 'Choose Therapist'});
  }, []);

	const confirmEmailFunction = async () => {
		const result = await getCurrentUserInfoResponse.refetch();
		if (!result.data?.user.emailVerified) {
			dispatch(
				setInfo({
					text: "l'email risulta non ancora verificata",
					variant: "error",
				})
			);
			return;
		} else {
				await getTherapist()
		}

		setModalToggle(false);
		navigate(location.pathname, {});
	};

	const resendCodeFunction = () => {
		resendCode
			.mutateAsync()
			.then(() => {
				dispatch(
					setInfo({
						text: "L'email di verifica è stata inviata con successo",
						variant: "confirm",
					})
				);
			})
			.catch((e) => {
				raiseException(e);
				dispatch(
					setInfo({
						text: "Si è verificato un errore durante l'invio dell'email di verifica",
						variant: "error",
					})
				);
			});
	};

	useEffect(() => {
		if (therapistsToggle) {
			if (getCurrentUserInfoResponse.status === "loading") {
				setLoadingToggle(true);
			} else if (
				!getCurrentUserInfoResponse.isFetching &&
				getCurrentUserInfoResponse.status === "success"
			) {
				setLoadingToggle(false);
				setTherapistsToggle(false);
				updateCurrentTrackingCampaign();
			}
		}
	}, [
		getCurrentUserInfoResponse,
		therapistsToggle,
		getCurrentUserInfoResponse.isFetching,
	]);

	useEffect(() => {
		if (
			!getCurrentUserInfoResponse?.data?.user?.therapistId &&
			getCurrentUserInfoResponse?.data?.user?.emailVerified &&
			getSuggestedTherapist.status === "success" &&
			!!getSuggestedTherapist?.data) {
				therapistNotFoundEmail.mutateAsync().catch((e) => {
					raiseException(e);
					console.error(e);
				});
		}
	}, []);

	useEffect(() => {
		if (!therapistsToggle) {
		(async () => {
			const user = await getCurrentUserInfoResponse.refetch();
			if (user?.data?.user?.emailVerified) {
				await getTherapist()
			}
			}
		)()
		}
	}, [therapistsToggle])

	const getTherapist = async () => {
		setLoading(true)
		const result = await getCurrentUserInfoResponse.refetch();
		setTherapist(result?.data?.user.therapistId)
		if (!result?.data?.user.therapistId) {
			const {data} = await getSuggestedTherapist.refetch();
			if (data?.suggestedTherapists?.length) {
				try {
					const therapist: any = data?.suggestedTherapists[0];
					setTherapist(therapist.id)
					const {assignedTherapist} = await chooseTherapists.mutateAsync({therapistId: therapist.id, therapistChange: false});
					if (assignedTherapist.id) {
						queryClient.removeQueries();
						await getCurrentUserInfoResponse.refetch();
					}
				} catch (error: any) {
					raiseException(error);
					dispatch(
						setInfo({
							variant: "error",
							text: error.message,
							title: "Errore scelta terapeuta",
						})
					);
				} finally {
					setLoading(false)
				}
			} else {
				setTherapist(null)
				setLoading(false)
			}
		} else {
			setLoading(false)
		}
	};

	const updateCurrentTrackingCampaign = async () => {
		if (getCurrentUserInfoResponse?.data?.user?.role === 'patient' && getCurrentUserInfoResponse?.data?.user?.id) {
			if (state?.campaignId) {
				await updateCampaign(state?.campainId, getCurrentUserInfoResponse?.data?.user?.id);
			} else {
				const campaignId = localStorage.getItem("campaignId");
				if (campaignId) {
					await updateCampaign(campaignId, getCurrentUserInfoResponse?.data?.user?.id);
				}
			}
			localStorage.removeItem("campaignId");
			const utmData: any = getTrackingCampaignUtmData('utm_data');
			if (utmData?.utm_medium && utmData?.utm_source && utmData?.utm_campaign) {
				deleteCookie('utm_data');
			}
		}
	}

	const updateCampaign = async (id: string, patientId: string) =>{
		return await updateTrackingCampaign.mutateAsync({id, patientId})
	}

	useEffect(() => {
		if (user && !user?.emailVerified) {
			setModalToggle(true);
		} else {
			setModalToggle(false);
		}
	}, [user]);

	return (
		<div className={`${styles.chooseTherapist} ${className}`}>
			{
				loading && (
					<Modal>
						<Spin
							indicator={
								<LoadingOutlined color={'#3576bb'} style={{fontSize: 32}} spin/>
							}
						/>
					</Modal>
				)
			}
			{
				modalToggle && (
					<Modal className={styles.chooseTherapistModal}>
						{!changeEmail ? (
							<GenericForm
								className={styles.confirmCodeForm}
								title={"Conferma la tua mail"}
								description={`Abbiamo inviato una mail a ${
									newEmail || (typeof user === "string" ? user : user?.email)
							}. Per proseguire, apri il link che trovi al suo interno.`}
							cta={[
								<div>
									<Button
										className={styles.confirmCodeBtn}
										onClick={() => confirmEmailFunction()}
									>
										Ho cliccato sul link
									</Button>
									<div className={styles.confirmCodeBtn}>
										Non hai ricevuto l'email?{" "}
										<span
											className={styles.spanBtn}
											onClick={() => resendCodeFunction()}
										>
                      Invia un'altra email
                    </span>
									</div>
									<div className={styles.confirmCodeBtn}>
										L’email è sbagliata?{" "}
										<span
											className={styles.spanBtn}
											onClick={() => {
												setNewEmail("");
												setChangeEmail(true);
											}}
										>
                      Modifica l’indirizzo
                    </span>
									</div>
									<div className={styles.confirmCodeBtn}>
										Non è il tuo account?{" "}
										<span
											className={styles.spanBtn}
											onClick={() => {
												// document.cookie = `accessToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
												// document.cookie = `refreshToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
												localStorage.removeItem("accessToken");
												localStorage.removeItem("greyBlock");
												localStorage.removeItem("refreshToken");
												localStorage.removeItem("storedChat");
												localStorage.removeItem("userId");
												navigate(routes.Login.path);
												queryClient.removeQueries();
											}}
										>
                      Esci
                    </span>
									</div>
									<div style={{textAlign: "center"}}>
										Se non trovi la mail controlla nella casella Spam.
									</div>
								</div>,
							]}
						/>
					) : (
						<GenericForm
							className={styles.confirmCodeForm}
							title={'Inserisci un altro indirizzo email'}
							description={'Scegline uno che utilizzi spesso. Ti chiederemo di verificarlo.'}
							answer={[
								<form
									className={styles.changeEmailForm}
									onSubmit={formikChangeEmail.handleSubmit}
								>
									<div className={styles.formInputs}>
										<Input
											type="email"
											name="email"
											value={formikChangeEmail.values.email}
											onChange={formikChangeEmail.handleChange}
											error={
												(formikChangeEmail.errors.email &&
													formikChangeEmail.touched.email &&
													formikChangeEmail.errors.email) as string
											}
											placeholder="Inserisci la tua mail"
										/>
										<Input
											type="email"
											name="repeatEmail"
											value={formikChangeEmail.values.repeatEmail}
											onChange={formikChangeEmail.handleChange}
											error={
												(formikChangeEmail.errors.repeatEmail &&
													formikChangeEmail.touched.repeatEmail &&
													formikChangeEmail.errors.repeatEmail) as string
											}
											placeholder="Scrivila di nuovo"
										/>
									</div>
									<div className={styles.formBtnWrapper}>
										<Button
											className={styles.confirmCodeBtn}
											type="submit"
											disabled={isChangeEmailLoading}
										>
											Conferma
										</Button>
										<Button
											className={styles.confirmCodeBtn}
											variant="tertiary"
											onClick={async () => {
												formikChangeEmail.setValues({
													email: "",
													repeatEmail: "",
												});
												formikChangeEmail.setErrors({
													email: undefined,
													repeatEmail: undefined,
												});
												formikChangeEmail.setTouched({
													email: false,
													repeatEmail: false,
												});
												setChangeEmail(false);
											}}
										>
											Torna indietro
										</Button>
									</div>
								</form>,
							]}
						/>
					)}
				</Modal>
			)}
			<div className={styles.welcomeWrapper}>
				{therapist && !loading && (
				<div className={styles.welcome}>
					<div className={styles.description}>
						<div className={styles.descriptionWrapper}>
								<img
									src={chatting}
									alt='chatting icon'
									className={`${styles.icon} ${styles.first}  ${isPsychiatry && styles.psychiatry}`}
								/>
							<label>Potrai parlare con il tuo {isPsychiatry ? 'psichiatra ' : 'terapeuta '}
								in una <span className={styles.price}>chat privata.</span></label>
						</div>
						<div className={styles.descriptionWrapper}>
								<img
									src={isPsychiatry ? meeting : interview}
									alt='interview icon'
									className={styles.icon}
								/>
							{isPsychiatry ?
								<label>Potrei effettuare comodamente le visite <span className={styles.price}>in ogni luogo e in ogni momento.</span></label> :
								<label>Il primo colloquio è gratuito e sarai tu a decidere se proseguire, a soli
									<span
										className={styles.price}>{getCurrentUserInfoResponse?.data?.user.therapyType === 'couple' ? ' 60€ ' : ' 50€ '}</span>
									a seduta.
								</label>
							}
						</div>
						<div className={styles.descriptionWrapper}>
							<img
								src={payment}
								alt='payment icon'
								className={styles.icon}
							/>
							<label>
								Potrai gestire pagamenti e {isPsychiatry ? 'visite' : 'sedute'} in
								<span className={styles.price}> autonomia.</span>
							</label>
						</div>
					</div>
					<div className={styles.btnWrapper}>
					<Button
						className={styles.soloBtn}
						onClick={() => {
							navigate(routes.PatientBookingFirstAppointment.path)
						}}
					>
						Prosegui
					</Button>
					</div>
				</div>
				)}
				{
					!loading && !therapist && (
						<div className={styles.welcome}>
							<div>
								Non siamo riusciti a trovare un {isPsychiatry ? 'professionista' : 'terapeuta'} per te, chiedi aiuto,
								scrivi a <a href="mailto:clientsupport@mymentis.it">clientsupport@mymentis.it</a>
								{!isPsychiatry && (
									<span> E possiamo trovarti un terapista.</span>
								)}
							</div>
						</div>
					)}
			</div>
		</div>
	);
};
