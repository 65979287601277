import {StarIcon as EmptyStar} from "@heroicons/react/24/outline";
import {StarIcon as FilledStar} from "@heroicons/react/24/solid";
import FormHeader from "components/atoms/FormHeader";
import Modal from "components/organisms/Modal";
import { useState } from "react";
import {trpc} from "../../../utils/trpc";
import Button from "../../atoms/Button";
import styles from "./DocInfo.module.scss";
import Input from "components/atoms/Input";
import {useAppDispatch} from "customHooks/reduxHooks";
import {setInfo} from "store/slices/infoSlice";
import TherapistFeedbackModal from "../../organisms/TherapistFeedbackModal";
import ModalCurrentTherapist from "../../organisms/ModalCurrentTherapist";
import { raiseException } from "../../../utils/raiseException";
import { getParsedCPS } from "../../../utils/getParsedCPS";

export const DocInfo = ({patient}) => {
	const dispatch = useAppDispatch();

	const [reviewModalIsOpen, setReviewModalIsOpen] = useState(false);
	const [reviewPoint, setReviewPoint] = useState(0);
	const [reviewTitle, setReviewTitle] = useState("");
	const [reviewText, setReviewText] = useState("");
	const [specToShow, setSpecToShow] = useState(3);
	const [showFeedbackModal, setShowFeedbackModal] = useState(false);
	const [showCurrentTherapistModal, setShowCurrentTherapistModal] =
		useState(false);

	const myTherapist =
		trpc.mainService.patient.getMyTherapist.useQuery();

	const addReview = trpc.mainService.review.addReview.useMutation();
	const interventionAreas = myTherapist?.data?.therapist?.specializations || [];
	const isTherapistPsychiatry = myTherapist?.data?.therapist?.therapyType?.includes('psychiatry');

	const sendReview = () => {
		if (
			reviewPoint !== 0 &&
			reviewText.trim() !== "" &&
			reviewTitle.trim() !== ""
		) {
			addReview
				.mutateAsync({
					content: reviewText,
					rating: reviewPoint,
					therapistId: myTherapist?.data?.therapist?.id!,
					title: reviewTitle,
				})
				.then((data) => {
					setReviewModalIsOpen(false);
					dispatch(
						setInfo({
							text: "La tua recensione è stata inviata",
							variant: "confirm",
						})
					);
				})
				.catch((e) => {
					raiseException(e);
					console.error("ERROR", e?.shape?.message)
				});
		} else {
			dispatch(
				setInfo({
					title: "Informazioni mancanti",
					text: "Completa tutti i campi per poter inviare la recensione",
					variant: "error",
				})
			);
		}
	};

	return (
		<div className={styles.docInfo}>
			{reviewModalIsOpen && (
				<Modal>
					<div className={styles.reviewModal}>
						<FormHeader
							closeModal={() => setReviewModalIsOpen(false)}
							title="Ti va di lasciare una recensione?"
							closeButton
						/>
						<div className={styles.description}>
							Adesso che hai svolto alcune sedute di terapia sarebbe utile per
							noi avere una tua recensione riguardo al professionista che ti sta
							seguendo nel tuo percorso. Le recensioni ci aiutano a fornire la
							migliore esperienza possibile.
						</div>
						<div className={styles.reviewForm}>
							<div className={styles.starContainer}>
								{Array(5)
									.fill(<EmptyStar/>)
									.map((i, index) => {
										if (index < reviewPoint) {
											return (
												<div
													className={styles.star}
													onClick={() => setReviewPoint(index + 1)}
												>
													<FilledStar/>
												</div>
											);
										} else {
											return (
												<div
													className={styles.star}
													onClick={() => setReviewPoint(index + 1)}
												>
													{i}
												</div>
											);
										}
									})}
								<div>{reviewPoint}/5</div>
							</div>
							<Input
								value={reviewTitle}
								onChange={(e) => setReviewTitle(e.target.value)}
								placeholder="Titolo recensione"
							/>
							<Input
								textarea
								value={reviewText}
								onChange={(e) => setReviewText(e.target.value)}
								placeholder="Scrivi qui la tua recensione"
							/>
							<div className={styles.btnWrapper}>
								<Button
									variant="tertiary"
									onClick={() => setReviewModalIsOpen(false)}
								>
									Più tardi
								</Button>
								<Button onClick={() => sendReview()}>Invia recensione</Button>
							</div>
						</div>
					</div>
				</Modal>
			)}
			{showFeedbackModal && (
				<TherapistFeedbackModal
					myTherapist={myTherapist?.data?.therapist}
					gender={patient?.gender}
					modalClose={() => setShowFeedbackModal(false)}
				/>
			)}
			{(showCurrentTherapistModal && myTherapist?.data?.therapist) &&
				<ModalCurrentTherapist
					className="modalWithSmallHeader"
					therapist={myTherapist?.data?.therapist}
					modalClose={() => setShowCurrentTherapistModal(false)}
					goToFeedbackModal={() => setShowFeedbackModal(true)}
				/>
			}
			<div className={styles.interventionArea}>
				<div className={styles.title}>Aree d'intervento</div>
				<div className={styles.areasList}>
					{interventionAreas.length &&
						getParsedCPS(isTherapistPsychiatry, interventionAreas)?.map(
							(interventionArea, index: number) => {
								if (index < specToShow)
									return (
										<Button
											key={index}
											variant="secondary"
											className={styles.areasBtn}
										>
											{interventionArea}
										</Button>
									);
							}
						)}
					{getParsedCPS(isTherapistPsychiatry, interventionAreas).length > 3 && specToShow === 3 && (
						<Button
							onClick={() => setSpecToShow(getParsedCPS(isTherapistPsychiatry, interventionAreas).length)}
							variant="secondary"
							className={styles.areasBtn}
						>
							+ {interventionAreas && getParsedCPS(isTherapistPsychiatry, interventionAreas).length - 3}
						</Button>
					)}
					{getParsedCPS(isTherapistPsychiatry, interventionAreas).length > 3 && specToShow > 3 && (
						<Button
							variant="secondary"
							className={styles.areasBtn}
							onClick={() => setSpecToShow(3)}
						>
							-
						</Button>
					)}
				</div>
				<span className={styles.therapistTitle}>{myTherapist?.data?.therapist?.title}</span>
				<div className={styles.aboutDoc}>
					<div className={styles.aboutTitle}>
						Iscritt{myTherapist?.data?.therapist?.gender === 'male' ? 'o' : 'a'} {isTherapistPsychiatry ? 'all’Ordine dei Medici' : `all'albo degli psicologi`}
					</div>
					<div className={styles.aboutText}>
						Regione {myTherapist?.data?.therapist?.licenceRegisterPlace}
					</div>
				</div>
				{myTherapist?.data?.therapist?.therapyType.includes('psychiatry') ? (
					<div className={styles.aboutDoc}>
						<div className={styles.aboutTitle}>
							Laurea in Medicina
						</div>
						<div className={styles.aboutText}>
							{myTherapist?.data?.therapist?.graduationCourse}
						</div>
						<div className={styles.aboutTitle}>
							Specializzazione in Psichiatria
						</div>
						<div className={styles.aboutText}>
							{myTherapist?.data?.therapist?.graduationPlace}
						</div>
					</div>
				) : (
					<div className={styles.aboutDoc}>
						<div className={styles.aboutTitle}>
							Laureat{myTherapist?.data?.therapist?.gender === "male" ? "o" : "a"} in
						</div>
						<div className={styles.aboutText}>
							{myTherapist?.data?.therapist?.graduationCourse} presso{" "}
							{myTherapist?.data?.therapist?.graduationPlace}
						</div>
					</div>
				)}
				{/* <div className={styles.aboutDoc}>
          <div className={styles.aboutTitle}>Qualcosa su di me</div>
          <div className={styles.aboutText}>{myTherapist?.description}</div>
        </div> */}
			</div>
			{/* TODO: feature da implementrare in un secondo momento?*/}
			{/* <div className={styles.phoneNumber}>
        <span>Whatsapp</span>
        <div>
          <div className={styles.whatsappIcon}>
            <WhatsAppOutlined />
          </div>
          <div>+39 340 123 4894</div>
        </div>
      </div> */}
			{/* <div className={styles.insertReview}> TODO: lasciare nascoste le recensioni per un primo momento
        <div className={styles.icon}>
          <EmptyStar />
        </div>
        <Button variant="tertiary" onClick={() => setReviewModalIsOpen(true)}>
          Lascia una recensione
        </Button>
      </div> */}
			<div className={styles.buttonCenter}>
				{(!patient?.changeTherapist && !isTherapistPsychiatry) && (
					<Button
						onClick={() => {
							setShowCurrentTherapistModal(true);
						}}>
						Scegli un nuovo terapeuta
					</Button>
				)}
			</div>
		</div>
	);
};
