import styles from './TherapistUploadFirma.module.scss'
import Button from '../../atoms/Button';
import { useEffect, useState } from 'react';
import { trpc } from '../../../utils/trpc';
import { queryClient } from '../../../index';
import { setInfo } from '../../../store/slices/infoSlice';
import { useAppDispatch } from '../../../customHooks/reduxHooks';
import { raiseException } from '../../../utils/raiseException';
import Input from '../../atoms/Input';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { envs } from '../../../utils/envs';
import { Document, Page } from 'react-pdf';


interface Props {
  userInfo: any;
  setIsModalOpen?: any;
  signatureName: any;
  isModal?: any;
  setTherapistToggle?: any;
}


export const TherapistUploadFirma = ({userInfo, setIsModalOpen, signatureName, isModal, setTherapistToggle}: Props) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [filePreviewUrl, setFilePreviewUrl] = useState<any>(null);
  const [content, setContent] = useState<JSX.Element | null>(null);

  const dispatch = useAppDispatch();

  const generateUploadPicUrl =
    trpc.mainService.therapist.generateUploadPicUrl.useMutation();

  const uploadSignature = trpc.mainService.medicalDocuments.uploadSignature.useMutation();

  const uploadSign = async (file: any) => {
    if (!file) return;

    const therapistEmail = userInfo.email;
    const therapistId = userInfo.id;

    if (therapistEmail && therapistId) {
      try {
        const {url} = await generateUploadPicUrl.mutateAsync({
          folder: 'docs',
          email: therapistEmail,
          userRegistered: true,
          fileName: therapistId
        });

        await fetch(url, {
          method: 'PUT',
          body: file,
        });

        await uploadSignature.mutateAsync({
          newFileName: `docs/file-${userInfo.id}`,
          userId: therapistId
        }).then(
          () => {
            queryClient.removeQueries();
            setSelectedFile(null);
            setFilePreviewUrl(null);
            isModal && setIsModalOpen(false);
            isModal && setTherapistToggle(true);
            dispatch(
              setInfo({
                text: 'Firma caricata con successo',
                variant: 'confirm',
              })
            );
          }
        )
      } catch (error) {
        console.log('Error: ', error)
        raiseException(error);
        dispatch(
          setInfo({
            variant: 'error',
            text: 'Si è verificato un errore durante il caricamento della firma',
            title: 'Error',
          })
        );
      }
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setSelectedFile(file);

    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setFilePreviewUrl(fileUrl);
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      await uploadSign(selectedFile);
    }
  };

  const getFileType = async (url: string): Promise<string | null> => {
    try {
      const response = await fetch(url, {method: 'HEAD'});
      const contentType = response.headers.get('Content-Type');
      return contentType;
    } catch (error) {
      console.error('Error fetching file type:', error);
      return null;
    }
  };

  const renderDocumentOrImage = async (url: string) => {
    const fileType = await getFileType(url);

    if (!fileType) return <p>Could not load file</p>;

    if (fileType === 'application/pdf') {
      return (
        <Document
          loading={
            <Spin
              className={styles.spinner}
              indicator={<LoadingOutlined style={{fontSize: 32, color: '#3576bb'}} spin/>}
            />
          }
          file={url}
        >
          <Page
            className={styles.page}
            pageNumber={1}
            width={150}
            loading=""
          />
        </Document>
      );
    } else if (fileType.startsWith('image/')) {
      return <img src={url} alt="document"/>;
    } else {
      return <p>Unsupported file type</p>;
    }
  };

  useEffect(() => {
    if (signatureName && !content) {
      const fetchFileType = async () => {
        const renderedContent = await renderDocumentOrImage(`${envs.REACT_APP_S3_ENDPOINT}/${signatureName}`);
        setContent(renderedContent);
      };

      fetchFileType();
    }
  }, [signatureName]);

  return (
    <>
      {!signatureName && (
        <div className={styles.content}>
          <Input
            label={isModal ? '' : 'Aggiungere la Firma'}
            sublabel="La foto deve essere in formato png oppure jpeg, pdf"
            type="file"
            accept="image/png, image/jpeg, application/pdf"
            onChange={handleFileChange}
          />
          {selectedFile && (
            <div className={styles.fileWrapper}>
              {selectedFile.type === 'application/pdf' ? (
                <Document file={filePreviewUrl} loading={
                  <Spin
                    className={styles.spinner}
                    indicator={<LoadingOutlined style={{fontSize: 32, color: '#3576bb'}} spin/>}
                  />
                }>
                  <Page pageNumber={1} width={75} loading=""/>
                </Document>
              ) : (
                <img src={filePreviewUrl} alt={selectedFile.name} width={75}/>
              )}
              <p>Nome file: {selectedFile.name}</p>
              <p>Dimensione: {(selectedFile.size / 1024).toFixed(2)} KB</p>
              <Button leftIcon={<ArrowDownTrayIcon/>} variant="secondary"
                      onClick={handleUpload}>Salva file</Button>
            </div>
          )}
        </div>
      )}
      {signatureName && content && (
        <div className={styles.firmaFile}>
          <a href={`${envs.REACT_APP_S3_ENDPOINT}/${signatureName}`} target="_blank" rel="noopener noreferrer">
            {content}
          </a>
          <Button variant="secondary" onClick={() => setIsModalOpen(true)}>
            Rimuovere la Firma
          </Button>
        </div>
      )}
    </>
  )
}
