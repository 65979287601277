import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import Button from "components/atoms/Button";
import FormHeader from "components/atoms/FormHeader";
import Input from "components/atoms/Input";
import { Dispatch, useEffect, useRef, useState } from 'react';
import styles from "./Faq.module.scss";
import Modal from "components/organisms/Modal";
import { trpc } from "utils/trpc";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { setInfo } from "store/slices/infoSlice";
import { useAppDispatch } from "customHooks/reduxHooks";
import { analyticsService } from "../../utils/analyticsService";
import { raiseException } from "../../utils/raiseException";
import { envs } from "../../utils/envs";


const therapistContactReasons = [
  "Sono un terapeuta e vorrei aprire una segnalazione",
  // "Per eliminare il profilo è necessario chiedere al Supporto.",
  "Altro",
];
const psychiatristContactReasons = [
  "Sono uno psichiatra e vorrei aprire una segnalazione",
  "Altro",
];
const patientContactReasons = [
  "Ho bisogno di assistenza per problemi relativi a pagamenti o fatturazione",
  "Ho bisogno di assistenza per problemi tecnici",
  "Ho bisogno di assistenza per ottenere dei chiarimenti riguardo ai servizi che offrite",
  "Ho bisogno di assistenza per ottenere dettagli sul processo di utilizzo dei servizi",
  "Vorrei lasciare feedback e/o condividere suggerimenti",
  "Vorrei presentare un reclamo",
  "Altro",
];

const ContactUsForm = ({
  setModalIsOpen,
}: {
  setModalIsOpen?: Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [reason, setReason] = useState("");
  const [content, setContent] = useState("");
  const dispatch = useAppDispatch();

  const getCurrentUserInfoResponse =
    trpc.mainService.user.getCurrentUserInfo.useQuery();

  const requestSupport = trpc.mainService.requestSupport.useMutation();

  return (
    <div className={styles.contactUs}>
      {requestSupport.isLoading && (
        <Modal className="loadingModal">
          <Spin
            indicator={
              <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
            }
          />
        </Modal>
      )}
      {getCurrentUserInfoResponse.data && (
        <div className={styles.inputContainer}>
          <Input
            select
            options={
              getCurrentUserInfoResponse.data.user.role === "therapist"
                ? getCurrentUserInfoResponse.data?.user.therapies?.includes('psychiatry') ? 
                  psychiatristContactReasons.map((reason) => ({
                    value: reason,
                    name: reason,
                  }))
                : therapistContactReasons.map((reason) => ({
                    value: reason,
                    name: reason,
                  }))
                : patientContactReasons.map((reason) => ({
                    value: reason,
                    name: reason,
                  }))
            }
            onChange={(e) => {
              setReason(e.target.value);
            }}
          />
          <Input
            textarea
            value={content}
            onChange={(e) => {
              setContent(e.target.value);
            }}
          />
          <Button
            disabled={!content}
            onClick={async () => {
              try {
                await requestSupport.mutateAsync({
                  givenName: getCurrentUserInfoResponse.data?.user.given_name!,
                  familyName:
                    getCurrentUserInfoResponse.data?.user.family_name!,
                  email: getCurrentUserInfoResponse.data?.user.email!,
                  reason,
                  content,
                  fromWhere:
                    getCurrentUserInfoResponse.data?.user?.role === "therapist"
                      ? "therapistDashboard"
                      : "patientDashboard",
                });
                setContent("");
                if (setModalIsOpen) {
                  setModalIsOpen(false);
                }
                dispatch(
                  setInfo({
                    variant: "confirm",
                    text: "Richiesta di supporto inviata con successo",
                  })
                );
              } catch (e: any) {
                raiseException(e);
                console.error("ERROR", e?.shape?.message);
                dispatch(
                  setInfo({
                    variant: "error",
                    text: "Si è verificato un errore durante l'invio della richiesta di supporto",
                  })
                );
              }
            }}
          >
            Invia richiesta
          </Button>
        </div>
      )}
    </div>
  );
};

export const Faq = () => {
  const contactUsRef = useRef<any>(null);

  const [actaualQuestion, setActualQuestion] = useState("");
  const [actualAnswer, setActualAnswer] = useState<any>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [guidelines, setGuidelines] = useState<string>('');

  const getCurrentUserInfoResponse = trpc.mainService.user.getCurrentUserInfo.useQuery();
  const { data: therapistPreferences, refetch: refetchPreferences } = trpc.mainService.therapist.getPreferences.useQuery();

  const isPsychiatry = getCurrentUserInfoResponse.data?.user.therapies?.includes('psychiatry')

  useEffect(() => {
    analyticsService({ 'event': 'virtualPageview', 'pageUrl': '/dashboard/faq', 'pageTitle': 'Dashboard Faq' });
    if (therapistPreferences) {
      setGuidelines(therapistPreferences.preferences?.['guideline_name']);
    }
  }, [therapistPreferences]);

  let selectedFaq: {
    question: string,
    answer: string | JSX.Element
  }[];

  if (getCurrentUserInfoResponse.data?.user.role === 'therapist'
    && isPsychiatry) {
      // Psychiatrist
      selectedFaq = [
        {
          question: "Impostare il calendario per le visite",
          answer: (
            <div>
              <strong>
                Come impostare la propria disponibilità sul calendario per le visite
              </strong>
              <p>
                Al termine della registrazione e quindi prima ancora di accedere alla chat, ogni cliente può selezionare in autonomia uno slot fra quelli dati come disponibili dallo psichiatra a cui è stato abbinato, per effettuare la visita.
              </p>
              <br/>
              <p>
                Pertanto, ogni 15 giorni il professionista dovrà impostare il calendario per le visite e in relazione alle proprie disponibilità/impegni dovrà aggiornarlo. <strong>NB:</strong> Tale calendario, infatti, dovrà essere aggiornato ogni qualvolta il professionista avrà un nuovo appuntamento/impegno al di fuori di Mymentis, che potrebbero accavallarsi agli slot dati come disponibili per le visite. 
              </p>
              <br/>
              <br/>
              <strong>
                Il professionista non deve aggiornare il calendario delle visite rispetto agli appuntamenti presi su Mymentis?
              </strong>
              <p>
                Esatto. Il sistema aggiorna automaticamente le disponibilità del calendario delle visite per tutti gli appuntamenti presi su Mymentis.
              </p>
              <br/>
              <p>
                Inoltre, all'interno del proprio profilo sulla piattaforma, ogni professionista troverà un banner che gli ricorderà di impostare/aggiornare tale calendario (nella sezione Appuntamenti &gt; Slot Occupati).
              </p>
            </div>
          ),
        },
        {
          question: "Generare e inviare le prescrizioni mediche",
          answer: (
            <div>
              <p>
                Per inviare una prescrizione medica, segui questi semplici passaggi:
              </p>
              <ol>
                <li>
                  <strong>Selezione del paziente:</strong>
                  <br/>
                  <p>
                    Accedi al profilo del paziente a cui desideri inviare la prescrizione.
                  </p>
                </li>
                <li>
                  <strong>
                    Creazione della prescrizione:
                  </strong>
                  <br/>
                  <ul>
                    <li>
                      Vai alla sezione <i>Documenti</i> e seleziona <i>Prescrizioni</i>.
                    </li>
                    <li>
                      Clicca su <i>Nuova Prescrizione</i> per iniziare la procedura.
                    </li>
                    <li>
                      Inserisci i farmaci prescritti e i relativi dosaggi, quindi clicca su <i>Prosegui</i>.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Firma della prescrizione:</strong>
                  <br/>
                  <ul>
                    <li>
                      Se hai già caricato la firma digitale, la prescrizione verrà firmata automaticamente senza ulteriori richieste e potrai direttamente scaricare il documento.
                    </li>
                    <li>
                      Se non hai ancora inserito la firma digitale, ti verrà chiesto se desideri aggiungerla.
                    </li>
                    <li>
                      In alternativa, puoi stampare la prescrizione, firmarla manualmente e successivamente scansionarla.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Invio della prescrizione al paziente:</strong>
                  <br/>
                  <ul>
                    <li>
                      Apri la chat con il paziente selezionato.
                    </li>
                    <li>
                      Clicca sull'icona della graffetta e allega la prescrizione precedentemente scaricata.
                    </li>
                  </ul>
                </li>
              </ol>
              <p>
                In questo modo, la prescrizione verrà inviata al paziente in modo rapido e semplice.
              </p>
            </div>
          ),
        },
        {
          question: "Problemi tecnici download allegati",
          answer: (
            <div>
              <p>
                In base al dispositivo utilizzato o alla velocità della connessione Internet, l'invio della prescrizione tramite chat potrebbe richiedere più tempo del previsto. Se l'allegato non risulta inviato, suggeriamo di:
              </p>
              <ol>
                <li>
                  Controllare la connessione Internet e assicurarsi che sia stabile.
                </li>
                <li>
                  Ricaricare la pagina o riaprire l'app e riprovare ad allegare il documento.
                </li>
                <li>
                  Provare a inviare l'allegato da un altro dispositivo per verificare se il problema è legato al dispositivo che si sta utilizzando.
                </li>
              </ol>
              <p>
                Se il problema persiste, ti invitiamo a contattare il nostro team di supporto inviando un'email a <a href="mailto:support@mymentis.it">support@mymentis.it</a>.
              </p>
              <br/>
              <p>
              Inoltre, tutte le prescrizioni e i documenti scambiati tramite chat saranno sempre disponibili nell'archivio della chat stessa. Per accedervi:
              </p>
              <ol>
                <li>
                  Seleziona il cliente di cui si desidera visualizzare i documenti.
                </li>
                <li>
                  Andare alla sezione "Documenti".
                </li>
                <li>
                  Cliccare su "Prescrizioni" per visualizzare le prescrizioni generate o su "Documenti" per visualizzare tutti i documenti inviati o ricevuti dal paziente.
                </li>
              </ol>
              <p>
                In questo modo sarà possibile recuperare in qualsiasi momento i documenti archiviati e verificarne l'effettivo invio. 
              </p>
            </div>
          ),
        },
        {
          question: "Problemi tecnici durante le visite",
          answer: (
            <div>
              <strong>
                Malfunzionamenti durante o poco prima di una visita online?
              </strong>
              <br/>
              <p>
                Se durante una visita, o poco prima dell'inizio della medesima, tu o il cliente riscontraste problemi tecnici (audio o video non si sentono, la video-visita non si apre, problemi di connessione ecc…), potrai generare un link Google Meet (gratuito) e inviarlo nella chat con il cliente in questione. In questo modo, avrete l'opportunità di incontrarvi nel giorno e nell'ora in cui avevate concordato, senza perdere questa occasione.
              </p>
              <br/>
              <strong>
                Problemi tecnici durante la video-visita?
              </strong>
              <br/>
              <p>
                È bene che il professionista sia preparato ad affrontare ogni evenienza tecnica e sia in grado di comunicare in maniera appropriata al team di supporto (<a href="mailto:support@mymentis.it">support@mymentis.it</a>) la natura del problema riscontrato, fornendo le seguenti informazioni:
              </p>
              <ol>
                <li>
                  <i>Tipo di dispositivo utilizzato</i> (cellulare, tablet o computer).
                </li>
                <li>
                  <i>Modello del dispositivo</i>
                </li>
                <li>
                  <i>Browser utilizzato</i> (Chrome, Safari, Edge)
                </li>
                <li>
                  <i>Screen/foto/video dell'errore che si è verificato</i> (se possibile)
                </li>
                <li>
                  <i>Orario dell'errore</i>
                </li>
              </ol>
              <p>
                La <i>modalità</i> con cui hai effettuato l'accesso alla video-visita:
              </p>
              <ol type="a">
                <li>
                  Tramite il tasto “collegati” del widget della visita presente in chat (riquadro con data e ora);
                </li>
                <li>
                  Tramite il bottone "accedi alla videochiamata"  presente nell'email di conferma dell'appuntamento.
                </li>
              </ol>
              <br/>
              <strong>
                Problemi di connessione a internet
              </strong>
              <p>
                Lo psichiatra o il cliente potrebbero sperimentare problemi di connessione a internet, che possono causare interruzioni video e/o audio durante la sessione o la caduta della stessa, oppure un rallentamento della trasmissione audio rispetto al video.
              </p>
              <p>
                Cosa fare quando la connessione a internet Wi-Fi non funziona?
              </p>
              <p>
                Quando le tacche del segnale Wi-Fi ci sono ma la connessione non funziona, è molto probabile che il problema sia riconducibile al router. In questo caso ecco cosa fare: 
              </p>
              <ol>
                <li>
                  Riavviare il router, se questo non basta allora procedere con il punto 2.
                </li>
                <li>
                  Scollegare il cavo alimentatore del router per almeno 10-15 secondi, poi ricollegarlo e provare a riavviare il router.
                </li>
              </ol>
              <p>
                È importante anche verificare di essere connessi alla rete Wi-Fi giusta.
              </p>
              <p>
                Qualora la rete Wi-Fi non dovesse funzionare, se lo psichiatra ne fosse dotato, potrebbe provare in alternativa a utilizzare l'hotspot attraverso il proprio telefono cellulare.
              </p>
              <p>
                È bene che lo psichiatra informi il prima possibile il cliente del problema tecnico e gli dica che sta cercando di risolverlo. 
              </p>
              <br/>
              <strong>
                Problemi audio o video
              </strong>
              <p>
                La qualità audio/video potrebbe essere bassa o assente, a causa di una scarsa qualità della connessione a internet o a problemi legati al malfunzionamento del microfono o della videocamera.
              </p>
              <p>
                In questo caso ecco i passi da seguire:
              </p>
              <ol>
                <li>
                  <p>
                    Assicurarsi che il browser utilizzato per accedere alla piattaforma (esempio: Safari, Chrome, Explorer) abbia l'accesso al microfono o alla videocamera. Se così non fosse, recarsi alla sezione “impostazioni” del browser utilizzato e consentire l'accesso sia al microfono, sia alla videocamera.
                  </p>
                  <p>
                    Se il problema dovesse persistere:
                  </p>
                  <ol type="A">
                    <li>
                      <p>
                        Per Macintosh:
                      </p>
                      <p>
                        Seleziona menù Apple &gt; Impostazioni di sistema &gt; fai click su “Privacy e sicurezza” nella barra laterale (potresti dover scorrere verso il basso).
                      </p>
                      <p>
                        Fai click su microfono/videocamera sulla destra.
                      </p>
                      <p>
                        Attiva o disattiva l'accesso al microfono/videocamera per ogni app nell'elenco.
                      </p>
                    </li>
                    <li>
                      <p>
                        Per Windows:
                      </p>
                      <p>
                        Seleziona Start &gt; Impostazioni &gt; Privacy e sicurezza &gt; microfono e verifica che l'accesso al microfono sia attivato.
                      </p>
                      <p>
                        Assicurati che l'opzione “Consenti alle app di accedere al microfono” sia attivata, quindi scegli quali app hanno accesso.
                      </p>
                    </li>
                  </ol>
                  <p>
                    Se non funziona il punto 1 proseguire con il punto 2:
                  </p>
                </li>
                <li>
                  <p>
                    Chiudere il browser e riattivarlo.
                  </p>
                  <p>
                    Se non funziona proseguire con il punto 3
                  </p>
                </li>
                <li>
                  Riavvia il pc o tablet e prova ad effettuare di nuovo l'accesso alla piattaforma.
                </li>
              </ol>
              <p>
                Se utilizzi una cuffia e gli altri non riescono a sentirt:
              </p>
              <ol>
                <li>
                  Assicurati che il microfono o la cuffia sia collegata correttamente al computer.
                </li>
                <li>
                  Verifica che il microfono o la cuffia siano il dispositivo di registrazione predefinito del sistema.
                </li>
              </ol>
              <p>
                Ecco come eseguire questa operazione in Windows 11:
              </p>
              <ol>
                <li>
                Selezionare Start &gt; Impostazioni &gt; Sistema &gt; Audio
                </li>
                <li>
                  In” Input” passare a “Scegli un dispositivo per parlare o registrare” e selezionare il dispositivo desiderato.
                </li>
              </ol>
              <br/>
              <strong>
                Difficoltà nell'uso delle tecnologie da parte dei clienti
              </strong>
              <br/>
              <p>
                Alcuni clienti potrebbero avere difficoltà nell'utilizzo delle tecnologie necessarie per le sessioni online. Per esempio, potrebbero avere difficoltà nell'attivazione della webcam o del microfono. In tal caso, è premura del professionista provare a fornire istruzioni chiare ai clienti o indirizzarli all'assistenza mediante l'apposita sezione all'interno della piattaforma.
              </p>
            </div>
          ),
        },
        {
          question: "Pagamenti e fatturazione",
          answer: (
            <div>
              <strong>
                Regime della propria P.iva
              </strong>
              <br/>
              <p>
                Nel momento in cui si registra sulla piattaforma Mymentis (registrazione distinta da quella su Stripe), è importante che il professionista selezioni il regime della propria p.iva correttamente (regime ordinario o forfettario). 
              </p>
              <p>
                Il proforma, scaricabile dall'apposita sezione, necessario per l'erogazione della fattura, sarà infatti diverso a seconda dell'opzione selezionata durante la fase di registrazione su Mymentis. 
              </p>
              <p>
                Nel caso in cui il regime della P.iva non venga indicato correttamente, è importante che il professionista lo comunichi tempestivamente a <a href="mailto:support@mymentis.it">support@mymentis.it</a>. 
              </p>
              <br/>
              <strong>
                Come funzionano i pagamenti?
              </strong>
              <br/>
              <p>
                Il pagamento di Mymentis ai collaboratori avverrà con cadenza mensile e verrà effettuato tramite bonifico bancario entro 20 giorni dall'emissione della fattura elettronica da parte del professionista.
              </p>
              <p>
                L'iban su cui vengono effettuati i bonifici è quello indicato al momento della registrazione su Stripe Connect. 
              </p>
              <br/>
              <strong>
                Ogni mese, per ricevere il pagamento, il professionista deve:
              </strong>
              <ol>
                <li>
                  <u>
                    Scaricare il modello precompilato 
                  </u>
                  <p>
                    Nell'apposita sezione all'interno della piattaforma Mymentis (Profilo &gt; Pagamenti), il professionista potrà scaricare il proforma, un modello che riporta i dati da inserire per la compilazione ed emissione della fattura elettronica, tra cui quelli societari e il totale da fatturare che fa riferimento al corrispettivo maturato nel mese precedente.
                  </p>
                  <br/>
                  <p>
                    Nota: compilando la sezione Dati di fatturazione (Profilo &gt; Pagamenti) anche i dati del
                    professionista saranno inseriti automaticamente all'interno del modello precompilato.
                    {/*(vedi “I tuoi dati di fatturazione” nelle <a target="_blank" href={`${envs.REACT_APP_S3_ENDPOINT}/${guidelines}`}>Linee Guida</a>).*/}
                  </p>
                  <br/>
                  <p>
                    <strong>N.B.:</strong> il tasto “Scarica modello” all'interno della sezione Pagamenti diventa cliccabile a partire dal 1° di ogni mese. Mediante quel tasto potrete scaricare il modello precompilato.
                  </p>
                  <br/>
                  <p>
                    <strong>Attenzione:</strong> Il modello è una traccia, contenente tutti i dati utili, che ti serviranno per emettere la fattura elettronica. Infatti, non è una fattura e non ha valenza fiscale. 
                  </p>
                </li>
                <br/>
                <li>
                  <p>
                    <u>Emettere la fattura elettronica</u> (entro il 5 di ogni mese)
                  </p>
                  <br/>
                  <p>
                    Nota: Per finalizzare il pagamento è necessario che Mymentis riceva correttamente la fattura del professionista nel cassetto fiscale tramite SDI entro il 5 di ogni mese.
                  </p>
                  <br/>
                  <p>
                    Inoltre, alla sezione Profilo e poi “Pagamenti” dell'account sulla piattaforma Mymentis il professionista può visualizzare il saldo maturato nel mese precedente.
                  </p>
                </li>
              </ol>
              <br/>
              <strong>
                Come emettere la fattura elettronica?
              </strong>
              <p>
                La fattura elettronica può essere emessa dal professionista tramite le seguenti opzioni:
              </p>
              <ul>
                <li>
                  Portali/applicazioni dell'agenzia delle entrate;
                </li>
                <li>
                  Portali web specializzati;
                </li>
                <li>
                  Il proprio consulente fiscale.
                </li>
              </ul>
            </div>
          ),
        },
        {
          question: "Vacanze o sospensione temporanea dell'attività",
          answer: (
            <div>
              <p>
                Quando il professionista vuole sospendere temporaneamente l'attività clinica derivante dalla collaborazione con il progetto Mymentis (ad esempio per periodo di ferie) è tenuto a: 
              </p>
              <ul>
                <li>
                  Interrompere la ricezione di nuovi clienti mediante il tasto "Interrompi", all'interno della sezione Profilo &gt; Gestione preferenze;
                </li>
                <li>
                  Informare i propri clienti della sospensione temporanea dell'attività e della data di ripresa;
                  {/*(per approfondire, vedi sezione “Come interrompere la ricezione di nuovi clienti” nelle <a target="_blank" href={`${envs.REACT_APP_S3_ENDPOINT}/${guidelines}`}>Linee Guida</a>)*/}
                </li>
                <li>
                  Inviare una comunicazione all'indirizzo <a href="mailto:support@mymentis.it">support@mymentis.it</a>
                </li>
              </ul>
            </div>
          ),
        }
      ];
  } else if (getCurrentUserInfoResponse.data?.user.role === "patient" 
    && getCurrentUserInfoResponse.data?.user.therapyType === "psychiatry") {
      // Psychiatry patient
      selectedFaq = [
        {
          question: "Qual'è il prezzo di una visita di Mymentis",
          answer: (
            <div>
              <ul>
                <li>
                  Il prezzo della prima visita è di 150€
                </li>
                <li>
                  Il prezzo delle visite di controllo è di 130€
                </li>
              </ul>
              <p>
                In Mymentis riteniamo che la fiducia e la trasparenza siano alla base della relazione con te per costruire una connessione genuina che favorisca il tuo benessere. Per tale motivazione il costo di ogni visita è fisso e senza sorprese. Non avrai alcun vincolo, potrai interrompere in qualsiasi momento desideri. Invece, la compilazione del <a target="_blank" href="/questionario/introduzione">questionario</a>, il servizio di matching che consente di trovare il professionista più adatto sono gratuiti e senza impegno.
              </p>
            </div>
          ),
        },
        {
          question: "Come si pagano le visite?",
          answer: (
            <div>
              <p>
              Potrai pagare le tue visite con il massimo della sicurezza dal <a target="_blank" href="/dashboard/profilo">tuo account</a> sulla Web-app di Mymentis, tramite il sistema di pagamenti Stripe. Tra i pagamenti accettati da Stripe rientrano tutte le principali carte di credito e di debito (ricaricabili) e bancomat. con Visa, Mastercard, Maestro, American Express, PostePay ed Apple Pay.
              </p>
              <p>
                Dove?
              </p>
              <br/>
              <p>
                Il pagamento dell'appuntamento avviene dopo la prenotazione. Dopo aver scelto la data e l'orario preferiti, o in accordo con lo psichiatra, potrai procedere al pagamento cliccando sul pulsante Paga.
              </p>
              <br/>
              <p>
                Reminder: È necessario chiedere l'annullamento dell'appuntamento già pagato fino a <strong>24 ore prima</strong> per poter ottenere il rimborso completo del pagamento. Le transazioni sono interamente gestite dal sistema Stripe, che garantisce la massima sicurezza.
              </p>
              <br/>
              <p>
                Potrai effettuare i pagamenti e, se desideri, salvare il metodo di pagamento inserendo e salvando i dati della carta in totale sicurezza. In questo caso i pagamenti delle visite successive avverranno in automatico, attraverso il metodo che hai precedentemente salvato, 24 ore prima dell'orario previsto dell'appuntamento. Se hai problemi con i pagamenti puoi rivolgerti al nostro <a target="_blank" href="https://wp.mymentis.it/contatti/">servizio assistenza</a> o all'email <a href="mailto:clientsupport@mymentis.it">clientsupport@mymentis.it</a>. Se vuoi rimuovere o cambiare il metodo di pagamento che avevi precedentemente salvato, accedi al <a target="_blank" href="/dashboard/profilo">tuo account sulla Web-app</a>, entra nella sezione “Profilo” e infine nella sezione Metodi di pagamento.
              </p>
            </div>
          ),
        },
        {
          question: "Come faccio a inserire o a cambiare il metodo di pagamento?",
          answer: (
            <div>
              <p>
                Se vuoi inserire, salvare, cambiare o rimuovere i dati della tua carta o il metodo di pagamento, puoi farlo dal tuo account sulla Web-app, entrando nella sezione “Profilo” del menù a tendina sulla sinistra e infine selezionando la sezione "Metodo di pagamento". Il sistema Stripe si occuperà della gestione delle transazioni, garantendoti la massima sicurezza.  Se hai problemi con i pagamenti puoi rivolgerti al nostro servizio assistenza o all'email <a href="mailto:clientsupport@mymentis.it">clientsupport@mymentis.it</a>.
              </p>
            </div>
          ),
        },
        {
          question: "Posso detrarre dalle tasse le spese delle visite che faccio con gli psichiatri di Mymentis",
          answer: (
            <div>
              <p>
                Tutte le spese mediche, comprese le visite che farai con il tuo psichiatra Mymentis, possono essere detratte dalla dichiarazione dei redditi se accompagnate da fattura, quest'ultima però deve essere richiesta esplicitamente. Per ottenerla è sufficiente accedere al proprio “Profilo“, andare nella sezione “Metodo di pagamento”, inserire i “Dati di fatturazione” e acconsentire alla condivisione delle informazioni richieste. Tutte le fatture, se richieste, ti saranno inviate sulla tua email una volta effettuato il pagamento della visita. 
              </p>
            </div>
          ),
        }
      ];
  } else {
    // Psychology patient & therapist
    selectedFaq = [
      {
        question: "Cos’è Mymentis e come funziona?",
        answer: (
          <div>
            <p>
              Mymentis è una startup che offre un servizio di supporto psicologico e di psicoterapia online. Ti aiutiamo a trovare il professionista adatto a te, sulla base delle tue esigenze e preferenze, selezionandolo tra centinaia di profili diversi. Gli psicologi/psicoterapeuti non sono tutti uguali:  esistono aree di intervento diverse in cui un professionista del settore può essere specializzato ed esistono anche tipi diversi di psicoterapia, che possono essere più o meno adatti a seconda del tipo di necessità che ha la persona che vuole affrontare un percorso di terapeutico.   Se vuoi trovare il professionista che fa al caso tuo, ti basterà compilare il nostro questionario (gratuitamente) e rispondere alle domande con onestà (tali dati sono protetti…). Il sistema di matching di Mymentis, sulla base di quello che emerge dalle tue risposte, selezionerà il terapeuta più adatto alle tue esigenze. Successivamente, con il professionista scelto per te potrai fissare ed effettuare il primo colloquio gratuito. Dopo aver effettuato il primo colloquio, se lo desideri, potrai proseguire con il percorso senza alcun vincolo e fissare con il tuo terapeuta la seduta successiva.
            </p>
          </div>
        )
      },
      {
        question: "Qual è il prezzo di una seduta di Mymentis?",
        answer: (
          <div>
            <p>
              Il prezzo per ogni seduta individuale (adulti - minori) è di 50
              euro.
            </p>
            <p>Il prezzo per ogni seduta di coppia è di 60 euro.</p>
            <br />
            <p>
              In Mymentis riteniamo che la fiducia e la trasparenza siano la base
              della nostra relazione con te. Attraverso questa fiducia e
              trasparenza speriamo di costruire una connessione genuina che
              favorisca il tuo benessere. Per tale motivazione ci impegniamo a
              condividere con te ogni informazione rilevante e promettiamo di
              essere trasparenti. Il costo di ogni seduta è fisso e senza
              sorprese. Non avrai alcun vincolo, potrai interrompere in qualsiasi
              momento desideri. -La compilazione del questionario, il servizio di
              matching che consente di trovare il terapeuta giusto e il primo
              incontro conoscitivo sono gratuiti e senza impegno
            </p>
          </div>
        ),
      },
      {
        question: "Come si pagano le sedute? ",
        answer: (
          <div>
            <p>
              Potrai pagare le tue sedute con il massimo della sicurezza dal tuo
              account sulla Web-app di Mymentis, tramite il sistema di pagamenti
              Stripe. Tra i pagamenti accettati da Stripe rientrano tutte le
              principali carte di credito e di debito (ricaricabili) e bancomat
              con Visa, Mastercard, Maestro, American Express, PostePay ed Apple
              Pay.
            </p>
            <br />
            <p>Dove?</p>
            <ol>
              <li>
                Dalla sezione “Chat” cliccando il tasto “Procedi al pagamento”che
                trovi all'interno del box dell'appuntamento concordato con il
                terapeuta dopo che hai confermato l'orario e il giorno
              </li>
              <li>
                Dalla sezione “Appuntamenti” cliccando il tasto “Procedi al
                pagamento” relativo all'appuntamento di interesse. Ricorda: È
                necessario chiedere l'annullamento dell'appuntamento fino a 24 ore
                prima per poter ottenere il rimborso completo del pagamento.
              </li>
            </ol>
            Le transazioni sono interamente gestite dal sistema Stripe, che
            garantisce la massima sicurezza. Potrai effettuare i pagamenti e, se
            desideri, salvare il metodo di pagamento inserendo e salvando i dati
            della carta in totale sicurezza. In questo caso i pagamenti delle
            sedute successive avverranno in automatico, attraverso il metodo che
            hai precedentemente salvato, 24 ore prima dell'orario previsto
            dell'appuntamento. Se hai problemi con i pagamenti puoi rivolgerti al
            nostro{" "}
            <p
              onClick={() => {
                contactUsRef.current &&
                  contactUsRef.current.scrollIntoView({
                    inline: "end",
                    block: "end",
                  });
              }}
              style={{
                textDecoration: "underline",
                display: "inline",
                cursor: "pointer",
              }}
            >
              servizio assistenza
            </p>{" "}
            o all’email{" "}
            <a href="mailto:clientsupport@mymentis.it">
              clientsupport@mymentis.it
            </a>
            . Se vuoi rimuovere o cambiare il metodo di pagamento che avevi
            precedentemente salvato, accedi al tuo account sulla Web-app, entra
            nella sezione “Profilo” e infine nella sezione "Metodi di pagamento".
          </div>
        ),
      },
      {
        question: "Come faccio a inserire o a cambiare il metodo di pagamento?",
        answer: (
          <div>
            <p>
              Se vuoi inserire, salvare, cambiare o rimuovere i dati della tua
              carta o il metodo di pagamento, puoi farlo dal tuo account sulla
              Web-app, entrando nella sezione “Profilo” del menù a tendina sulla
              sinistra e infine selezionando la sezione "Metodo di pagamento". Il
              sistema Stripe si occuperà della gestione delle transazioni,
              garantendoti la massima sicurezza. Se hai problemi puoi rivolgerti
              al nostro{" "}
              <p
                onClick={() => {
                  contactUsRef.current &&
                    contactUsRef.current.scrollIntoView({
                      inline: "end",
                      block: "end",
                    });
                }}
                style={{
                  textDecoration: "underline",
                  display: "inline",
                  cursor: "pointer",
                }}
              >
                servizio assistenza
              </p>{" "}
              o all’email{" "}
              <a href="mailto:clientsupport@mymentis.it">
                clientsupport@mymentis.it
              </a>
              .
            </p>
          </div>
        ),
      },
      {
        question:
          "Posso detrarre dalle tasse le spese delle sedute che faccio con gli psicologi di Mymentis?",
        answer: (
          <div>
            <p>
            Tutte le spese mediche, comprese le sedute di psicoterapia che farai con il tuo terapeuta Mymentis, possono essere detratte dalla dichiarazione dei redditi se accompagnate da fattura, quest’ultima però deve essere richiesta esplicitamente. Per ottenerla è sufficiente accedere al proprio “Profilo“, andare nella sezione “Metodo di pagamento”, inserire i “Dati di fatturazione” e acconsentire alla condivisione delle informazioni richieste.  Tutte le fatture, se richieste, ti saranno inviate sulla tua email, una volta effettuato il pagamento della seduta ed entro le 24 ore dopo. Altrimenti puoi trovarle e scaricarle alla sezione “Appuntamenti”, “Passati”, della tua area personale.
            </p>
          </div>
        )
      },
    ];
  }

  const faq = selectedFaq;
  return (
    <div className={styles.faqContainer}>
      {modalIsOpen && (
        <Modal>
          <div className={styles.contactUsForm}>
            <FormHeader
              closeModal={() => setModalIsOpen(false)}
              title="Contattaci"
              closeButton
            />
            <ContactUsForm setModalIsOpen={setModalIsOpen} />
          </div>
        </Modal>
      )}
      {!actualAnswer && actaualQuestion.trim() === "" ? (
        <div className={styles.faq}>
          <h4 className={styles.title}>Come possiamo aiutarti?</h4>
          <div className={styles.faqContainer}>
            <h6 className={styles.subtitle}>Domande frequenti</h6>
            <div className={styles.faqList}>
              {faq.map((q, index) => (
                <Button
                  key={index}
                  variant="tertiary"
                  rightIcon={<ChevronRightIcon />}
                  onClick={() => {
                    const question = faq[index].question;
                    const answer = faq[index].answer;
                    setActualQuestion(question);
                    setActualAnswer(answer);
                  }}
                >
                  {q.question}
                </Button>
              ))}
            </div>
          </div>
          <div>
            <h6 className={styles.subtitle}>
              Non hai trovato quello che cercavi? Contattaci
            </h6>
            <ContactUsForm />
          </div>
        </div>
      ) : (
        <div className={styles.faqAnswer}>
          <Button
            leftIcon={<ChevronLeftIcon />}
            variant="tertiary"
            className={styles.backBtn}
            onClick={() => {
              setActualQuestion("");
              setActualAnswer("");
            }}
          >
            Torna alle Domande Frequenti
          </Button>
          <div className={styles.answerContainer}>
            <h5 className={styles.actualQuestion}>{actaualQuestion}</h5>
            <div className={styles.actualAnswer}>{actualAnswer}</div>
          </div>
          <div className={styles.contactUsInfoBox} ref={contactUsRef}>
            <h6>Non hai trovato quello che cercavi?</h6>
            <Button onClick={() => setModalIsOpen(true)}>Contattaci</Button>
          </div>
        </div>
      )}
    </div>
  );
};
