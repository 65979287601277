import { CalendarDaysIcon, UserIcon, UsersIcon } from "@heroicons/react/24/solid";
import styles from "./PatientChatCard.module.scss";
import { dateToShow } from "../../../utils/showDate";
import parentIcon from "../../../theme/images/parentIcon.png";
import psychiatryGreen from '../../../theme/svg/psychiatry-green.svg';
import psychiatryBlue from '../../../theme/svg/psychiatry-blue.svg';

interface Props {
  patientName: string;
  unreadMessages: number;
  lastMessage: any;
  lastAppointment: any;
  isSelected?: boolean;
  getPatient: any;
  therapyType?: any;
}

export const PatientChatCard = ({
  patientName,
  unreadMessages,
  lastAppointment,
  lastMessage,
  isSelected = false,
  getPatient,
  therapyType
}: Props) => {

  const isPsychiatry = therapyType === 'psychiatry'

  const icon = () => {
    if (therapyType) {
      switch (therapyType) {
        case 'single':
          return (
            <>
              <div className={styles.typeIcon}>
                <UserIcon className={styles.icon}/>
              </div>
              <p>Individuale</p>
            </>
          )
        case 'couple':
          return (
            <>
              <div className={styles.typeIcon}>
                <UsersIcon className={styles.icon}/>
              </div>
              <p>Di coppia</p>
            </>
          )
        case 'underage':
          return (
            <>
              <div className={styles.typeIcon}>
                <img
                  src={parentIcon}
                  alt='parent icon'
                  className={styles.icon}
                />
              </div>
              <p>Minori</p>
            </>
          )
        case 'psychiatry':
          return (
            <>
              <div className={`${styles.typeIcon} ${isPsychiatry ? styles.typeIconPsychiatry : ''}`}>
                <img
                  src={isPsychiatry ? psychiatryGreen : psychiatryBlue}
                  alt="psychiatry icon"
                  className={styles.icon}
                />
              </div>
              <p>Psichiatria</p>
            </>
          )
      }
    }
  }

  return (
    <div
      className={`${styles.patientChatCard}
      ${isSelected && therapyType !== undefined ? (isPsychiatry ? styles.isChatSelectedPsychiatry : styles.isChatSelected) : ''}`}
    >
      <div className={styles.patientChatCardLeft}>
        {lastAppointment && (
          <div className={styles.patientChatCardCalendar}>
            <CalendarDaysIcon className={styles.icon}/>
            <span>
              {dateToShow(new Date(lastAppointment.startTime), "card")}
            </span>
          </div>
        )}
        <div className={styles.patientChatCardName}>
          <div className={styles.patientCardNameWrapper}>
            {therapyType && (
              <div className={`${styles.wrapper} ${isPsychiatry ? styles.wrapperPsychiatry : ''}`}>
                {icon()}
              </div>
            )}
          </div>
          <div className={styles.name}>{patientName}</div>
          {lastMessage && (
            <div className={styles.message}>{lastMessage.content.replaceAll('&moved', '')}</div>
          )}
        </div>
      </div>
      <div className={styles.patientChatCardRight}>
        {unreadMessages > 0 && (
          <div
            className={`${styles.patientChatCardNewMessage} ${isPsychiatry ? styles.patientChatCardNewMessagePsychiatry : ''}`}>
            {unreadMessages}
          </div>
        )}
        {lastMessage && (
          <div className={styles.patientChatCardDate}>
            {dateToShow(new Date(lastMessage.when), "card")}
          </div>
        )}
      </div>
    </div>
  );
};
