import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import routes from "routes";
import * as yup from "yup";
import { trpc } from "../../../utils/trpc";
import Button from "../../atoms/Button";
import FormHeader from "../../atoms/FormHeader";
import InfoBox from "../../atoms/InfoBox";
import Input from "../../atoms/Input";
import Modal from "../Modal";
import styles from "./TherapistPersonalInfo.module.scss";
import { Alert } from "antd";
import { capitalize } from "utils/capitalize";
import Avatar from "components/atoms/Avatar";
import { envs } from "utils/envs";
import { analyticsService } from "../../../utils/analyticsService";
import { raiseException } from "../../../utils/raiseException";
import trimString from "../../../utils/trimString";

export const TherapistPersonalInfo = () => {
  const [therapiesType, setTherapiesType] = useState<any>([]);
  const [vatNumberType, setVatNumberType] = useState("");
  const [licenceRegisterNumber, setLicenceRegisterNumber] = useState("");
  const [licenceRegisterPlace, setLicenceRegisterPlace] = useState("");
  const [licenceRegisterWith, setLicenceRegisterWith] = useState("");
  const [graduationCourse, setGraduationCourse] = useState("");
  const [graduationPlace, setGraduationPlace] = useState("");
  const [therapistTitle, setTherapistTitle] = useState("");
  const [therapistDescription, setTherapistDescription] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [userGivenName, setUserGivenName] = useState("");
  const [userFamilyName, setUserFamilyName] = useState("");
  const [userGender, setUserGender] = useState<
    "male" | "female" | "other" | "noanswer" | undefined
  >(undefined);
  const [userEmail, setUserEmail] = useState("");
  const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(false);
  const [modifyPwModalIsOpen, setModifyPwModalIsOpen] = useState(false);
  const [modifyEmailModalIsOpen, setModifyEmailModalIsOpen] = useState(false);
  const [confirmationCodeModalIsOpen, setConfirmationCodeModalIsOpen] =
    useState(false);
  const [changePwOk, setChangePwOk] = useState<
    "ok" | "notOk" | "notConfirm" | "notOld" | "maxAttempt" | null
  >(null);
  const [changeEmailError, setChangeEmailError] = useState("");

  useEffect(() => {
    analyticsService({
      'event': 'virtualPageview',
      'pageUrl': '/dashboard/profilo/informazioni-personali',
      'pageTitle': 'Therapist Personal Info'
    });
  }, []);

  const emailFormik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .trim()
        .matches(
          /^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i,
          "L'email inserita ha un formato non valido"
        )
        .required("L'email è un campo obbligatorio"),
    }),
    onSubmit: (values, actions) => {
      onChangeEmail(values.email);
    },
  });

  const passwordFormik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      checkNewPassword: "",
    },
    validationSchema: yup.object().shape({
      password: yup
        .string()
        .required("La password attuale è un campo obbligatorio"),
      newPassword: yup
        .string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^$*.[\]{}()?\-"!@#%&\/\\,><':;|_~`=+\-])[^\s]{8,}$/,
          "La password non rispetta i criteri di sicurezza"
        )
        .required("La nuova password è un campo obbligatorio"),
      checkNewPassword: yup
        .string()
        .oneOf([yup.ref("newPassword")], "Le due password non combaciano")
        .required("Inserisci di nuovo la nuova password"),
    }),
    onSubmit: (values, actions) => {
      onChangePasword(values.password, values.newPassword);
    },
  });

  const userInfo = trpc.mainService.user.getCurrentUserInfo.useQuery();
  const updateUserInfo = trpc.mainService.patient.updateInfo.useMutation();
  const changePasword = trpc.mainService.user.changePassword.useMutation();
  const changeEmail = trpc.mainService.user.changeEmail.useMutation();

  const navigate = useNavigate();

  const resendEmailVerificationCode =
    trpc.mainService.user.resendEmailVerificationCode.useMutation();

  const onChangeEmail = (email) => {
    changeEmail
      .mutateAsync({ email: trimString(email) })
      .then((data) => {
        setModifyEmailModalIsOpen(false);
        setConfirmationCodeModalIsOpen(true);
      })
      .catch((e) => {
        raiseException(e);
        if (e?.shape?.errorCode === "EmailAlreadyExists") {
          emailFormik.setErrors({
            ...emailFormik.errors,
            email: "L'email inserita è già in uso",
          });
        } else {
          setChangeEmailError(
            "Si è verificato un errore inaspettato. Verifica di essere connesso a internet e riprova."
          );
        }
      });
  };

  const onChangePasword = (oldPassword, password) => {
    changePasword
      .mutateAsync({
        oldPassword: trimString(oldPassword),
        newPassword: trimString(password),
      })
      .then((data) => setChangePwOk("ok"))
      .catch((e) => {
        if (e?.shape?.errorCode === "CurrentPasswordWrong") {
          setChangePwOk("notOld");
        } else if (e?.shape?.errorCode === "MaxAttemptsReached") {
          setChangePwOk("maxAttempt");
        } else {
          setChangePwOk("notOk");
        }
        raiseException(e);
        console.error("ERROR", e?.shape?.message);
      });
  };

  useEffect(() => {
    if (userInfo.data) {
      setTherapistTitle(userInfo.data.user.title!);
      setTherapistDescription(userInfo.data.user.description!);
      setUserGivenName(userInfo.data.user.given_name!);
      setUserFamilyName(userInfo.data.user?.family_name!);
      setUserGender(userInfo.data.user?.gender as any);
      setUserEmail(userInfo.data.user?.email!);
      setUserPhoneNumber(userInfo.data.user?.phone_number!);
      setLicenceRegisterNumber(userInfo.data.user?.licenceRegisterNumber!);
      setLicenceRegisterPlace(userInfo.data.user?.licenceRegisterPlace!);
      setLicenceRegisterWith(userInfo.data.user?.licenceRegisterWith!);
      setGraduationCourse(userInfo.data.user?.graduationCourse!);
      setGraduationPlace(userInfo.data.user?.graduationPlace!);
      setVatNumberType(userInfo.data.user?.vatNumberType!);
      setTherapiesType(userInfo.data.user?.therapies!);
    }
  }, [userInfo.data]);

  useEffect(() => {
    if (updateUserInfo.status === "loading") {
      setLoadingModalIsOpen(true);
    } else {
      setLoadingModalIsOpen(false);
    }
  }, [updateUserInfo]);

  const closeConfirmationCodeModal = async () => {
    try {
      await userInfo.refetch();
    } catch (e: any) {
      raiseException(e);
    }
    setConfirmationCodeModalIsOpen(false);
  }

  return (
    <div className={styles.therapistPersonalInfo}>
      {loadingModalIsOpen && <Modal className="loadingModal">Loading...</Modal>}
      {modifyPwModalIsOpen && (
        <Modal close={() => {
          setModifyPwModalIsOpen(false);
        }}>
          <form
            onSubmit={passwordFormik.handleSubmit}
            className={styles.modifyModalContent}
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <FormHeader
              closeModal={() => setModifyPwModalIsOpen(false)}
              closeButton
              title="Modifica password"
            />
            <div className={styles.inputWrapper}>
              <Input
                type="password"
                name="password"
                value={passwordFormik.values.password}
                onChange={passwordFormik.handleChange}
                error={
                  (passwordFormik.errors.password &&
                    passwordFormik.touched.password &&
                    passwordFormik.errors.password) as string
                }
                label="Password attuale"
              />
              <Input
                type="password"
                name="newPassword"
                value={passwordFormik.values.newPassword}
                onChange={passwordFormik.handleChange}
                error={
                  (passwordFormik.errors.newPassword &&
                    passwordFormik.touched.newPassword &&
                    passwordFormik.errors.newPassword) as string
                }
                // onChange={(e) => setUserNewPassword(e.target.value)}
                label="Nuova passoword"
              />
              <Input
                type="password"
                name="checkNewPassword"
                value={passwordFormik.values.checkNewPassword}
                onChange={passwordFormik.handleChange}
                error={
                  (passwordFormik.errors.checkNewPassword &&
                    passwordFormik.touched.checkNewPassword &&
                    passwordFormik.errors.checkNewPassword) as string
                }
                label="Ripeti nuova password"
              />
            </div>
            {changePwOk === "ok" && (
              <InfoBox
                className={styles.infoBoxModal}
                text={"Password aggiornata correttamente!"}
                variant="confirm"
              />
            )}
            {changePwOk === "notOk" && (
              <InfoBox
                className={styles.infoBoxModal}
                text={"Errore nell'aggiornamento password!"}
                variant="error"
              />
            )}
            {changePwOk === "notConfirm" && (
              <InfoBox
                className={styles.infoBoxModal}
                text={"Le password non coincidono!"}
                variant="error"
              />
            )}
            {changePwOk === "notOld" && (
              <InfoBox
                className={styles.infoBoxModal}
                text={"La password attuale non è corretta"}
                variant="error"
              />
            )}
            {changePwOk === "maxAttempt" && (
              <InfoBox
                className={styles.infoBoxModal}
                text={"Tentativi massimi raggiunti. Riprova più tardi"}
                variant="error"
              />
            )}

            {changePwOk !== "ok" && (
              <Button type="submit">Aggiorna password</Button>
            )}
            {changePwOk === "ok" && (
              <Button
                type="button"
                onClick={() => {
                  setModifyPwModalIsOpen(false);
                }}
              >
                {changePwOk === "ok" ? "Conferma!" : "Aggiorna password"}
              </Button>
            )}
          </form>
        </Modal>
      )}
      {modifyEmailModalIsOpen && (
        <Modal close={() => {
          setModifyEmailModalIsOpen(false);
        }}>
          <form
            onSubmit={emailFormik.handleSubmit}
            className={styles.modifyModalContent}
            onClick={e => {
              e.stopPropagation()
            }}>
            <FormHeader
              closeModal={() => setModifyEmailModalIsOpen(false)}
              closeButton
              title="Modifica email"
            />
            <div className={styles.inputWrapper}>
              <Input disabled value={userEmail} label="Email attuale" />
              <Input
                type="email"
                name="email"
                value={emailFormik.values.email}
                onChange={emailFormik.handleChange}
                error={
                  (emailFormik.errors.email &&
                    emailFormik.touched.email &&
                    emailFormik.errors.email) as string
                }
                label="Nuova email"
              />
            </div>
            {changeEmailError && (
              <Alert
                closeIcon={null}
                type="error"
                message={changeEmailError}
                closable
              />
            )}
            <InfoBox
              className={styles.infoBoxModal}
              text={
                "Invieremo un’email con un link di conferma sul nuovo indirizzo email che hai inserito."
              }
            />
            <Button
            >
              Invia email di conferma
            </Button>
          </form>
        </Modal>
      )}
      {confirmationCodeModalIsOpen && (
        <Modal close={closeConfirmationCodeModal}>
          <div className={styles.modifyModalContent}>
            <FormHeader
              closeModal={closeConfirmationCodeModal}
              closeButton
              title="Conferma la tua mail"
            />
            <div className={styles.modalDescription}>
              Abbiamo inviato una mail a <span>{emailFormik.values.email}</span>
              . Apri il link che trovi al suo interno per aggiornare l'email.
            </div>
            <Button
              onClick={async () => {
                await resendEmailVerificationCode.mutateAsync().catch((e) => {
                  raiseException(e);
                  console.error(e);
                });
              }}
            >
              Non hai ricevuto la mail? Rinviala
            </Button>
          </div>
        </Modal>
      )}
      <h1 className={styles.title}>Informazioni personali</h1>
      <div className={styles.infoWrapper}>
        <div className={styles.topPart}>
          {userInfo.data?.user?.id ? (
            <div className={styles.profilePicture}>
              <Avatar
                avatarImg={`${envs.REACT_APP_S3_ENDPOINT}/pic-${userInfo.data?.user.id}`}
              />
            </div>
          ) : null}
          <div className={styles.userName}>
            <Input
              label="Nome"
              value={userGivenName}
              disabled
              onChange={(e) => setUserGivenName(e.target.value)}
            />
            <Input
              label="Cognome"
              value={userFamilyName}
              disabled
              onChange={(e) => setUserFamilyName(e.target.value)}
            />
            <Input
              label="Genere"
              select
              disabled
              onChange={(e) => setUserGender(e.target.value)}
              options={[
                { name: "Uomo", value: "male" },
                { name: "Donna", value: "female" },
              ]}
              value={userGender}
            />
          </div>
          <div className={styles.userNumber}>
            <Input
              label="Livello di formazione"
              disabled
              value={therapistTitle}
              onChange={(e) => setTherapistTitle(e.target.value)}
            />
            <Input
              label="Telefono"
              disabled
              value={userPhoneNumber}
              onChange={(e) => setUserPhoneNumber(e.target.value)}
            />
          </div>
          <div className={styles.userNumber}>
            <Input
              label={userInfo?.data?.user.therapies?.includes('psychiatry') ? 'Ordine' : `Albo d'iscrizione`}
              disabled
              value={licenceRegisterPlace}
            />
            <Input
              label="Assicurazione"
              disabled
              value={`${licenceRegisterWith} ${licenceRegisterNumber}`}
            />
          </div>
          {!therapiesType.includes('psychiatry') ? (
            <div className={styles.userNumber}>
              <Input
                label="Laureato in"
                disabled
                value={`${graduationCourse} presso ${graduationPlace}`}
              />
              <Input
                label="Tipo partita I.V.A."
                disabled
                value={vatNumberType ? capitalize(vatNumberType) : ""}
              />
            </div>
          ) : (
            <div className={styles.userNumber}>
              <Input
                label="Laurea in Medicina"
                disabled
                value={graduationCourse}
              />
              <Input
                label="Specializzazione in Psichiatria"
                disabled
                value={graduationPlace}
              />
              <Input
                label="Tipo partita I.V.A."
                disabled
                value={vatNumberType ? capitalize(vatNumberType) : ""}
              />
            </div>
          )}
          <div className={styles.userNumber}>
            <Input
              label="Percorsi di terapia"
              disabled
              value={therapiesType
                .map((t) => {
                  if (t === "underage") {
                    return "Minori";
                  }
                  if (t === "couple") {
                    return "Di coppia";
                  }
                  if (t === "single") {
                    return "Individuale";
                  }
                  if (t === "psychiatry") {
                    return "Psichiatria";
                  }
                })
                .join(", ")}
            />
          </div>
          <div className={styles.userNumber}>
            <Input
              label="Descrizione"
              disabled
              textarea
              value={therapistDescription}
              onChange={(e) => setTherapistDescription(e.target.value)}
            />
          </div>
        </div>
        <div className={styles.bottomPart}>
          <div className={styles.inputContainer}>
            <div className={styles.inputName}>Email</div>
            <div className={styles.inputToModify}>
              <Input label="Email attuale" disabled value={userEmail} />
              <Button
                variant="tertiary"
                onClick={() => setModifyEmailModalIsOpen(true)}
              >
                Modifica
              </Button>
            </div>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.inputName}>Password</div>
            <div className={styles.inputToModify}>
              <Input
                label="Password attuale"
                disabled
                type="password"
                value={"**************"}
              />
              <Button
                variant="tertiary"
                onClick={() => {
                  setModifyPwModalIsOpen(true);
                }}
              >
                Modifica
              </Button>
            </div>
          </div>
        </div>
        <InfoBox
          onClick={() => {
            navigate(routes.DashboardFaq.path);
          }}
          text="Per eliminare il profilo è necessario chiedere al Supporto."
        />
      </div>
    </div>
  );
};
