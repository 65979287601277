import Menu from "components/organisms/Menu";
import { useState } from "react";
import styles from "./MobileHeader.module.scss";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "customHooks/reduxHooks";
import routes from "routes";
import Tabs from "components/atoms/Tabs";
import { CalendarIcon, ListBulletIcon } from '@heroicons/react/24/outline';
import { setTabAppointment } from "store/slices/tabAppointmentSlice";
import { envs } from "../../../utils/envs";
import logo from '../../../theme/images/logo-new.webp';
import Avatar from '../../atoms/Avatar';

interface Props {
  openProfile?: any;
  profileIsOpen?: boolean;
  chatWith?: any;
  profileDataId: string;
  showDocuments?: boolean;
  setShowDocuments?: any;
  showNotes?: boolean;
  setShowNotes?: any;
  showSurveyAnswers?: boolean;
  setShowSurveyAnswers?: any;
  getUserInfo: any;
}
export const MobileHeader = ({
  openProfile,
  profileDataId,
  chatWith,
  getUserInfo
}: Props) => {
  const appointmentType = useAppSelector(
    (store) => store.tabAppointment.listType
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {pathname} = location;
  const appHomeUrl = envs.REACT_APP_FRONTEND_URL;
  const patientName = useAppSelector((store) => store.patientInChat);

  const isPatientPsychiatry = getUserInfo?.data?.user?.therapyType === 'psychiatry'

  chatWith = chatWith ? chatWith : "Chat";

  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);

  const getTitleByPath = (pathname: string): string => {
    switch (pathname) {
      case routes.Chat.path:
        return chatWith;
      case routes.Appointments.path:
        return 'Appuntamenti';
      case routes.Profile.path:
        return 'Profilo';
      case routes.ClinicalPapers.path:
        return isPatientPsychiatry ? 'Documenti Clinici' : 'Allegati';
      case routes.ProfileSection.links.info:
        return 'Informazioni personali';
      case routes.ProfileSection.links.payment:
        return 'Metodo di pagamento';
      case routes.ProfileSection.links.preferences:
        return 'Gestione preferenze';
      case routes.ProfileSection.links.reviews:
        return 'Recensioni';
      case routes.ProfileSection.links.billing:
        return 'Dati di fatturazione';
      case routes.FaqPage.path:
        return 'Assistenza';
      default:
        return '';
    }
  };

  return (
    <div
      className={`${styles.mobileHeader} ${
        mobileMenuIsOpen && styles.incraseZindex
      }`}
    >
      <div
        className={styles.menuIconWrapper}
        onClick={() => {
          if (
            pathname.includes(routes.Profile.path) &&
            pathname !== routes.Profile.path
          ) {
            navigate(routes.Profile.path);
          } else {
            openProfile(false);
            setMobileMenuIsOpen(true);
          }
        }}
      >
        {(pathname.includes(routes.Profile.path) &&
          pathname !== routes.Profile.path) ? (
          <ChevronLeftIcon width={35} />
        ) : (
          <>
            <div className={styles.icon}></div>
            <div className={styles.word}>Menu</div>
          </>
        )}
      </div>
      <div className={styles.username}>
        {(pathname === routes.Chat.path && patientName.name?.trim() !== '')
          ? <img
            className={styles.logo}
            src={logo}
            alt="logo Mymentis"
            onClick={() => window.location.href = `${appHomeUrl}`}
          />
          : getTitleByPath(pathname)
        }
      </div>
      {pathname === routes.Appointments.path ?
        <div className={styles.therapistTabWrapper}>
          <Tabs
            className={styles.therapistTab}
            activeLabelClassName={styles.customActiveLabel}
            labelClassName={styles.customLabelWrapper}
            items={[
              {
                key: 1,
                active: appointmentType === 'list',
                label: (
                  <div
                    className={styles.tabLabel}
                    onClick={() => dispatch(setTabAppointment("list"))}
                  >
                    <div className={styles.icon}>
                      <ListBulletIcon />
                    </div>
                    <span>Lista</span>
                  </div>
                ),
                content: <div></div>,
              },
              {
                key: 2,
                active: appointmentType === "calendar",
                label: (
                  <div
                    className={styles.tabLabel}
                    onClick={() => dispatch(setTabAppointment("calendar"))}
                  >
                    <div className={styles.icon}>
                      <CalendarIcon />
                    </div>
                    <span>Calendario</span>
                  </div>
                ),
                content: <div></div>,
              },
            ]}
          ></Tabs>
        </div>
        :
        (chatWith !== 'Chat' || patientName.name?.trim() !== '') &&
        pathname === routes.Chat.path ? (
          <div
            onClick={() => {
              openProfile(prev => !prev);
            }}
          >
            {
              chatWith !== 'Chat' &&
              <Avatar className={styles.profileIcon}
                      avatarImg={`${envs.REACT_APP_S3_ENDPOINT}/pic-${profileDataId}`}/>
            }
          </div>
        ) : <div></div>
      }
      <Menu
        closeMenu={() => setMobileMenuIsOpen(false)}
        className={
          mobileMenuIsOpen ? styles.openMobileMenu : styles.closeMobileMenu
        }
      />
    </div>
  );
};
