import styles from "./ModalCurrentTherapist.module.scss";
import FormHeader from "../../atoms/FormHeader";
import Button from "../../atoms/Button";
import Modal from '../../organisms/Modal';

export const ModalCurrentTherapist = ({therapist, modalClose, goToFeedbackModal, className}: any) => {

	const closeTherapistModal = () => {
		modalClose(false);
	}

	const isPsychiatry = therapist.therapyType.includes("psychiatry");

	return (
		<Modal modal subClassName={className} className={className} close={closeTherapistModal}>
			<FormHeader
				closeModal={closeTherapistModal}
				title=""
				closeButton
			/>
			<div className={styles.description}>Abbiamo
				scelto <span style={{fontWeight: '600'}}>{therapist.given_name} {therapist.family_name}</span> come
				tua {isPsychiatry ? "psichiatra " : "terapeuta "} perché riteniamo che le sue competenze siano perfettamente
				adatte alle tue esigenze emerse dal questionario.
				<br/>
				<br/>
				Ti incoraggiamo a dare una
				possibilità a <span style={{fontWeight: '600'}}>{therapist.given_name} {therapist.family_name}</span>,
				incontrandola e approfondendo la vostra conoscenza.
				<br/>
				<br/>
				Tuttavia, se desideri procedere con il cambio, rispetteremo la tua scelta.
				<br/>
				<br/>
				Ricorda che se decidi di cambiare {isPsychiatry ? "psichiatra " : "terapeuta "}, verrai assegnato ad uno e non
				potrai più
				contattare <span style={{fontWeight: '600'}}>{therapist.given_name} {therapist.family_name}</span>.
			</div>
			<div className={styles.btnWrapper}>
				<Button
					variant="secondary"
					onClick={closeTherapistModal}
				>
					Annulla
				</Button>
				<Button
					onClick={() => {
						modalClose(false);
						goToFeedbackModal(true)
					}}
				>
					Prosegui
				</Button>
			</div>
		</Modal>
	)
};