import { forwardRef } from "react";
import styles from "./GenericForm.module.scss";

interface Props {
  className?: string;
  title: string | JSX.Element;
  description: string | JSX.Element;
  answer?: JSX.Element[];
  cta?: JSX.Element[];
  isModal?: boolean;
}
export const GenericForm = forwardRef<HTMLDivElement, Props>(
  ({className, title, description, answer, cta, isModal}, ref) => {
    return (
      <div ref={ref} className={`${styles.genericForm} ${className}`} onClick={e => {
        isModal && e.stopPropagation()
      }}>
        <div className={styles.card}>
          <h4 className={styles.title}>{title}</h4>
          <div className={styles.description}>{description}</div>
        </div>
        {answer && (
          <div className={styles.answersWrapper}>
            {answer.map((i, index) => {
              return (
                <div key={index} className={styles.answer}>
                  {i}
                </div>
              );
            })}
          </div>
        )}
        {cta && (
          <div className={styles.btnWrapper}>
            {cta?.map((i, index) => {
              return <div key={index}>{i}</div>;
            })}
          </div>
        )}
      </div>
    );
  }
);
