import {RetrievePassword} from "pages/RetrivePassword/RetrievePassword";
import Chat from "pages/Chat";
import WaitingRoom from "components/WaitingRoom";
import Survey from "pages/Survey";
import PatientRegister from "pages/PatientRegister";
import TherapistRegister from "pages/TherapistRegister";
import SupervisedPatientRegister from "pages/SupervisedPatientRegister";
import ChooseTherapist from "pages/ChooseTherapist";
import Home from "pages/Home";
import AboutUs from "pages/AboutUs";
import OurApproach from "pages/OurApproach";
import Reviews from "pages/Reviews";
import FaqPage from "pages/FaqPage";
import ContactUs from "pages/ContactUs";
import Login from "pages/Login";
import ConfirmTherapist from "pages/ConfirmTherapist";
import ChangePassword from "pages/ChangePassword";
import VerifyEmail from "pages/VerifyEmail";
import InformedConsent from "pages/InformedConsent";
import {GeneralPrivacy} from "pages/Privacy/GeneralPrivacy";
import {SurveyPrivacy} from "pages/Privacy/SurveyPrivacy";
import PatientTherapistPrivacy from "pages/Privacy/PatientTherapistPrivacy";
import {TermAndCondition} from "pages/Privacy/TermAndCondition";
import { InformedConsentView } from "pages/InformedConsentView/InformedConsentView"
import { PatientBookingFirstAppointment } from "pages/PatientBookingFirstAppointment/PatientBookingFirstAppointment"
import { PsychotherapistsRegister } from "pages/PsychotherapistsRegister/PsychotherapistsRegister"
const routes = {
	// public area
	TermsAndCondition: {
		path: "/termini-e-condizioni",
		element: <TermAndCondition className="pageWithHeader"/>,
	},
	PatientTherapistPrivacy: {
		path: "/privacy-paziente-terapeuta",
		element: <PatientTherapistPrivacy className="pageWithHeader"/>,
	},
	GeneralPrivacy: {
		path: "/privacy-generale",
		element: <GeneralPrivacy className="pageWithHeader"/>,
	},
	SurveyPrivacy: {
		path: "/privacy-paziente",
		element: <SurveyPrivacy className="pageWithHeader"/>,
	},
	InformedConsent: {
		path: "/consenso-informato/:code",
		element: <InformedConsent className="pageWithHeader"/>,
	},
	InformedConsentView: {
		path: "/visualizza-il-consenso-informato/:id",
		element: <InformedConsentView className="pageWithHeader"/>,
	},
	PatientBookingFirstAppointment: {
		path: '/prenotazione-appuntamento',
		element: <PatientBookingFirstAppointment className="pageWithHeader"/>,
	},
	VerifyEmail: {
		path: "/verifica-email/:code",
		element: <VerifyEmail className="pageWithHeader"/>,
	},
	RetrievePassword: {
		path: "/recupero-password/:code",
		element: <RetrievePassword className="pageWithHeader"/>,
	},
	ChangePassword: {
		path: "/cambio-password",
		element: <ChangePassword className="pageWithHeader"/>,
	},
	Login: {
		path: "/login",
		element: <Login className="pageWithHeader"/>,
	},
	ConfirmTherapist: {
		path: "/login/2fa",
		element: <ConfirmTherapist className="pageWithHeader"/>,
	},
	Home: {
		path: "/",
		element: <Login className="pageWithHeader"/>,
	},
	AboutUs: {
		path: "/chi-siamo",
		element: <AboutUs/>,
	},
	OurApproach: {
		path: "/il-nostro-approccio",
		element: <OurApproach/>,
	},
	Reviews: {
		path: "/recensioni",
		element: <Reviews/>,
	},
	FaqPage: {
		path: "/faq",
		element: <FaqPage/>,
	},
	ContactUs: {
		path: "/contatti",
		element: <ContactUs/>,
	},
	Survey: {
		path: "/questionario/:question",
		links: {
			intro: "/questionario/introduzione",
			start: "/questionario/incorso",
		},
		element: <Survey className="pageWithHeader"/>,
	},
	TherapistRegister: {
		path: "/registrazione-terapeuta/asdAQW_ASd_456ASD",
		element: <TherapistRegister className="pageWithHeader"/>,
	},
	PsychotherapistsRegister: {
		path: "/registrazione-psichiatra/asdAQW_ASd_456ASD",
		element: <PsychotherapistsRegister className="pageWithHeader"/>,
	},
	SupervisedPatientRegister: {
		path: "/registrazione-supervisione/ncu8Aj_d8n",
		element: <SupervisedPatientRegister className="pageWithHeader"/>,
	},
	PatientRegister: {
		path: "/registrazione",
		element: <PatientRegister className="pageWithHeader"/>,
	},
	ChooseTherapist: {
		path: "/pagina-di-benvenuto",
		element: <ChooseTherapist className="pageWithHeader"/>,
	},
	// dashboard
	Chat: {
		path: "/dashboard/chat",
		element: <Chat/>,
	},
	Appointments: {
		path: "/dashboard/appuntamenti",
		element: <Chat/>,
	},
	ClinicalPapers: {
		path: "/dashboard/documenti-clinici",
		element: <Chat/>,
	},
	Profile: {
		path: "/dashboard/profilo",
		element: <Chat/>,
	},
	ProfileSection: {
		path: "/dashboard/profilo/:section",
		links: {
			info: "/dashboard/profilo/informazioni-personali",
			payment: "/dashboard/profilo/pagamenti",
			preferences: "/dashboard/profilo/preferenze",
			reviews: "/dashboard/profilo/recensioni",
			billing: "/dashboard/profilo/fatturazione",
		},
		element: <Chat/>,
	},
	DashboardFaq: {
		path: "/dashboard/faq",
		element: <Chat/>,
	},
	WaitingRoom: {
		path: "/seduta/:appointmentId",
		element: <WaitingRoom/>,
	},
};

export default routes;
