import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../components/atoms/Button";
import Input from "../../components/atoms/Input";
import { useAppDispatch } from "../../customHooks/reduxHooks";
import { setInfo } from "../../store/slices/infoSlice";
import { trpc } from "../../utils/trpc";
import styles from "./Login.module.scss";
import Modal from "components/organisms/Modal";
import GenericForm from "components/molecules/GenericForm";
import routes from "routes";
import { useFormik } from "formik";
import * as yup from "yup";
import { Alert, Spin } from "antd";
import { getToken, isLocalStorageAvailable } from "utils/getToken";
import { analyticsService } from "../../utils/analyticsService";
import { raiseException } from "../../utils/raiseException";
import trimString from "../../utils/trimString";
import { checkIf48HoursPassed } from "utils/checkIf48HoursPassed";
import { firstAppointmentExist } from "../../utils/checkAppointmentExist";
import { deleteCookie, getTrackingCampaignUtmData } from "../../utils/cookies";

export const Login = ({ className }: any) => {
  const location = useLocation();
  const { hash } = location;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState<string | null>();
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    analyticsService({ 'event': 'virtualPageview', 'pageUrl': '/login', 'pageTitle': 'Login' });
  }, [])

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .trim()
        .matches(
          /^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i,
          "L'email inserita ha un formato non valido"
        )
        .required("L'email è un campo obbligatorio"),
      password: yup.string().required("La password è un campo obbligatorio"),
    }),
    onSubmit: (values, actions) => {
      login(values.email, values.password);
    },
  });

  const [resetPasswordEmailError, setResetPasswordEmailError] = useState("");

  const [passwordTypeToggle, setPasswordTypeToggle] = useState(true);
  const [getUserInfoToggle, setGetUserInfoToggle] = useState(false);
  const [email, setEmail] = useState("");
  const [retrivePwModalIsOpen, setRetrivePwModalIsOpen] = useState(false);
  const [searchParams] = useSearchParams();
  let campaignId = ''

  const medium = searchParams.get("utm_medium");
  const source = searchParams.get("utm_source");
  const campaign = searchParams.get("utm_campaign");

  const loginService = trpc.authService.auth.login.useMutation();
  const retrivePassword = trpc.authService.auth.forgotPassword.useMutation();
  const getUserInfo = trpc.mainService.user.getCurrentUserInfo.useQuery(
    undefined,
    { enabled: getUserInfoToggle }
  );

  const userInfoForRedirect = trpc.mainService.user.getCurrentUserInfo.useQuery(
    undefined,
    { enabled: !!getToken("accessToken") }
  );

  const getAppointments = trpc.mainService.appointment.getAppointments.useQuery(
    {patientId: getUserInfo?.data?.user.id}, {enabled: true}
  ).data

  const sendTrackingCampaign= trpc.mainService.tracking.sendTrackingCampaign.useMutation();

  const updateTrackingCampaign =
    trpc.mainService.tracking.updateTrackingCampaign.useMutation();

  useEffect(() => {
    (async () => {
      const utmData: any = getTrackingCampaignUtmData('utm_data');
      if (medium && source && campaign) {
        await setTrackingCampaign(medium, source, campaign);
      } else if (utmData?.utm_medium && utmData?.utm_source && utmData?.utm_campaign) {
        await setTrackingCampaign(utmData?.utm_medium, utmData?.utm_source, utmData?.utm_campaign);
      }
    })();
  }, []);

  const setTrackingCampaign = async (medium: string, source: string, campaign: string) => {
    const storeCampaignId = localStorage.getItem('campaignId');
    if (!storeCampaignId) {
      const trackingCampaign: any = await sendTrackingCampaign.mutateAsync({
        medium,
        source,
        campaign,
      });
      localStorage.setItem('campaignId', trackingCampaign?.campaign?.id);
    } else {
      campaignId = storeCampaignId;
    }
  };

  const updateCurrentTrackingCampaign = async (role: string, id: string) => {
    if (role === 'patient' && id) {
      if (campaignId) {
        await updateCampaign(campaignId, id);
      } else {
        const storedCampaignId = localStorage.getItem("campaignId");
        if (storedCampaignId) {
          await updateCampaign(storedCampaignId, id);
        }
      }
      localStorage.removeItem("campaignId");
      const utmData: any = getTrackingCampaignUtmData('utm_data');
      if (utmData?.utm_medium && utmData?.utm_source && utmData?.utm_campaign) {
        deleteCookie('utm_data');
      }
    }
  }

  const updateCampaign = async (id: string, patientId: string) =>{
    return await updateTrackingCampaign.mutateAsync({id, patientId})
  };

  const sendRetriveLink = () => {
    retrivePassword
      .mutateAsync({ email: trimString(email) })
      .then((data) => {
        setRetrivePwModalIsOpen(false);
        dispatch(
          setInfo({
            variant: "confirm",
            text: "Il link per il recupero password è stato inviato all'indirizzo richiesto",
          })
        );
      })
      .catch((e) => {
        raiseException(e);
        console.error(e)
      });
  };

  const login = async (email: string, password: string) => {
    setLoginError(null);
    setLoginLoading(true);
    try {
      // document.cookie = `accessToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
      localStorage.removeItem("greyBlock");
      localStorage.removeItem("accessToken");
      const data: any = await loginService.mutateAsync({ email: trimString(email), password: trimString(password) })
        localStorage.removeItem("storedChat");
        const accessToken = data.accessToken;
        const refreshToken = data.refreshToken;
        const challenge = data.challenge;
        if (accessToken !== undefined) {
          // document.cookie = `accessToken=${accessToken}; Path=/;`;
          // document.cookie = `refreshToken=${refreshToken}; Path=/;`;
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("refreshToken", refreshToken);
          const userData = await getUserInfo.refetch();
          const userId = userData?.data?.user?.id;
          const userRole = userData?.data?.user?.role;
          if (userRole && userId) {
            await updateCurrentTrackingCampaign(userRole, userId);
          }
          if (userId) {
            localStorage.setItem("userId", userId);
          }
          setGetUserInfoToggle(true);
        }
        if (challenge !== undefined) {
          if (challenge.name === "NEW_PASSWORD_REQUIRED") {
            navigate(
              `${routes.ChangePassword.path}?email=${email
                .trim()
                .toLowerCase()}&role=${challenge.role}`
            );
          }
          if (challenge.name === "EMAIL_MFA") {
            navigate(`${routes.ConfirmTherapist.path}?email=${encodeURIComponent(email)}&session=${challenge.session}`, {
              state: {email, session: challenge.session, hash},
            });
          }
        }
    } catch (error: any) {
      setLoginLoading(false);
      raiseException(error);
      if (error?.shape?.errorCode === "WrongCredentials") {
        formik.setErrors({
          email: " ",
          password: "Le credenziali inserite sono errate",
        });
      } else {
        setLoginError(
          "Si è verificato un errore inaspettato. Verifica di essere connesso a internet e riprova."
        );
      }
    }
  };

  useEffect(() => {
    if (getUserInfoToggle) {
      if (getUserInfo.isFetching) {
        return;
      }
      if (!getUserInfo.isFetching && getUserInfo.status === "success" && getAppointments) {
        const user = getUserInfo.data.user as any;
        const result = firstAppointmentExist(getAppointments?.appointments, user.therapistId);
        if (hash) {
          const pathToRedirectInB64 = hash.slice(1, hash.length);
          return navigate(atob(pathToRedirectInB64));
        }
        if (user.role === "patient") {
          if (user.therapistId !== null) {
            setGetUserInfoToggle(false);
            localStorage.setItem("user", JSON.stringify(user));
            if (checkIf48HoursPassed(user?.chats) || result) {
              navigate(routes.Chat.path, {state: {user}});
            } else {
              navigate(routes.PatientBookingFirstAppointment.path);
            }
          } else {
            setGetUserInfoToggle(false);
            navigate(routes.ChooseTherapist.path, { state: user });
          }
        } else if (user.role === "therapist") {
          setGetUserInfoToggle(false);
          localStorage.setItem("user", JSON.stringify(user));
          navigate(routes.Chat.path, { state: { user } });
        }
      }
      if (getUserInfo.status === "error") {
        dispatch(
          setInfo({
            title: "Erroe",
            text: "Errore nel recupero informazioni dell'utente. Verifica di essere connesso a internet e riprova.",
            variant: "error",
          })
        );
        setGetUserInfoToggle(false);
      }
    }
  }, [getUserInfoToggle, getUserInfo, getAppointments]);

  useEffect(() => {
    if (getToken("accessToken") && userInfoForRedirect.isSuccess && getAppointments) {
      const userInfo = userInfoForRedirect.data.user;
      const result = firstAppointmentExist(getAppointments?.appointments, userInfo?.therapistId!);
      if (hash) {
        const pathToRedirectInB64 = hash.slice(1, hash.length);
        return navigate(atob(pathToRedirectInB64));
      }
      if (userInfo.role === "therapist") {
        return navigate(routes.Chat.path);
      }

      if (!userInfo.therapistId) {
        navigate(routes.ChooseTherapist.path, {
          ...(userInfo.emailVerified
            ? {}
            : {
                state: userInfo.email,
              }),
        });
      } else {
        if (checkIf48HoursPassed(userInfo?.chats) || result) {
          navigate(routes.Chat.path);
        } else {
          navigate(routes.PatientBookingFirstAppointment.path);
        }
      }
    }
  }, [userInfoForRedirect, getAppointments]);

  return (
    !isLocalStorageAvailable() ? (
      <div className={`${styles.login} ${className}`}>
        <div className={styles.loginCard}>
          <div className={styles.titleWrapper}>
            <div className={styles.storageSupportDescription}>
              Il tuo browser non permette il salvataggio dei dati sullo “storage locale” e questo non permette il
              corretto funzionamento della piattaforma, verifica le impostazioni del browser e riprova.
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className={`${styles.login} ${className}`}>
        {retrivePwModalIsOpen && (
          <Modal close={() => {
            setRetrivePwModalIsOpen(false);
          }}>
            <GenericForm
              isModal
              className={styles.retrivePwForm}
              description={
                "Ti invieremo un link per effettuare il recupero della tua password."
              }
              title={"Recupero password"}
              answer={[
                <Input
                  type="email"
                  label="Email"
                  value={email}
                  error={resetPasswordEmailError}
                  onChange={(e) => {
                    if (
                      /^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i.test(
                        email
                      )
                    ) {
                      setResetPasswordEmailError("");
                    }
                    setEmail(e.target.value);
                  }}
                />,
              ]}
              cta={[
                <Button
                  variant="tertiary"
                  onClick={() => {
                    setEmail("");
                    setRetrivePwModalIsOpen(false);
                  }}
                >
                  Annulla
                </Button>,
                <Button
                  onClick={() => {
                    if (!email) {
                      setResetPasswordEmailError(
                        "L'email è un campo obbligatorio"
                      );
                      return;
                    }
                    if (
                      !/^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i.test(
                        email
                      )
                    ) {
                      setResetPasswordEmailError(
                        "L'email inserita ha un formato invalido"
                      );
                      return;
                    }
                    setEmail("");
                    sendRetriveLink();
                  }}
                >
                  Recupera password
                </Button>,
              ]}
            />
          </Modal>
        )}
        {userInfoForRedirect.isLoading && getToken("accessToken") && (
          <Modal>
            <Spin />
          </Modal>
        )}
        <div className={styles.loginCard}>
          <div className={styles.titleWrapper}>
            <h4 className={styles.title}>Ciao!</h4>
            <div className={styles.description}>
              Ti diamo il benvenuto su Mymentis. Fai il login per iniziare.
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.inputsWrapper}>
              <Input
                placeholder="Email"
                label="Email"
                name="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={
                  (formik.errors.email &&
                    formik.touched.email &&
                    formik.errors.email) as string
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    submitButtonRef.current?.click();
                  }
                }}
              />
              <Input
                className={styles.password}
                placeholder="Password"
                type={passwordTypeToggle ? "password" : "text"}
                label="Password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  (formik.errors.password &&
                    formik.touched.password &&
                    formik.errors.password) as string
                }
                rightIcon={
                  <p
                    className={styles.showPw}
                    onClick={() => {
                      setPasswordTypeToggle(!passwordTypeToggle);
                    }}
                  >
                    {passwordTypeToggle ? "Mostra" : "Nascondi"}
                  </p>
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    submitButtonRef.current?.click();
                  }
                }}
              />
              <Button
                variant="tertiary"
                onClick={(e) => {
                  e.preventDefault();
                  setRetrivePwModalIsOpen(true);
                }}
              >
                Hai dimenticato la password?
              </Button>
              {loginError && <Alert type="error" message={loginError} closable />}
            </div>
            <Button disabled={loginLoading} type="submit" ref={submitButtonRef}>
              Accedi
            </Button>
          </form>
          <div className={styles.register}>
            Non hai ancora un profilo?{" "}
            <span
              onClick={() => {
                navigate(routes.Survey.links.intro);
              }}
            >
            Registrati
          </span>
          </div>
        </div>
      </div>
    )
  );
};
