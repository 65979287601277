const recountAmountValue = (couponInfo: any, surveys: any, amountValue: number): number => {
  if (!couponInfo?.id) {
    return amountValue;
  }

  if (couponInfo.discount_type === 'percentage') {
    if (couponInfo.discount_value === 100) {
      return 0;
    }
    return amountValue - (amountValue * (couponInfo.discount_value / 100));
  } else {
    const surveyType = surveys?.at(0)?.type;
    const discountMap = {
      single: 50,
      underage: 50,
      couple: 60,
      psychiatry: 150
    };

    if (couponInfo.discount_value === discountMap[surveyType]) {
      return 0;
    } else {
      return amountValue - couponInfo.discount_value;
    }
  }
}

export default recountAmountValue;
