import { trpc } from '../../../utils/trpc';
import Button from '../../atoms/Button';
import FormHeader from '../../atoms/FormHeader';
import styles from './PaidAppointmentDisclaimerModal.module.scss';
import { Socket } from 'socket.io-client';
import { useRouteLoaderData } from 'react-router-dom';
import { raiseException } from '../../../utils/raiseException';
import { getNormalizedDate } from '../../../utils/dateTime';
import Modal from '../../organisms/Modal';

interface Props {
  closeModal: () => void;
  appointment?: any;
  messageId?: number;
  message: string;
  chatId: string;
  onModifyAppointmentInChat?: (appointmentId: any, messageId: number) => void;
  onModifyAppointmentInAppointments?: () => void;
  onCancel: () => void;
  page: 'chat' | 'appointment'
}

export const PaidAppointmentDisclaimerModal = ({
    closeModal,
    appointment,
    message,
    chatId,
    onModifyAppointmentInChat,
    onModifyAppointmentInAppointments,
    onCancel,
    messageId,
    page
  }: Props) => {

  const socket = useRouteLoaderData("root") as Socket;

  const userInfo = trpc.mainService.user.getCurrentUserInfo.useQuery().data;

  const cancelAppointment =
    trpc.mainService.appointment.cancelAppointment.useMutation();

  const makeAppointmentCancel = async () => {
    try {
      const {message: successfullyCancelMessage} = await cancelAppointment.mutateAsync({ appointmentId: appointment.id })
      if (successfullyCancelMessage) {
        socket.emit(
          "sendTextMessage",
          {
            type: "text",
            content: `&moved ${userInfo?.user.given_name} ${
              userInfo?.user.family_name
            } ha cancellato la seduta di ${getNormalizedDate(
              appointment.startTime
            ).toLocaleString("IT", {
              weekday: "long",
              day: "2-digit",
              month: "long",
            })} alle ore ${getNormalizedDate(appointment.startTime).toLocaleString(
              "IT",
              {
                hour: "2-digit",
                minute: "2-digit",
              }
            )} per il seguente motivo:&moved ${message}`,
          },
          chatId
        );
        if (onCancel) {
          onCancel();
        }
        closeModal();
      }
    } catch (error) {
      raiseException(error);
      console.error("ERROR", error)
      closeModal();
    }
  }

  const modifyAppointment = () => {
    if (page === 'chat') {
      if (onModifyAppointmentInChat && messageId) {
        onModifyAppointmentInChat(appointment, messageId)
      }
    } else {
      if (onModifyAppointmentInAppointments) {
        onModifyAppointmentInAppointments()
      }
    }
  }

  return (
    <div className={styles.modifyModalContent} onClick={e => {
      e.stopPropagation()
    }}>
      <FormHeader
        closeModal={closeModal}
        closeButton
        title=""
      />
      <div className={styles.modalDescription}>
        Stai per cancellare una seduta già pagata.
        Se confermi la cancellazione, il cliente riceverà automaticamente il rimborso.
        Se invece vuoi solo cambiare data o orario, clicca su Sposta.
      </div>
      <div className={styles.btnWrapper}>
        <Button
          className={styles.moveButton}
          onClick={modifyAppointment}>
          Sposta seduta
        </Button>
        <Button
          className={styles.cancelButton}
          onClick={makeAppointmentCancel}>
          Cancella seduta
        </Button>
      </div>
    </div>
  );
};
