import {
  CheckBadgeIcon,
  Cog6ToothIcon,
  ExclamationCircleIcon,
  MicrophoneIcon,
  PhoneIcon,
  SpeakerWaveIcon,
  SpeakerXMarkIcon,
  VideoCameraIcon,
  VideoCameraSlashIcon,
  ViewfinderCircleIcon,
} from "@heroicons/react/24/solid";
import OT, { AudioOutputDevice } from "@opentok/client";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import routes from "routes";
import styles from "./VideoCallRoom.module.scss";
import { useAppDispatch, useAppSelector } from "customHooks/reduxHooks";
import { delStream, setStream } from "store/slices/infoSlice";
import Button from "components/atoms/Button";
import { runInContext } from "vm";
import Modal from "components/organisms/Modal";
import GenericForm from "components/molecules/GenericForm";
import FormHeader from "components/atoms/FormHeader";

interface Props {
  token: string;
  appointmentId: string;
  pubOptions: OT.PublisherProperties;
  session: OT.Session;
  outputAudioDeviceList: AudioOutputDevice[];
  inputAudioDeviceList: AudioOutputDevice[];
  inputVideoDeviceList: AudioOutputDevice[];
  audioSource: string;
}

export const VideoCallRoom: FC<Props> = ({
  outputAudioDeviceList,
  inputAudioDeviceList,
  inputVideoDeviceList,
  token,
  pubOptions,
  session,
  audioSource,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [publisherAudioSource, setpublisherAudioSource] = useState(audioSource);
  const [optionPublisher, setOptionPublisher] = useState(pubOptions);
  const [settingModalIsOpen, setSettingModalIsOpen] = useState(false);
  const [showVideo, setShowVideo] = useState(pubOptions.publishVideo);
  const [activeAudio, setActiveAudio] = useState(pubOptions.publishAudio);
  const [activeVideoFilter, setActiveVideoFilter] = useState(
    pubOptions.videoFilter
  );
  const [publisher, setPublisher] = useState<OT.Publisher>();
  const [callEnded, setCallEnded] = useState(false);
  const [callError, setCallError] = useState("");
  const streams = useAppSelector((store) => store.info.stream);

  const fitMode = window.innerWidth < 700 ? "contain" : "cover";
  const handleError = (error: any) => {
    if (error) {
      if (error.name === "OT_USER_MEDIA_ACCESS_DENIED") {
        setCallError(
          "Non hai dato al browser il permesso di utilizzare webcam e microfono, concedi le autorizazioni e ricarica la pagina per continuare"
        );
      } else {
        setCallError("Ops! Qualcosa è andato storto.");
      }
    }
  };

  const initSession = () => {
    // create subscriber
    // This code allows you to subscribe to new streams published by other clients in the session.
    session.on("streamCreated", (event) => {
      dispatch(setStream(event.stream.streamId));
    });
    session.on("streamDestroyed", (event) => {
      dispatch(delStream(event.stream.streamId));
    });

    session.on("streamCreated", (event) => {
      const subscriber = session.subscribe(
        event.stream,
        "subscriber",
        {
          insertMode: "append",
          width: "100%",
          height: "100%",
          fitMode: "contain",
          showControls: false,
        },
        (err) => {
          handleError(err);
        }
      );
      // session.unsubscribe(subscriber);
      // session.subscribe(
      //   event.stream,
      //   "subscriber",
      //   {
      //     insertMode: "append",
      //     width: "100%",
      //     height: "100%",
      //     fitMode: "contain",
      //     showControls: false,
      //   },
      //   (err) => {
      //     handleError(err);
      //   }
      // );

      // fix for safari on ios: if you enter the call when there is only one person, it will trigger full screen on the video on ios. This fix avoids the full screen
      if (streams.length <= 1) {
        session.subscribe(
          event.stream,
          undefined,
          {
            insertMode: "append",
            audioVolume: 0,
            width: "100%",
            height: "100%",
            fitMode: "contain",
            showControls: false,
          },
          (err) => {
            handleError(err);
          }
        );
      }
    });

    // create publisher
    const publisher = OT.initPublisher(
      "publisher",
      {
        ...pubOptions,
        insertMode: "append",
        width: "100%",
        height: "100%",
        fitMode: "contain",
        style: {
          buttonDisplayMode: "off",
        },
      },
      (err) => {
        handleError(err);
      }
    );

    setPublisher(publisher);

    // session.on("streamDestroyed", (e) => {
    //   e.preventDefault();
    //   session.disconnect();
    //   navigate("/auth/chat");
    // });

    publisher.on("streamDestroyed", (e) => {
      e.preventDefault();
      session.unpublish(publisher);
      // publisher.destroy();
      // navigate(routes.Chat.path);
    });

    publisher.on({
      accessAllowed: (event: any) => {},
      accessDenied: (event: any) => {},
    });

    session.on("sessionDisconnected", () => {
      publisher?.destroy();
    });

    session.connect(token, (err: any) => {
      if (err) {
        handleError(err);
      } else {
        // publish publisher
        // This code publishes the audio-video stream to the session using your webcam and microphone.
        session.publish(publisher);
      }
    });
  };

  const endCall = () => {
    session.disconnect();
    setCallEnded(true);
    // window.close();
    // navigate('/auth/chat')
  };

  useEffect(() => {
    initSession();
  }, [session]);

  return callEnded || callError ? (
    <div className={styles.callClosed}>
      <div className={styles.infoCard}>
        <div className={callError ? styles.errorIcon : styles.icon}>
          {callError ? <ExclamationCircleIcon /> : <CheckBadgeIcon />}
        </div>
        <div className={styles.infoText}>
          {callError ? callError : "La seduta è terminata!"}
        </div>
        <div className={styles.infoText}>
          <span>Hai chiuso per sbaglio?</span>{" "}
          <span
            className={styles.infoLink}
            onClick={() => {
              window.location.reload();
            }}
          >
            Riprendi la seduta
          </span>
        </div>
        <div className={styles.wrapperBtn}>
          {callError && (
            <Button onClick={() => window.location.reload()}>
              Torna a pertecipare
            </Button>
          )}
          <Button
            onClick={() => window.open(routes.Chat.path, "_self")}
            variant={callError ? "secondary" : "primary"}
          >
            Vai alla chat
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.videosWrapper}>
      {settingModalIsOpen && (
        <Modal close={() => {
          setSettingModalIsOpen(false);
        }}>
          <GenericForm
            isModal
            className={styles.settingModal}
            title={
              <FormHeader
                closeModal={() => setSettingModalIsOpen(false)}
                title="Impostazioni"
                closeButton
              />
            }
            description="Di solito il nostro sistema riconosce automaticamente gli asset di default, ma se vuoi, puoi modificarli!"
            cta={[
              <div className={styles.selectInput}>
                <span>Videocamera</span>
                <select
                  value={optionPublisher.videoSource as string}
                  className={styles.videoCallSelect}
                  onChange={(e) => {
                    e.preventDefault();
                    // OT.setAudioOutputDevice(e.target.value);
                    setOptionPublisher({
                      ...optionPublisher,
                      videoSource: e.target.value,
                    });
                    publisher?.setVideoSource(e.target.value);
                  }}
                >
                  {inputVideoDeviceList?.map((i, index) => (
                    <option key={index} value={i.deviceId!}>
                      {i.label}
                    </option>
                  ))}
                </select>
              </div>,
              <div className={styles.selectInput}>
                <span>Microfono</span>
                <select
                  value={optionPublisher.audioSource as string}
                  className={styles.videoCallSelect}
                  onChange={(e) => {
                    e.preventDefault();
                    // OT.setAudioOutputDevice(e.target.value);
                    setOptionPublisher({
                      ...optionPublisher,
                      audioSource: e.target.value,
                    });
                    publisher?.setAudioSource(e.target.value);
                  }}
                >
                  {inputAudioDeviceList?.map((i, index) => (
                    <option key={index} value={i.deviceId!}>
                      {i.label}
                    </option>
                  ))}
                </select>
              </div>,
              <div className={styles.selectInput}>
                <span>Altoparlante</span>
                <select
                  value={publisherAudioSource}
                  className={styles.videoCallSelect}
                  onChange={(e) => {
                    e.preventDefault();
                    OT.setAudioOutputDevice(e.target.value);
                    setpublisherAudioSource(e.target.value);
                  }}
                >
                  {outputAudioDeviceList?.map((i, index) => (
                    <option key={index} value={i.deviceId!}>
                      {i.label}
                    </option>
                  ))}
                </select>
              </div>,
            ]}
          />
        </Modal>
      )}
      <div className={styles.videos} id="videos">
        <div
          className={`${
            streams.length > 1 ? styles.coupleSubscriber : styles.subscriber
          }`}
          id="subscriber"
        ></div>
        <div
          className={`${
            streams.length > 1 ? styles.couplePublisher : styles.publisher
          }`}
          id="publisher"
        ></div>
      </div>
      <div className={styles.btnWrapper}>
        <div
          className={`${styles.controlBtn} ${
            !showVideo ? styles.offButton : ""
          }`}
          onClick={() => {
            publisher?.publishVideo(!showVideo);
            setShowVideo(!showVideo);
          }}
        >
          {showVideo ? <VideoCameraIcon /> : <VideoCameraSlashIcon />}
        </div>
        <div
          className={`${styles.controlBtn} ${
            !activeAudio ? styles.offButton : ""
          }`}
          onClick={() => {
            publisher?.publishAudio(!activeAudio);
            setActiveAudio(!activeAudio);
          }}
        >
          {activeAudio ? (
            <MicrophoneIcon />
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="currentColor"
              strokeWidth={1.5}
              view-box="0 0 24 24"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 18.75C13.5913 18.75 15.1174 18.1179 16.2426 16.9926C17.3679 15.8674 18 14.3413 18 12.75V11.25M12 18.75C10.4087 18.75 8.88258 18.1179 7.75736 16.9926C6.63214 15.8674 6 14.3413 6 12.75V11.25M12 18.75V22.5M8.25 22.5H15.75M12 15.75C11.2044 15.75 10.4413 15.4339 9.87868 14.8713C9.31607 14.3087 9 13.5456 9 12.75V4.5C9 3.70435 9.31607 2.94129 9.87868 2.37868C10.4413 1.81607 11.2044 1.5 12 1.5C12.7956 1.5 13.5587 1.81607 14.1213 2.37868C14.6839 2.94129 15 3.70435 15 4.5V12.75C15 13.5456 14.6839 14.3087 14.1213 14.8713C13.5587 15.4339 12.7956 15.75 12 15.75Z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19 19L17.591 17.591L5.409 5.409L4 4"
                strokeWidth={3.5}
                color="#CB4539"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19 19L17.591 17.591L5.409 5.409L4 4"
              />
            </svg>
          )}
        </div>
        {OT.hasMediaProcessorSupport() && (
          <div
            onClick={() => {
              if (publisher?.getVideoFilter() === null) {
                publisher.applyVideoFilter({
                  blurStrength: "high",
                  type: "backgroundBlur",
                });
                setActiveVideoFilter({
                  blurStrength: "high",
                  type: "backgroundBlur",
                });
              } else {
                publisher?.clearVideoFilter();
                setActiveVideoFilter(undefined);
              }
            }}
            className={`${styles.controlBtn} ${
              !activeVideoFilter ? styles.offButton : ""
            }`}
          >
            <ViewfinderCircleIcon />
          </div>
          // <select
          //   onChange={(e) => {
          //     e.preventDefault();
          //     if (e.target.value !== "undefined") {
          //       const value = JSON.parse(e.target.value);
          //       publisher?.applyVideoFilter(value);
          //     } else {
          //       publisher?.clearVideoFilter();
          //     }
          //   }}
          // >
          //   <option value={"undefined"}>Disattiva filtro</option>
          //   <option
          //     value={JSON.stringify({
          //       type: "backgroundBlur",
          //       blurStrength: "high",
          //     })}
          //   >
          //     Attiva filtro
          //   </option>
          //   <option
          //     value={JSON.stringify({
          //       type: "backgroundReplacement",
          //       backgroundImgUrl: logo,
          //     })}
          //   >
          //     Applica immagine di sfondo
          //   </option>
          // </select>
        )}
        <div
          className={styles.controlBtn}
          onClick={() => {
            setSettingModalIsOpen(true);
          }}
        >
          <Cog6ToothIcon />
        </div>
        <div className={styles.endCallButton} onClick={endCall}>
          <PhoneIcon />
        </div>
      </div>
    </div>
  );
};
