import styles from "./Modal.module.scss";

export const Modal = ({children, className, modal, subClassName, close}: any) => {
  return (
    <div className={`${styles.modal} ${className}`} onClick={() => {
      close && close();
    }}>
      {modal ? (
        <div className={`${styles.modalContent} ${subClassName}`} onClick={e => {
          close && e.stopPropagation()
        }}>
          {children}
        </div>
      ) : (
        children
      )}
    </div>
  );
};
