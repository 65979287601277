export const provinces = [
  {name: 'Selezionare un\'opzione', value: ''},
  {name: 'Agrigento', value: 'Agrigento'},
  {name: 'Alessandria', value: 'Alessandria'},
  {name: 'Ancona', value: 'Ancona'},
  {name: 'Aosta', value: 'Aosta'},
  {name: 'Arezzo', value: 'Arezzo'},
  {name: 'Ascoli Piceno', value: 'Ascoli Piceno'},
  {name: 'Asti', value: 'Asti'},
  {name: 'Avellino', value: 'Avellino'},
  {name: 'Bari', value: 'Bari'},
  {name: 'Barletta-Andria-Trani', value: 'Barletta-Andria-Trani'},
  {name: 'Belluno', value: 'Belluno'},
  {name: 'Benevento', value: 'Benevento'},
  {name: 'Bergamo', value: 'Bergamo'},
  {name: 'Biella', value: 'Biella'},
  {name: 'Bologna', value: 'Bologna'},
  {name: 'Bolzano', value: 'Bolzano'},
  {name: 'Brescia', value: 'Brescia'},
  {name: 'Brindisi', value: 'Brindisi'},
  {name: 'Cagliari', value: 'Cagliari'},
  {name: 'Caltanissetta', value: 'Caltanissetta'},
  {name: 'Campobasso', value: 'Campobasso'},
  {name: 'Caserta', value: 'Caserta'},
  {name: 'Catania', value: 'Catania'},
  {name: 'Catanzaro', value: 'Catanzaro'},
  {name: 'Chieti', value: 'Chieti'},
  {name: 'Como', value: 'Como'},
  {name: 'Cosenza', value: 'Cosenza'},
  {name: 'Cremona', value: 'Cremona'},
  {name: 'Crotone', value: 'Crotone'},
  {name: 'Cuneo', value: 'Cuneo'},
  {name: 'Enna', value: 'Enna'},
  {name: 'Fermo', value: 'Fermo'},
  {name: 'Ferrara', value: 'Ferrara'},
  {name: 'Firenze', value: 'Firenze'},
  {name: 'Foggia', value: 'Foggia'},
  {name: 'Forlì-Cesena', value: 'Forlì-Cesena'},
  {name: 'Frosinone', value: 'Frosinone'},
  {name: 'Genova', value: 'Genova'},
  {name: 'Gorizia', value: 'Gorizia'},
  {name: 'Grosseto', value: 'Grosseto'},
  {name: 'Imperia', value: 'Imperia'},
  {name: 'Isernia', value: 'Isernia'},
  {name: 'L\'Aquila', value: 'L\'Aquila'},
  {name: 'La Spezia', value: 'La Spezia'},
  {name: 'Latina', value: 'Latina'},
  {name: 'Lecce', value: 'Lecce'},
  {name: 'Lecco', value: 'Lecco'},
  {name: 'Livorno', value: 'Livorno'},
  {name: 'Lodi', value: 'Lodi'},
  {name: 'Lucca', value: 'Lucca'},
  {name: 'Macerata', value: 'Macerata'},
  {name: 'Mantova', value: 'Mantova'},
  {name: 'Massa-Carrara', value: 'Massa-Carrara'},
  {name: 'Matera', value: 'Matera'},
  {name: 'Messina', value: 'Messina'},
  {name: 'Milano', value: 'Milano'},
  {name: 'Modena', value: 'Modena'},
  {name: 'Monza e Brianza', value: 'Monza e Brianza'},
  {name: 'Napoli', value: 'Napoli'},
  {name: 'Novara', value: 'Novara'},
  {name: 'Nuoro', value: 'Nuoro'},
  {name: 'Oristano', value: 'Oristano'},
  {name: 'Padova', value: 'Padova'},
  {name: 'Palermo', value: 'Palermo'},
  {name: 'Parma', value: 'Parma'},
  {name: 'Pavia', value: 'Pavia'},
  {name: 'Perugia', value: 'Perugia'},
  {name: 'Pesaro e Urbino', value: 'Pesaro e Urbino'},
  {name: 'Pescara', value: 'Pescara'},
  {name: 'Piacenza', value: 'Piacenza'},
  {name: 'Pisa', value: 'Pisa'},
  {name: 'Pistoia', value: 'Pistoia'},
  {name: 'Pordenone', value: 'Pordenone'},
  {name: 'Potenza', value: 'Potenza'},
  {name: 'Prato', value: 'Prato'},
  {name: 'Ragusa', value: 'Ragusa'},
  {name: 'Ravenna', value: 'Ravenna'},
  {name: 'Reggio Calabria', value: 'Reggio Calabria'},
  {name: 'Reggio Emilia', value: 'Reggio Emilia'},
  {name: 'Rieti', value: 'Rieti'},
  {name: 'Rimini', value: 'Rimini'},
  {name: 'Roma', value: 'Roma'},
  {name: 'Rovigo', value: 'Rovigo'},
  {name: 'Salerno', value: 'Salerno'},
  {name: 'Sassari', value: 'Sassari'},
  {name: 'Savona', value: 'Savona'},
  {name: 'Siena', value: 'Siena'},
  {name: 'Siracusa', value: 'Siracusa'},
  {name: 'Sondrio', value: 'Sondrio'},
  {name: 'Sud Sardegna', value: 'Sud Sardegna'},
  {name: 'Taranto', value: 'Taranto'},
  {name: 'Teramo', value: 'Teramo'},
  {name: 'Terni', value: 'Terni'},
  {name: 'Torino', value: 'Torino'},
  {name: 'Trapani', value: 'Trapani'},
  {name: 'Trento', value: 'Trento'},
  {name: 'Treviso', value: 'Treviso'},
  {name: 'Trieste', value: 'Trieste'},
  {name: 'Udine', value: 'Udine'},
  {name: 'Varese', value: 'Varese'},
  {name: 'Venezia', value: 'Venezia'},
  {name: 'Verbano-Cusio-Ossola', value: 'Verbano-Cusio-Ossola'},
  {name: 'Vercelli', value: 'Vercelli'},
  {name: 'Verona', value: 'Verona'},
  {name: 'Vibo Valentia', value: 'Vibo Valentia'},
  {name: 'Vicenza', value: 'Vicenza'},
  {name: 'Viterbo', value: 'Viterbo'}
]