import Modal from '../../organisms/Modal';
import styles from './TherapistWarningButtonModal.module.scss';
import FormHeader from '../../atoms/FormHeader';

export const TherapistWarningButtonModal = ({setClose}: any) => {

  return (
    <Modal modal close={setClose}>
      <>
        <FormHeader
          title=""
          closeModal={setClose}
          closeButton
        ></FormHeader>
        <div className={styles.warningModalContent}>
          Il cliente ha selezionato un appuntamento che potrà pagare fino a 24 ore prima dell'orario di inizio, se non
          verrà pagato in tempo questo si annullerà automaticamente.
        </div>
      </>
    </Modal>
  );
};
