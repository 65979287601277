import { PlusIcon } from "@heroicons/react/20/solid";
import Button from "../../atoms/Button";
import styles from "./UserPaymentMethod.module.scss";
import { useEffect, useState } from "react";
import Modal from "../Modal";
import AddCardForm from "../../molecules/AddCardForm";
import { trpc } from "utils/trpc";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "utils/stripe";
import SavedCard from "components/molecules/SavedCard";
import FormHeader from "components/atoms/FormHeader";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { analyticsService } from "../../../utils/analyticsService";
import PatientPayment from "../PatientPayment";

export const UserPaymentMethod = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [modalData, setModalData] = useState<{ clientSecret: string }>({
    clientSecret: "",
  });


  const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(false);


  useEffect(() => {
    analyticsService({
      'event': 'virtualPageview',
      'pageUrl': '/dashboard/profilo/pagamenti',
      'pageTitle': 'Patient Payment Methods'
    });
  }, []);

  const listPaymentMethodsResponse =
    trpc.mainService.payments.stripe.listPaymentMethods.useQuery();
  const getCurrentUserInfoResponse =
    trpc.mainService.user.getCurrentUserInfo.useQuery();

  const generateSetupIntent =
    trpc.mainService.payments.stripe.generateSetupIntent.useMutation();


  return (
    <div className={styles.userPaymentMethod}>
      {loadingModalIsOpen && (
        <Modal className="loadingModal">
          <Spin
            indicator={
              <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
            }
          />
        </Modal>
      )}
      <h1 className={styles.title}>Metodo di pagamento</h1>
      <div className={styles.savedCardsContainer}>
        <div className={styles.subtitleContainer}>
          <div>
            <h2 className={styles.subtitle}>Carte salvate</h2>
          </div>
          <Button
            variant="secondary"
            rightIcon={<PlusIcon />}
            onClick={async () => {
              const response = await generateSetupIntent.mutateAsync();
              setModalData({
                clientSecret: response.clientSecret!,
              });
              setIsModalVisible(true);
            }}
          >
            <span>Aggiungi metodo di pagamento</span>
            <span>Aggiungi metodo</span>
          </Button>
        </div>
        <div>
          <p className={styles.text}>
            Qui puoi visualizzare o cancellare le carte che hai salvato. Per
            aggiungere una nuova carta o un nuovo metodo di pagamento clicca su
            “Aggiungi metodo”.
          </p>
          <div className={styles.savedCards}>
            {listPaymentMethodsResponse.data &&
              listPaymentMethodsResponse.data.map(({ id, card }, key) => (
                <SavedCard
                  key={key}
                  id={id}
                  last4={card?.last4!}
                  brand={card?.brand!}
                  showDefaultLabel={true}
                  isDefault={
                    getCurrentUserInfoResponse.data?.user
                      .stripeDefaultPaymentMethodId === id
                  }
                  onChange={() => {
                    listPaymentMethodsResponse.refetch();
                    getCurrentUserInfoResponse.refetch();
                  }}
                />
              ))}
          </div>
        </div>
      </div>
      <PatientPayment
        isModal={false}
        setLoadingModalIsOpen={setLoadingModalIsOpen}
        userInfo={getCurrentUserInfoResponse?.data?.user}
        clientSecret=""
        paymentIntentId=""
      />
      {isModalVisible && (
        <Modal modal close={() => {
          setIsModalVisible(false)
        }}>
          <FormHeader
            title="Aggiungi il metodo di pagamento"
            closeModal={() => setIsModalVisible(false)}
            closeButton
          />
          <Elements
            stripe={stripePromise}
            options={{
              locale: 'it-IT',
              clientSecret: modalData.clientSecret,
              appearance: {
                rules: {
                  '.Input': {
                    border: '1px solid #dfeaff',
                  },
                },
              },
            }}
          >
            <AddCardForm
              clientSecret={modalData.clientSecret}
              onSuccess={async () => {
                await listPaymentMethodsResponse.refetch();
                setIsModalVisible(false);
              }}
            />
          </Elements>
        </Modal>
      )}
    </div>
  );
};
