import Modal from "components/organisms/Modal";
import Faq from "pages/Faq";
import PatientAppointments from "pages/PatientAppointments";
import UserProfile from "pages/UserProfile";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useRouteLoaderData } from "react-router-dom";
import { Socket } from "socket.io-client";
import MobileHeader from "../../components/molecules/MobileHeader";
import ChatRoom from "../../components/organisms/ChatRoom";
import DocSheet from "../../components/organisms/DocSheet";
import { useAppDispatch } from "../../customHooks/reduxHooks";
import { setInfo } from "../../store/slices/infoSlice";
import { getToken } from "../../utils/getToken";
import { trpc } from "../../utils/trpc";
import styles from "./Chat.module.scss";
import routes from "routes";
import Button from "components/atoms/Button";
import FormHeader from "components/atoms/FormHeader";
import { analyticsService } from "../../utils/analyticsService";
import Emitter from "../../utils/eventemitter";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { raiseException } from "../../utils/raiseException";
import MedicalDocuments from "../../components/organisms/MedicalDocuments";

export interface Message {
  chatId: string;
  id: number;
  type: string;
  from: string;
  when: string;
  content: string | null;
  appointmentId: string | null;
  appointment: any;
}

export interface Conversation {
  cursor: number | null;
  messages: Message[];
}

export const Chat = ({ className }: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { state } = location;
  const socket = useRouteLoaderData("root") as Socket;

  const [isPatient, setIsPatient] = useState(false);
  const [chatId, setChatId] = useState<string>("");
  const [user, setUser] = useState<any>(state?.user);
  const [userInfoToggle, setUserInfoToggle] = useState(false);
  const [myTherapist, setMyTherapist] = useState<any>();
  const [myTherapistToggle, setMyTherapistToggle] = useState(false);
  const [docSheetIsOpen, setDocSheetIsOpen] = useState(false);
  const [onboardingTherapist, setOnboardingTherapist] = useState(false);
  const [showStripeConnectWarning, setShowStripeConnectWarning] = useState(state?.showStripeConnectWarning);
  const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(false);
  const [showDocuments, setShowDocuments] = useState(false);
  const [showSurveyAnswers, setShowSurveyAnswers] = useState(false);

  const getMyTherapist = trpc.mainService.patient.getMyTherapist.useQuery(
    undefined,
    { enabled: myTherapistToggle }
  );

  const getUserInfo = trpc.mainService.user.getCurrentUserInfo.useQuery();

  useEffect(() => {
    analyticsService({'event': 'virtualPageview', 'pageUrl': '/dashboard/chat', 'pageTitle': 'Chat'});
  }, []);

  const { data: chat, refetch: refetchChats } =
    trpc.mainService.chat.getChats.useQuery();

  useEffect(() => {
    refetchChats().then((result) => {
      const currentChatId = result.data?.chats[0]?.id;
      if (currentChatId) {
        setChatId(currentChatId);
        socket.emit("joinChat", currentChatId);
      }
    });
  }, []);

  useEffect(() => {
    if (user) {
      const { role } = user;
      if (role === "patient") {
        setIsPatient(true);
        setMyTherapistToggle(true);
      }
      if (role === "therapist") {
        if (user.onboarding || state?.onboarding) {
          setOnboardingTherapist(true);
        }
        setIsPatient(false);
        setMyTherapist(user);
      }
    }
  }, [user, state]);

  useEffect(() => {
    if (myTherapistToggle) {
      if (getMyTherapist.status === "success") {
        setMyTherapistToggle(false);
        const { data } = getMyTherapist!;
        setMyTherapist(data.therapist);
      }
      if (getMyTherapist.status === "error") {
        setMyTherapistToggle(false);
        dispatch(
          setInfo({
            text: getMyTherapist.error.message,
            variant: "error",
          })
        );
        setMyTherapistToggle(false);
      }
    }
  }, [getMyTherapist, myTherapistToggle]);

  useEffect(() => {
    if (userInfoToggle) {
      if (getUserInfo.status === "success") {
        setUserInfoToggle(false);
        setUser(getUserInfo.data.user);
      }
      if (getUserInfo.status === "error") {
        setUserInfoToggle(false);
        dispatch(setInfo({ text: getUserInfo.error.message }));
      }
    }
  }, [getUserInfo, userInfoToggle]);

  useEffect(() => {
    if (chat) {
      if (chat.chats.length > 0) {
        setChatId(chat.chats[0].id);
      } else if (chat.chats.length === 0) {
        refetchChats().then((result) => {
          if (result.data?.chats.length! > 0) {
            setChatId(chat.chats[0].id);
          } else {
            setChatId("");
          }
        });
      }
    }
  }, [chat]);

  useEffect(() => {
    const accessToken = getToken("accessToken");
    if (accessToken) {
      if (!user) {
        setUserInfoToggle(true);
      }
      if (user?.role === "patient") {
        setMyTherapistToggle(true);
      }
    } else {
      dispatch(
        setInfo({
          title: "Utente non loggato",
          text: "Eseguire il login",
          variant: "error",
        })
      );
      navigate(routes.Home.path);
    }
  }, [user]);

  useEffect(() => {
    Emitter.on("updateTherapistChange", updateTherapistInfo);
    Emitter.on("updatePatientInfo", updatePatientInfo);
  }, []);

  const updateTherapistInfo = async () => {
    try {
      setLoadingModalIsOpen(true)
      const myTherapist: any = await getMyTherapist.refetch();
      const patientInfo: any = await getUserInfo.refetch();
      setMyTherapist(myTherapist?.data?.therapist)
      setUser(patientInfo.data?.user);
    } catch (error: any) {
      raiseException(error);
      console.log('Error:', error)
    } finally {
      setLoadingModalIsOpen(false)
    }
  }

  const updatePatientInfo = async () => {
    try {
      setLoadingModalIsOpen(true)
      const patientInfo: any = await getUserInfo.refetch();
      setUser(patientInfo.data?.user);
    } catch (error: any) {
      raiseException(error);
      console.log('Error:', error)
    } finally {
      setLoadingModalIsOpen(false)
    }
  }

  const renderLoadingModal = () => (
    <Modal>
      <Spin indicator={<LoadingOutlined style={{fontSize: 32}} spin/>}/>
    </Modal>
  );

  const renderStripeWarningModal = () => (
    <Modal modal close={() => {
      setShowStripeConnectWarning(false);
      navigate(routes.Chat.path, {});
    }}>
        <FormHeader
          title="Attenzione"
          closeModal={() => {
            setShowStripeConnectWarning(false);
            navigate(routes.Chat.path, {});
          }}
          closeButton
        />
        <p className={styles.connectDescription}>
          Per poter ricevere pazienti, devi completare la configurazione
          dell'account Stripe Connect attraverso la sezione Pagamenti.
        </p>
        <Button
          onClick={() => {
            setShowStripeConnectWarning(false);
            navigate(routes.ProfileSection.links.payment);
          }}
          className={styles.stripeWarningButton}
        >
          Vai alla sezione pagamenti
        </Button>
    </Modal>
  );

  const renderOnboardingModal = () => (
    <Modal>
      <div className={styles.therapistOnboarding}>
        <video width="612" controls>
          <source src="mov_bbb.mp4" type="video/mp4"/>
          <source src="mov_bbb.ogg" type="video/ogg"/>
          Your browser does not support HTML video.
        </video>
        <div className={styles.bottomPart}>
          <h5 className={styles.title}>Video tutorial Terapeuta</h5>
          <div className={styles.description}>
            In questo breve video ti mostreremo come utilizzare al meglio la
            piattaforma.
          </div>
          <div className={styles.description}>
            Puoi anche rivederlo in un secondo momento nella tua sezione
          </div>
        </div>
        <Button onClick={() => setOnboardingTherapist(false)}>
          Entra nella piattaforma
        </Button>
      </div>
    </Modal>
  );
  const renderChatContent = () => {
    if (!user) return renderLoadingModal();

    if ((user.role === 'therapist' || (user.role === 'patient' && chat?.chats[0]?.id)) && myTherapist) {
      const realChatId = user.role === 'therapist' ? '' : chat?.chats[0].id as string;

      return (
        <ChatRoom
          docSheetIsOpen={docSheetIsOpen}
          className={styles.chatWrapper}
          chatId={realChatId}
          myTherapist={myTherapist}
          user={user}
          showDocuments={showDocuments}
          setShowDocuments={setShowDocuments}
          showSurveyAnswers={showSurveyAnswers}
          setShowSurveyAnswers={setShowSurveyAnswers}
        />
      );
    }

    return renderLoadingModal();
  };

  const isChatPath = location.pathname === routes.Chat.path && !loadingModalIsOpen;
  const isAppointmentsPath = location.pathname === routes.Appointments.path && getUserInfo.status === 'success';
  const isProfilePath = location.pathname === routes.Profile.path || location.pathname.includes(routes.Profile.path);
  const isClinicalPapersPath = location.pathname === routes.ClinicalPapers.path && myTherapist && user;
  const isDashboardFaqPath = location.pathname.includes(routes.DashboardFaq.path);


  return (
    <div className={`${styles.chatPage} ${className}`}>
      {loadingModalIsOpen && renderLoadingModal()}
      {showStripeConnectWarning && renderStripeWarningModal()}
      {onboardingTherapist && renderOnboardingModal()}
      {myTherapist?.id && !loadingModalIsOpen && (
        <MobileHeader
          chatWith={user?.role === 'patient' ? `${myTherapist?.given_name} ${myTherapist?.family_name}` : ''}
          openProfile={setDocSheetIsOpen}
          profileIsOpen={docSheetIsOpen}
          profileDataId={myTherapist?.id}
          showDocuments={showDocuments}
          setShowDocuments={setShowDocuments}
          showSurveyAnswers={showSurveyAnswers}
          setShowSurveyAnswers={setShowSurveyAnswers}
          getUserInfo={getUserInfo}
        />
      )}
      {isChatPath
        ? renderChatContent()
        : isAppointmentsPath
          ? <PatientAppointments userInfo={getUserInfo?.data?.user}/>
          : isProfilePath
            ? <UserProfile/>
            : isClinicalPapersPath
              ? (
                <div className={styles.medicalDocumentsContainer}>
                  <div className={styles.name}>
                    {`${myTherapist?.given_name} ${myTherapist?.family_name}`}
                  </div>
                  <MedicalDocuments
                    myTherapist={myTherapist}
                    patient={user}
                    chatId={chatId}
                  />
                </div>
              )
              : isDashboardFaqPath
                ? <Faq/>
                : ''
      }
      {isPatient && myTherapist && location.pathname === routes.Chat.path && !loadingModalIsOpen && (
        <DocSheet
          patientData={user}
          doc={myTherapist}
          className={`${styles.chatDocSheet} ${docSheetIsOpen && styles.open}`}
        />
      )}
    </div>
  );
};
