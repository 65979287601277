import { useEffect, useRef, useState } from 'react';
import Button from '../../components/atoms/Button';
import Input from '../../components/atoms/Input';
import GenericForm from '../../components/molecules/GenericForm';
import { useAppDispatch } from '../../customHooks/reduxHooks';
import { setInfo } from '../../store/slices/infoSlice';
import { trpc } from '../../utils/trpc';
import styles from './PsychotherapistsRegister.module.scss';
import Multiselect from 'multiselect-react-dropdown';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { getCPS } from 'utils/general';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { internationalPrefix } from 'utils/international_prefix';
import { analyticsService } from '../../utils/analyticsService';
import { raiseException } from '../../utils/raiseException';
import trimString from '../../utils/trimString';
import { provinces } from '../../utils/provinces';

export const PsychotherapistsRegister = ({ className = "" }) => {
  const dispatch = useAppDispatch();

  const [therapistTitle, setTherapistTitle] = useState("");
  const [therapistName, setTherapistName] = useState("");
  const [therapistSurname, setTherapistSurname] = useState("");
  const [therapistMail, setTherapistMail] = useState("");
  const [therapistPhonePrefix, setTherapistPhonePrefix] = useState("+39");
  const [therapistPhoneNumber, setTherapistPhoneNumber] = useState("");
  const [graduateIn, setGraduateIn] = useState("");
  const [graduateAt, setGraduateAt] = useState("");
  const [phoneNumber, setPhoneNumber] = useState<any>("");
  const [gender, setGender] = useState<"male" | "female" | any>("");
  const [therapistSpec, setTherapistSpec] = useState<any[]>([]);
  const [formPage, setFormPage] = useState(1);
  const [signature, setSignatureUpload] = useState<boolean>(false)

  const uploadFileRef = useRef<HTMLInputElement>(null);
  const uploadSignatureFileRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLDivElement>(null);
  const firstPageSubmitRef = useRef<HTMLButtonElement>(null);
  const secondPageSubmitRef = useRef<HTMLButtonElement>(null);
  const thirdPageSubmitRef = useRef<HTMLButtonElement>(null);

  const therapistTherapyType: any[] = ["psychiatry"]
  const totalPage = 3

  const preRegisterTherapist =
    trpc.authService.auth.preRegisterTherapist.useMutation();

  useEffect(() => {
    analyticsService({
      'event': 'virtualPageview',
      'pageUrl': '/registrazione-psicoterapeuta/asdAQW_ASd_456ASD',
      'pageTitle': 'Psychotherapists Register'
    });
  }, []);

  const formikFirstPage = useFormik({
    initialValues: {
      givenName: "",
      familyName: "",
      gender: "",
      email: "",
      phoneNumber: "",
    },
    validationSchema: yup.object().shape({
      givenName: yup.string().required("Il nome è un campo obbligatorio"),
      familyName: yup.string().required("Il cognome è un campo obbligatorio"),
      gender: yup.string().required("Il genere è un campo obbligatorio"),
      email: yup
        .string()
        .trim()
        .matches(
          /^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i,
          "L'email inserita ha un formato non valido"
        )
        .required("L'email è un campo obbligatorio"),
      phoneNumber: yup
        .string()
        .matches(
          /^\+\d{7,18}$/,
          "Il numero di telefono inserito non ha un formato non valido"
        )
        .required("Il numero di telefono è un campo obbligatorio"),
    }),
    onSubmit: async (values) => {
      try {
        await preRegisterTherapist.mutateAsync({
          email: values.email,
        });
        setTherapistMail(values.email);
        setTherapistName(values.givenName);
        setTherapistSurname(values.familyName);
        setGender(values.gender);
        setPhoneNumber(values.phoneNumber);
        setFormPage(formPage + 1);
        formRef.current?.scrollIntoView();
      } catch (e: any) {
        raiseException(e);
        if (e?.shape?.errorCode === "EmailAlreadyExists") {
          formikFirstPage.setErrors({
            ...formikFirstPage.errors,
            email: "L'email inserita è già in uso",
          });
        } else {
          dispatch(
            setInfo({
              variant: "error",
              text: "Errore nel servizio di registrazione",
            })
          );
        }
      }
    },
  });

  const formikSecondPage = useFormik({
    initialValues: {
      therapistTitle: "",
      graduateIn: "",
      graduateAt: "",
      therapistSpecializations: [] as string[],
    },
    validationSchema: yup.object().shape({
      therapistTitle: yup
        .string()
        .required("Il livello di formazione è un campo obbligatorio"),
      graduateIn: yup.string().required("Specifica in cosa ti sei laureato"),
      graduateAt: yup
        .string()
        .required("Specifica in quale università ti sei laureato"),
      therapistSpecializations: yup
        .array()
        .min(1, "Devi selezionare almeno una tipologia di percorso"),
    }),
    onSubmit: (values) => {
      setTherapistTitle(values.therapistTitle);
      setGraduateIn(values.graduateIn);
      setGraduateAt(values.graduateAt);
      setTherapistSpec(values.therapistSpecializations);
      setFormPage(formPage + 1);
      formRef.current?.scrollIntoView();
    },
  });

  const formikThirdPage = useFormik({
    initialValues: {
      uploadedFile: false,
      therapistRegisterPlace: "",
      therapistRegisterNumber: "",
      licenceRegisterWith: "",
      licenceRegisterNumber: "",
      therapistVatType: "",
      therapistDaySlot: [] as string[],
      therapistTimeSlot: [] as string[],
    },
    validationSchema: yup.object().shape({
      uploadedFile: yup
        .boolean()
        .isTrue("Il caricamento della foto è obbligatorio"),
      therapistRegisterPlace: yup
        .string()
        .required("L'albo di iscrizione è un campo obbligatorio"),
      therapistRegisterNumber: yup
        .string()
        .required("Il numero di iscrizione all'albo è un campo obbligatorio"),
      licenceRegisterWith: yup
        .string()
        .required(
          "Devi inserire con chi hai sottoscritto la Polizza RC professionale"
        ),
      licenceRegisterNumber: yup
        .string()
        .required("Il numero di Polizza RC è un campo obbligatorio"),
      therapistVatType: yup
        .string()
        .required("Il tipo di partita iva è un campo obbligatorio"),
      therapistDaySlot: yup
        .array()
        .min(1, "Devi selezionare almeno un opzione"),
      therapistTimeSlot: yup
        .array()
        .min(1, "Devi selezionare almeno un opzione"),
    }),
    onSubmit: (values) => {
      therapistRegistraion
        .mutateAsync({
          email: trimString(therapistMail),
          given_name: trimString(therapistName),
          family_name: trimString(therapistSurname),
          gender: gender,
          phone_number: trimString(phoneNumber),
          specializations: therapistSpec,
          title: therapistTitle,
          therapyType: therapistTherapyType,
          daySlots: values.therapistDaySlot as (
            | "week"
            | "saturday"
            | "sunday"
            )[],
          timeSlots: values.therapistTimeSlot as (
            | "morning"
            | "afternoon"
            | "late_afternoon"
            | "evening"
            )[],
          minWeeklyHoursAvailability: 6,
          maxWeeklyHoursAvailability: 12,
          videoUrl: undefined,
          licenceRegisterNumber: trimString(values.licenceRegisterNumber),
          licenceRegisterPlace: `${values.therapistRegisterPlace} al n°${trimString(values.therapistRegisterNumber)}`,
          licenceRegisterWith: trimString(values.licenceRegisterWith),
          vatNumberType: values.therapistVatType as "ordinaria" | "forfettaria",
          graduationCourse: trimString(graduateIn),
          graduationPlace: trimString(graduateAt),
          signature
        })
        .then(() => {
          setFormPage(formPage + 1);
          formRef.current?.scrollIntoView();
        })
        .catch((e) => {
          raiseException(e);
          if (e?.shape?.errorCode === "EmailAlreadyExists") {
            formikFirstPage.setErrors({
              ...formikFirstPage.errors,
              email: "L'email inserita è già in uso",
            });
            dispatch(
              setInfo({
                variant: "error",
                text: "Errore nel servizio di registrazione: l'email inserita è già in uso. Tornare indietro e cambiarla.",
              })
            );
          } else {
            dispatch(
              setInfo({
                variant: "error",
                text: "Errore nel servizio di registrazione",
              })
            );
          }
        });
    },
  });

  const specializationResponse =
    trpc.mainService.therapist.getAllSpecializations.useQuery();

  const therapistRegistraion =
    trpc.authService.auth.registerTherapist.useMutation();

  const sendForm = async () => {
    if (formPage === 1) {
      firstPageSubmitRef.current?.click();
    } else if (formPage === 2) {
      const result = await formikSecondPage.validateForm();
      if (Object.keys(result).length !== 0) {
        formRef.current?.scrollIntoView();
      }
      secondPageSubmitRef.current?.click();
    } else if (formPage === 3) {
      const result = await formikThirdPage.validateForm();
      if (Object.keys(result).length !== 0) {
        formRef.current?.scrollIntoView();
      }
      thirdPageSubmitRef.current?.click();
    }
  };

  const generateUploadPicUrl =
    trpc.mainService.therapist.generateUploadPicUrl.useMutation();

  const pag1 = [
    <form className={styles.formPage} onSubmit={formikFirstPage.handleSubmit}>
      <Input
        label="1. Inserisci il tuo nome"
        placeholder="Nome"
        name="givenName"
        value={formikFirstPage.values.givenName}
        onChange={formikFirstPage.handleChange}
        error={
          (formikFirstPage.errors.givenName &&
            formikFirstPage.touched.givenName &&
            formikFirstPage.errors.givenName) as string
        }
      />
      <Input
        label="2. Inserisci il tuo cognome"
        placeholder="Cognome"
        name="familyName"
        value={formikFirstPage.values.familyName}
        onChange={formikFirstPage.handleChange}
        error={
          (formikFirstPage.errors.familyName &&
            formikFirstPage.touched.familyName &&
            formikFirstPage.errors.familyName) as string
        }
      />
      <Input
        label="3. Inserisci il tuo genere"
        list="gender"
        select
        options={[
          { name: "Selezionare un'opzione", value: "" },
          { name: "Uomo", value: "male" },
          { name: "Donna", value: "female" },
        ]}
        name="gender"
        value={formikFirstPage.values.gender}
        onChange={formikFirstPage.handleChange}
        error={
          (formikFirstPage.errors.gender &&
            formikFirstPage.touched.gender &&
            formikFirstPage.errors.gender) as string
        }
      />
      <Input
        label="4. Inserisci la tua email"
        sublabel="Sarà utilizzata solo per uso interno e non sarà resa pubblica."
        placeholder="Email"
        type="email"
        name="email"
        value={formikFirstPage.values.email}
        onChange={formikFirstPage.handleChange}
        error={
          (formikFirstPage.errors.email &&
            formikFirstPage.touched.email &&
            formikFirstPage.errors.email) as string
        }
      />
      <div className={styles.phoneInputWrapper}>
        <div className={styles.label}>5. Inserisci il tuo numero di telefono</div>
        <div className={styles.subLabel}>Sarà utilizzato solo per uso interno e non sarà resa pubblica.
        </div>
        <div className={styles.phoneInput}>
          <Input
            select
            options={internationalPrefix.map((p) => {
              return {name: p.prefix, value: p.prefix};
            })}
            placeholder="+39"
            value={therapistPhonePrefix}
            onChange={(e) => setTherapistPhonePrefix(e.target.value)}
            error={
              (formikFirstPage.errors.phoneNumber &&
                formikFirstPage.touched.phoneNumber &&
                " ") as string
            }
          />
          <Input
            placeholder="3471234567"
            name="phoneNumber"
            value={therapistPhoneNumber}
            onChange={(e) => setTherapistPhoneNumber(e.target.value)}
            error={
              (formikFirstPage.errors.phoneNumber &&
                formikFirstPage.touched.phoneNumber &&
                " ") as string
            }
          />
        </div>
      </div>
      <div className={styles.errorLabel}>
        {formikFirstPage.errors.phoneNumber &&
          formikFirstPage.touched.phoneNumber &&
          (formikFirstPage.errors.phoneNumber as string)}
      </div>
      <button
        ref={firstPageSubmitRef}
        className={styles.hiddenButton}
        type="submit"
      ></button>
    </form>,
  ];

  const pag2 = [
    <form className={styles.formPage} onSubmit={formikSecondPage.handleSubmit}>
      <Input
        label="6. Qual è la tua formazione?"
        placeholder="Formazione"
        select
        options={[
          { name: "Selezionare un'opzione", value: "" },
          { name: "Medico Psichiatra", value: "Psichiatra" },
        ]}
        name="therapistTitle"
        value={formikSecondPage.values.therapistTitle}
        onChange={(e) => {
          formikSecondPage.setValues({
            ...formikSecondPage.values,
            therapistTitle: e.target.value,
          });
        }}
        error={
          (formikSecondPage.errors.therapistTitle &&
            formikSecondPage.touched.therapistTitle &&
            formikSecondPage.errors.therapistTitle) as string
        }
      />
      <Input
        placeholder="Università degli studi di Torino"
        label={`7. In quale università hai conseguito la Laurea in Medicina?`}
        name="graduateIn"
        value={formikSecondPage.values.graduateIn}
        onChange={formikSecondPage.handleChange}
        error={
          (formikSecondPage.errors.graduateIn &&
            formikSecondPage.touched.graduateIn &&
            formikSecondPage.errors.graduateIn) as string
        }
      />
      <Input
        label={`8. In quale università hai conseguito la Specializzazione in Psichiatria?`}
        placeholder="Università degli studi di Torino"
        name="graduateAt"
        value={formikSecondPage.values.graduateAt}
        onChange={formikSecondPage.handleChange}
        error={
          (formikSecondPage.errors.graduateAt &&
            formikSecondPage.touched.graduateAt &&
            formikSecondPage.errors.graduateAt) as string
        }
      />
      <div className={styles.answrWrapper}>
        <div
          className={styles.label}
        >
          9. Quali sono le aree di intervento in cui sei specializzato:
          <div className={styles.note}>
            (puoi selezionare più di un’opzione)
          </div>
        </div>
        {formikSecondPage.errors.therapistSpecializations &&
          formikSecondPage.touched.therapistSpecializations &&
          formikSecondPage.errors.therapistSpecializations && (
            <span className={styles.multiSelectErrorLabel}>
              {formikSecondPage.errors.therapistSpecializations}
            </span>
          )}
        {specializationResponse.isSuccess &&
          specializationResponse.data.specializations.filter(specialization => specialization.id.startsWith('p')).map(
            (specialization, index) => (
              <Input
                key={index}
                value={specialization.id}
                type="checkbox"
                checkboxId={specialization.id}
                checkboxLabel={getCPS([specialization.id]).join(", ")}
                checked={Boolean(
                  formikSecondPage.values.therapistSpecializations?.find(
                    (spec) => spec === specialization.id
                  )
                )}
                error={
                  (formikSecondPage.errors.therapistSpecializations &&
                    formikSecondPage.touched.therapistSpecializations &&
                    formikSecondPage.errors.therapistSpecializations &&
                    " ") as string
                }
                onChange={(e: any) => {
                  if (e.target.checked) {
                    formikSecondPage.setValues({
                      ...formikSecondPage.values,
                      therapistSpecializations: [
                        ...formikSecondPage.values.therapistSpecializations,
                        e.target.value,
                      ],
                    });
                  } else {
                    const updatedTherapistSpec =
                      formikSecondPage.values.therapistSpecializations.filter(
                        (s) => s !== specialization.id
                      );
                    formikSecondPage.setValues({
                      ...formikSecondPage.values,
                      therapistSpecializations: updatedTherapistSpec,
                    });
                  }
                }}
              />
            )
          )}
      </div>
      <button
        ref={secondPageSubmitRef}
        type="submit"
        className={styles.hiddenButton}
      ></button>
    </form>,
  ];

  const pag3 = [
    <form className={styles.formPage} onSubmit={formikThirdPage.handleSubmit}>
      <Input
        label="10. Inserisci una tua foto"
        sublabel="La foto deve essere in formato png oppure jpeg"
        type="file"
        accept="image/png, image/jpeg"
        ref={uploadFileRef}
        error={
          (formikThirdPage.errors.uploadedFile &&
            formikThirdPage.touched.uploadedFile &&
            formikThirdPage.errors.uploadedFile) as string
        }
        onChange={async (e) => {
          const file = e.target.files[0];
          try {
            if (
              !file?.name?.toLowerCase()?.endsWith(".png") &&
              !file?.name?.toLowerCase()?.endsWith(".jpg") &&
              !file?.name?.toLowerCase()?.endsWith(".jpeg")
            ) {
              throw new Error("Wrong format");
            }
            const { url } = await generateUploadPicUrl.mutateAsync({
              email: therapistMail,
            });

            await fetch(url, {
              method: "PUT",
              body: file,
            });
            formikThirdPage.setValues({
              ...formikThirdPage.values,
              uploadedFile: true,
            });
          } catch (e: any) {
            raiseException(e);
            if (uploadFileRef) {
              (uploadFileRef?.current as any).value = "";
            }
            if (e?.shape?.errorCode === "EmailAlreadyExists") {
              dispatch(
                setInfo({
                  variant: "error",
                  text: "Si è verificato un errore durante il caricamento della foto perché la mail inserita risulta già utilizzata. Tornare indietro e modificarla.",
                })
              );
            } else if (e?.message === "Wrong format") {
              dispatch(
                setInfo({
                  variant: "error",
                  text: `La foto "${file?.name?.toLowerCase()}" che hai caricato ha il formato sbagliato. Il formato della foto deve essere ".png", ".jpg" oppure ".jpeg".`,
                })
              );
            } else {
              dispatch(
                setInfo({
                  variant: "error",
                  text: "Si è verificato un errore durante il caricamento della foto. Riprovare",
                })
              );
            }
          }
        }}
      />
      <Input
        select
        label="11. Sei iscritto all’Ordine dei Medici e Chirurghi di quale provincia? Indicala insieme al tuo numero di iscrizione"
        placeholder="Iscrizione all'albo della regione..."
        // onChange={(e) => setTherapistRegisterPlace(e.target.value)}
        name="therapistRegisterPlace"
        value={formikThirdPage.values.therapistRegisterPlace}
        onChange={(e) => {
          formikThirdPage.setValues({
            ...formikThirdPage.values,
            therapistRegisterPlace: e.target.value,
          });
        }}
        error={
          (formikThirdPage.errors.therapistRegisterPlace &&
            formikThirdPage.touched.therapistRegisterPlace &&
            formikThirdPage.errors.therapistRegisterPlace) as string
        }
        options={provinces}
      />
      <Input
        placeholder="Numero iscrizione"
        name="therapistRegisterNumber"
        value={formikThirdPage.values.therapistRegisterNumber}
        onChange={formikThirdPage.handleChange}
        error={
          (formikThirdPage.errors.therapistRegisterNumber &&
            formikThirdPage.touched.therapistRegisterNumber &&
            formikThirdPage.errors.therapistRegisterNumber) as string
        }
      />
      <Input
        label="12. Se possiedi una firma digitale inseriscila qui, sarà utilizzata per le ricette elettroniche che invierai ai tuoi pazienti (campo non obbligatorio)"
        sublabel="La foto deve essere in formato png oppure jpeg"
        type="file"
        accept="image/png, image/jpeg"
        ref={uploadSignatureFileRef}
        error={
          (formikThirdPage.errors.uploadedFile &&
            formikThirdPage.touched.uploadedFile &&
            formikThirdPage.errors.uploadedFile) as string
        }
        onChange={async (e) => {
          const file = e.target.files[0];
          try {
            if (
              !file?.name?.toLowerCase()?.endsWith(".png") &&
              !file?.name?.toLowerCase()?.endsWith(".jpg") &&
              !file?.name?.toLowerCase()?.endsWith(".jpeg")
            ) {
              throw new Error("Wrong format");
            }
            const { url} = await generateUploadPicUrl.mutateAsync({
              folder: 'docs',
              email: therapistMail,
            });
            await fetch(url, {
              method: "PUT",
              body: file,
            });
            if (file) {
              setSignatureUpload(true);
            }
            formikThirdPage.setValues({
              ...formikThirdPage.values,
              uploadedFile: true,
            });
          } catch (e: any) {
            raiseException(e);
            if (uploadSignatureFileRef) {
              (uploadSignatureFileRef?.current as any).value = "";
            }
            if (e?.shape?.errorCode === "EmailAlreadyExists") {
              dispatch(
                setInfo({
                  variant: "error",
                  text: "Si è verificato un errore durante il caricamento della foto perché la mail inserita risulta già utilizzata. Tornare indietro e modificarla.",
                })
              );
            } else if (e?.message === "Wrong format") {
              dispatch(
                setInfo({
                  variant: "error",
                  text: `La foto "${file?.name?.toLowerCase()}" che hai caricato ha il formato sbagliato. Il formato della foto deve essere ".png", ".jpg" oppure ".jpeg".`,
                })
              );
            } else {
              dispatch(
                setInfo({
                  variant: "error",
                  text: "Si è verificato un errore durante il caricamento della foto. Riprovare",
                })
              );
            }
          }
        }}
      />
      <Input
        label="13. Inserisci con chi hai sottoscritto la Polizza RC professionale"
        placeholder="Polizza RC"
        name="licenceRegisterWith"
        value={formikThirdPage.values.licenceRegisterWith}
        onChange={formikThirdPage.handleChange}
        error={
          (formikThirdPage.errors.licenceRegisterWith &&
            formikThirdPage.touched.licenceRegisterWith &&
            formikThirdPage.errors.licenceRegisterWith) as string
        }
      />
      <Input
        label="14. Inserisci il numero di Polizza RC professionale"
        placeholder="Numero Polizza RC"
        name="licenceRegisterNumber"
        value={formikThirdPage.values.licenceRegisterNumber}
        onChange={formikThirdPage.handleChange}
        error={
          (formikThirdPage.errors.licenceRegisterNumber &&
            formikThirdPage.touched.licenceRegisterNumber &&
            formikThirdPage.errors.licenceRegisterNumber) as string
        }
      />
      <Input
        label="15. Inserisci il tipo di partita I.V.A"
        name="licenceRegisterNumber"
        value={formikThirdPage.values.therapistVatType}
        onChange={(e) => {
          formikThirdPage.setValues({
            ...formikThirdPage.values,
            therapistVatType: e.target.value,
          });
        }}
        error={
          (formikThirdPage.errors.therapistVatType &&
            formikThirdPage.touched.therapistVatType &&
            formikThirdPage.errors.therapistVatType) as string
        }
        options={[
          { name: "Selezionare un'opzione", value: "" },
          { name: "Ordinaria", value: "ordinaria" },
          { name: "Forfettaria", value: "forfettaria" },
        ]}
        select
        placeholder="Tipologia partita iva"
      />
      <div className={styles.multiselect}>
        <span
          className={
            formikThirdPage.errors.therapistDaySlot &&
            formikThirdPage.touched.therapistDaySlot &&
            formikThirdPage.errors.therapistDaySlot
              ? styles.multiSelectErrorLabel
              : ""
          }
        >
          16. Giorni disponibili
          <div className={styles.note}>
            Potrai aggiornare queste informazioni dalla tua area personale
          </div>
        </span>
        <Multiselect
          className={
            formikThirdPage.errors.therapistDaySlot &&
            formikThirdPage.touched.therapistDaySlot &&
            formikThirdPage.errors.therapistDaySlot
              ? styles.multiSelectError
              : ""
          }
          onSelect={(_, selectedSlot) => {
            formikThirdPage.setValues({
              ...formikThirdPage.values,
              therapistDaySlot: [
                ...formikThirdPage.values.therapistDaySlot,
                selectedSlot.value,
              ],
            });
          }}
          onRemove={(_, deselectedSlot) => {
            const updatedTherapistTherapyType =
              formikThirdPage.values.therapistDaySlot.filter(
                (s) => s !== deselectedSlot.value
              );
            formikThirdPage.setValues({
              ...formikThirdPage.values,
              therapistDaySlot: [...updatedTherapistTherapyType],
            });
          }}
          displayValue="name"
          showCheckbox
          placeholder="Puoi selezionare più di un opzione"
          showArrow
          id='multiSelect'
          customArrow={<ChevronDownIcon />}
          avoidHighlightFirstOption
          selectedValues={formikThirdPage.values.therapistDaySlot.map((d) => {
            if (d === "week") {
              return { name: "Lun - Ven", value: "week" };
            }
            if (d === "saturday") {
              return { name: "Sabato", value: "saturday" };
            }
            if (d === "sunday") {
              return { name: "Domenica", value: "sunday" };
            }
          })}
          options={[
            { name: "Lun - Ven", value: "week" },
            { name: "Sabato", value: "saturday" },
            { name: "Domenica", value: "sunday" },
          ]}
        />
        {formikThirdPage.errors.therapistDaySlot &&
          formikThirdPage.touched.therapistDaySlot &&
          formikThirdPage.errors.therapistDaySlot && (
            <span className={styles.multiSelectErrorLabel}>
              {formikThirdPage.errors.therapistDaySlot}
            </span>
          )}
      </div>
      <div className={styles.multiselect}>
        <span
          className={
            formikThirdPage.errors.therapistTimeSlot &&
            formikThirdPage.touched.therapistTimeSlot &&
            formikThirdPage.errors.therapistTimeSlot
              ? styles.multiSelectErrorLabel
              : ""
          }
        >
          17. Orari disponibili{" "}
          <div className={styles.note}>
            Potrai aggiornare queste informazioni dalla tua area personale
          </div>
        </span>
        <Multiselect
          className={
            formikThirdPage.errors.therapistTimeSlot &&
            formikThirdPage.touched.therapistTimeSlot &&
            formikThirdPage.errors.therapistTimeSlot
              ? styles.multiSelectError
              : ""
          }
          onSelect={(_, selectedSlot) => {
            formikThirdPage.setValues({
              ...formikThirdPage.values,
              therapistTimeSlot: [
                ...formikThirdPage.values.therapistTimeSlot,
                selectedSlot.value,
              ],
            });
          }}
          onRemove={(_, deselectedSlot) => {
            const updatedTherapistTherapyType =
              formikThirdPage.values.therapistTimeSlot.filter(
                (s) => s !== deselectedSlot.value
              );
            formikThirdPage.setValues({
              ...formikThirdPage.values,
              therapistTimeSlot: [...updatedTherapistTherapyType],
            });
          }}
          displayValue="name"
          showCheckbox
          placeholder="Puoi selezionare più di un opzione"
          showArrow
          id='multiSelect'
          customArrow={<ChevronDownIcon/>}
          avoidHighlightFirstOption
          selectedValues={formikThirdPage.values.therapistTimeSlot.map((t) => {
            if (t === "morning") {
              return {name: "Mattina (8:00 - 13:00)", value: "morning"};
            }
            if (t === "afternoon") {
              return {name: "Pomeriggio (13:00 - 18:00)", value: "afternoon"};
            }
            if (t === "late_afternoon") {
              return {name: "Tardo pomeriggio (18:00 - 20:00)", value: "late_afternoon"};
            }
            if (t === "evening") {
              return {name: "Sera (20:00 - 22:00)", value: "evening"};
            }
          })}
          options={[
            {name: "Mattina (8:00 - 13:00)", value: "morning"},
            {name: "Pomeriggio (13:00 - 18:00)", value: "afternoon"},
            {name: "Tardo pomeriggio (18:00 - 20:00)", value: "late_afternoon"},
            {name: "Sera (20:00 - 22:00)", value: "evening"}
          ]}
        />
        {formikThirdPage.errors.therapistTimeSlot &&
          formikThirdPage.touched.therapistTimeSlot &&
          formikThirdPage.errors.therapistTimeSlot && (
            <span className={styles.multiSelectErrorLabel}>
              {formikThirdPage.errors.therapistTimeSlot}
            </span>
          )}
      </div>
      <button
        ref={thirdPageSubmitRef}
        className={styles.hiddenButton}
        type="submit"
      ></button>
    </form>
  ];

  const getPage = () => {
    switch (formPage) {
      case 1:
        return pag1;
      case 2:
        return pag2;
      case 3:
        return pag3;
      case 4:
        return [];
      default:
        return pag1;
    }
  };

  useEffect(() => {
    if (
      therapistPhonePrefix.trim() !== "" &&
      therapistPhoneNumber.trim() !== ""
    ) {
      formikFirstPage.setValues({
        ...formikFirstPage.values,
        phoneNumber: therapistPhonePrefix + therapistPhoneNumber,
      });
    } else {
      formikFirstPage.setValues({
        ...formikFirstPage.values,
        phoneNumber: "",
      });
    }
  }, [therapistPhoneNumber, therapistPhonePrefix]);

  return (
    <div className={`${styles.therapistRegister} ${className}`}>
      <GenericForm
        ref={formRef}
        className={styles.form}
        answer={getPage()}
        title="Ti diamo il benvenuto nel processo di registrazione Psichiatri di Mymentis!"
        description={
          formPage <= totalPage ? (
            "Compila tutti gli step seguenti con i tuoi dati. Al termine della compilazione ti forniremo le istruzioni successive sulla tua email."
          ) : (
            <div className={styles.tankyouMessage}>
              Grazie per aver completato la prima fase di registrazione! Controlla la tua casella email: entro le
              prossime
              48 ore riceverai una mail da parte nostra con le credenziali per il primo accesso. Se non le dovessi
              ricevere o hai bisogno di supporto puoi scriverci all’indirizzo support@mymentis.it
            </div>
          )
        }
      />
      {formPage <= totalPage && (
        <div className={styles.outsideBtnWrapper}>
          <div className={styles.btnWrapper}>
            {formPage > 1 && (
              <Button
                className={styles.btn}
                onClick={() => {
                  setFormPage(formPage - 1);
                  formRef.current?.scrollIntoView();
                }}
                variant="secondary"
              >
                Indietro
              </Button>
            )}
            <Button className={styles.btn} onClick={() => sendForm()}>
              {formPage < totalPage ? "Avanti" : "Concludi"}
            </Button>
          </div>
          <div className={styles.pageNumber}>
            Pagina {formPage} di {totalPage}
          </div>
        </div>
      )}
    </div>
  );
};
