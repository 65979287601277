import moment from 'moment-timezone';

const timezone = 'Europe/Rome';

// To understand when to show clock with Italian time if user is not in Italy
export const currentUserLocationIsItaly = () => {
  return moment().utcOffset() === moment().tz(timezone).utcOffset();
}

export const getNormalizedDate = (time?: Date | string | number) => {
  const timeDiff = moment().tz(timezone).utcOffset() * 60_000 - (moment().utcOffset()) * 60_000;

  if (!time) {
    return new Date((+new Date()) + timeDiff);
  } else {
    let parsedTime = time;
    if (typeof time === 'string' && !time.endsWith('Z')) {
      parsedTime = time.replaceAll('-', '/');
    }

    return new Date((+new Date(parsedTime)) + timeDiff);
  }
};

export const getNormalizedTime = (time: Date) => {
  return time.getTime() -
    moment().tz(timezone).utcOffset() * 60_000 +
    moment().utcOffset() * 60_000;
};

export const toISOStingWithOffset = (time: Date) => {
  const timeDiff = moment().utcOffset() * 60_000 - moment().tz(timezone).utcOffset() * 60_000;

  return new Date(+new Date(time) + timeDiff).toISOString();
}
