import { ArrowUpTrayIcon, PaperClipIcon, TrashIcon, DocumentArrowUpIcon } from '@heroicons/react/24/outline';
import ButtonSend from '../../atoms/ButtonSend';
import styles from './TextAreaInput.module.scss';
import { CalendarDaysIcon } from '@heroicons/react/20/solid';
import { useRouteLoaderData } from 'react-router-dom';
import { useState } from 'react';
import { trpc } from 'utils/trpc';
import { Input as InputAnt, Spin } from 'antd';
import { Socket } from 'socket.io-client';
import { useAppDispatch, useAppSelector } from 'customHooks/reduxHooks';
import Button from 'components/atoms/Button';
import { setPatientOnBoarding } from 'store/slices/onBoardingDoneSlice';
import { raiseException } from '../../../utils/raiseException';
import { getNormalizedDate } from '../../../utils/dateTime';
import Modal from '../../organisms/Modal';
import FormHeader from '../../atoms/FormHeader';
import { Document, Page } from 'react-pdf';
import { LoadingOutlined } from '@ant-design/icons';
import Input from '../../atoms/Input';

interface Props {
  sendMessage: () => void;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  scrollToBottom: () => void;
  value: string;
  chatId: string;
  appointmentAction?: () => void;
  onModify: (appointmentId: any, messageId: number) => void;
  oldMessageDateIsVisible: boolean;
  appointmentIcon?: boolean;
  className?: string;
  messagesList: any;
  user: any;
}
export const TextAreaInput = ({
  sendMessage,
  value,
  onChange,
  chatId,
  appointmentIcon,
  appointmentAction,
  className,
  onModify,
  messagesList,
  user
}: Props) => {
  const dispatch = useAppDispatch();
  const socket = useRouteLoaderData('root') as Socket;
  const [isModalUpload, setIsModalUpload] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [filePreviewUrl, setFilePreviewUrl] = useState<any>(null);

  const onboarding = useAppSelector((store) => store.onBoardingDone);

  const generateUploadFileUrl =
    trpc.mainService.chat.generateUploadFileUrl.useMutation();
  const onboardingService =
    trpc.mainService.user.completeOnboarding.useMutation();

  const completeOnboarding = () => {
    onboardingService
      .mutateAsync()
      .then((data) => {
        dispatch(setPatientOnBoarding(false));
      })
      .catch((e) => {
        raiseException(e);
        console.error(e?.shape?.message)
      });
  };

  const getFirstAppointmentData = () => {
    if (messagesList.length) {
      const moreThanOneAppointment = messagesList.filter(el => el.appointment);
      if (moreThanOneAppointment.length) {
        return messagesList.find((el) => {
          const { appointment } = el || {};
          if (appointment?.id && appointment.isFirstAppointment && ['ready', 'waiting_for_confirm'].includes(appointment.status)) {
            return appointment;
          }
        })?.appointment;
      }
    }
  }

  const openAppointmentWindow = () => {
    const appointment: any = getFirstAppointmentData();
    const firstAppointmentIsPast = +getNormalizedDate(appointment?.endTime) < +getNormalizedDate();
    if (!appointment?.isFirstAppointment || firstAppointmentIsPast) {
      if (appointmentAction) {
        appointmentAction();
      }
    } else {
      onModify(appointment, appointment?.id);
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setSelectedFile(file);

    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setFilePreviewUrl(fileUrl);
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      try {
        const {url, fileLocation} = await generateUploadFileUrl.mutateAsync({
          chatId,
        });
        await fetch(url, {
          method: 'PUT',
          body: selectedFile,
        });

        socket.emit(
          'sendFileMessage',
          {
            type: 'file',
            fileLocation,
            filename: selectedFile?.name ?? 'file',
          },
          chatId
        );

        setSelectedFile(null);
        setFilePreviewUrl(null);
        setIsModalUpload(false);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  return (
    <div
      className={`${styles.inputTextWrapper} ${className} ${
        onboarding.checkpoint && styles.onboarding
      }`}
    >
      {isModalUpload &&
        <Modal close={() => {
          setIsModalUpload(false);
        }} modal>
          <FormHeader
            title={user?.therapies?.includes('psychiatry') || user?.therapyType === 'psychiatry' ? 'Carica Documentazione Sanitaria' : 'Aggiungi file'}
            closeModal={() => {
              setIsModalUpload(false);
            }}
            closeButton
          />
          <Input
            className={styles.input}
            label='Aggiungi file'
            sublabel='Il documento  deve essere in formato png oppure jpeg, pdf'
            type='file'
            accept='image/png, image/jpeg, application/pdf'
            onChange={handleFileChange}
          />
          {selectedFile &&
            <div className={styles.fileWrapper}>
              {selectedFile.type === 'application/pdf' ? (
                <Document file={filePreviewUrl} loading={
                  <Spin
                    className={styles.spinner}
                    indicator={<LoadingOutlined style={{fontSize: 32, color: "#3576bb"}} spin/>}
                  />
                }>
                  <Page pageNumber={1} width={75} loading=''/>
                </Document>
              ) : selectedFile.type === 'image/png' || selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/jpg' ? (
                <img src={filePreviewUrl} alt={selectedFile.name} width={75}/>
              ) : ''}
              <p>Nome file: {selectedFile.name}</p>
              <p>Dimensione: {(selectedFile.size / 1024).toFixed(2)} KB</p>
              <div className={styles.buttonsContainer}>
                <Button leftIcon={<ArrowUpTrayIcon/>} className={styles.medical} variant='secondary'
                        onClick={handleUpload}>Carica file</Button>
                <Button leftIcon={<TrashIcon/>} className={styles.medical} variant='secondary'
                        onClick={() => {
                          setSelectedFile(null);
                          setFilePreviewUrl(null);
                          setIsModalUpload(false);
                        }}>Cancellare il file</Button>
              </div>
            </div>
          }
        </Modal>
      }
      {onboarding.checkpoint && (
        <div className={styles.onboardingDescription}>
          <div className={styles.triangle}></div>
          <span>
            Potrai parlare con il tuo terapeuta prima di iniziare a programmare
            gli appuntamenti.
          </span>
          <Button onClick={() => completeOnboarding()}>Inizia subito</Button>
        </div>
      )}
      <div className={styles.iconWrapper}>
        <div
          className={styles.icon}
          onClick={() => {
            setIsModalUpload(true);
          }}
        >
          {user?.therapyType === 'psychiatry' || user?.therapies?.includes('psychiatry') ? <DocumentArrowUpIcon/> :
            <PaperClipIcon/>}
        </div>
      </div>
      {appointmentIcon && (
        <div className={styles.iconWrapper}>
          <div className={styles.icon}
             onClick={() => {
               openAppointmentWindow();
             }}>
            <CalendarDaysIcon />
          </div>
        </div>
      )}
      <div className={styles.Input}>
        <InputAnt.TextArea
          placeholder='Scrivi il tuo messaggio'
          autoSize={{ minRows: 1, maxRows: 3 }}
          variant="borderless"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              sendMessage();
            }
          }}
        />
        <ButtonSend onClick={sendMessage} />
      </div>
    </div>
  );
};
