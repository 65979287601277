import { LoadingOutlined } from "@ant-design/icons";
import { Alert, Spin } from "antd";
import { useAppDispatch } from "customHooks/reduxHooks";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import routes from "routes";
import { setInfo } from "store/slices/infoSlice";
import * as yup from "yup";
import { trpc } from "../../../utils/trpc";
import Button from "../../atoms/Button";
import FormHeader from "../../atoms/FormHeader";
import InfoBox from "../../atoms/InfoBox";
import Input from "../../atoms/Input";
import Modal from "../Modal";
import styles from "./UserPersonalInfo.module.scss";
import { analyticsService } from "../../../utils/analyticsService";
import { raiseException } from "../../../utils/raiseException";
import trimString from "../../../utils/trimString";

export const UserPersonalInfo = () => {
  const [userGivenName, setUserGivenName] = useState("");
  const [userFamilyName, setUserFamilyName] = useState("");
  const [userGender, setUserGender] = useState<
    "male" | "female" | "other" | "noanswer" | undefined
  >(undefined);
  const [userEmail, setUserEmail] = useState("");
  const [userNewEmail, setUserNewEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userNewPassword, setUserNewPassword] = useState("");
  const [userConfirmPassword, setUserConfirmPassword] = useState("");
  const [delMotive, setDelMotive] = useState("");
  const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(false);
  const [modifyPwModalIsOpen, setModifyPwModalIsOpen] = useState(false);
  const [modifyEmailModalIsOpen, setModifyEmailModalIsOpen] = useState(false);
  const [partnerEmail, setPartnerEmail] = useState("");
  const [emailParent, setEmailParent] = useState("");
  const [confirmationCodeModalIsOpen, setConfirmationCodeModalIsOpen] =
    useState(false);
  const [delProfileModalIsOpen, setDelProfileModalIsOpen] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [changePwOk, setChangePwOk] = useState<
    "ok" | "notOk" | "notConfirm" | "notOld" | "maxAttempt" | null
  >(null);

  const [changeEmailError, setChangeEmailError] = useState("");

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    analyticsService({
      'event': 'virtualPageview',
      'pageUrl': '/dashboard/profilo/informazioni-personali',
      'pageTitle': 'Patient Personal Profile'
    });
  }, []);

  const userInfo = trpc.mainService.user.getCurrentUserInfo.useQuery();
  const updateUserInfo = trpc.mainService.patient.updateInfo.useMutation();
  const changePasword = trpc.mainService.user.changePassword.useMutation();
  const changeEmail = trpc.mainService.user.changeEmail.useMutation();
  const deleteAccount = trpc.mainService.patient.deleteAccount.useMutation();
  const resendEmailVerificationCode =
    trpc.mainService.user.resendEmailVerificationCode.useMutation();

  const emailFormik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .trim()
        .matches(
          /^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i,
          "L'email inserita ha un formato non valido"
        )
        .required("L'email è un campo obbligatorio"),
    }),
    onSubmit: (values, actions) => {
      onChangeEmail(values.email);
    },
  });

  const passwordFormik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      checkNewPassword: "",
    },
    validationSchema: yup.object().shape({
      password: yup
        .string()
        .required("La password attuale è un campo obbligatorio"),
      newPassword: yup
        .string()
        .matches(
          /^(?=.*[a-z])(?=.*\d)[^\s]{8,}$/,
          "La password non rispetta i criteri di sicurezza"
        )
        .required("La nuova password è un campo obbligatorio"),
      checkNewPassword: yup
        .string()
        .oneOf([yup.ref("newPassword")], "Le due password non combaciano")
        .required("Inserisci di nuovo la nuova password"),
    }),
    onSubmit: (values, actions) => {
      onChangePasword(values.password, values.newPassword);
    },
  });

  const userInfoFormik = useFormik({
    initialValues: {
      givenName: "",
      familyName: "",
      gender: "",
      emailPartner: "",
      parentEmail: "",
    },
    validationSchema: yup.object().shape({
      givenName: yup.string().required("Il nome è un campo obbligatorio"),
      familyName: yup.string(),
      gender: yup.string().required("Il genere è un campo obbligatorio"),
      emailPartner: yup
        .string()
        .trim()
        .matches(
          /^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i,
          "L'email inserita ha un formato non valido"
        )
        .nullable(),
      parentEmail: yup
        .string()
        .trim()
        .matches(
          /^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i,
          "L'email inserita ha un formato non valido"
        )
        .nullable(),
    }),
    onSubmit: (values, actions) => {
      onUpdateUserInfo({
        givenName: values.givenName,
        familyName: values.familyName,
        gender: values.gender as
          | "unknown"
          | "male"
          | "female"
          | "other"
          | "noanswer"
          | undefined,
        partnerEmail: values.emailPartner,
        parentEmail: values.parentEmail,
      });
    },
  });

  const navigate = useNavigate();

  const onChangeEmail = (email) => {
    changeEmail
      .mutateAsync({ email: trimString(email) })
      .then((data) => {
        setModifyEmailModalIsOpen(false);
        setConfirmationCodeModalIsOpen(true);
      })
      .catch((e) => {
        raiseException(e);
        if (e?.shape?.errorCode === "EmailAlreadyExists") {
          emailFormik.setErrors({
            ...emailFormik.errors,
            email: "L'email inserita è già in uso",
          });
        } else {
          setChangeEmailError(
            "Si è verificato un errore inaspettato. Verifica di essere connesso a internet e riprova."
          );
        }
      });
  };

  const onChangePasword = (oldPassword, password) => {
    changePasword
      .mutateAsync({
        oldPassword: trimString(oldPassword),
        newPassword: trimString(password),
      })
      .then((data) => setChangePwOk("ok"))
      .catch((e) => {
        if (e?.shape?.errorCode === "CurrentPasswordWrong") {
          setChangePwOk("notOld");
        } else if (e?.shape?.errorCode === "MaxAttemptsReached") {
          setChangePwOk("maxAttempt");
        } else {
          setChangePwOk("notOk");
        }
        raiseException(e);
        console.error("ERROR", e?.shape?.message);
      });
  };

  const onUpdateUserInfo = ({
    givenName,
    familyName,
    gender,
    partnerEmail,
    parentEmail,
  }: {
    givenName: string;
    familyName: string;
    gender: "unknown" | "male" | "female" | "other" | "noanswer" | undefined;
    partnerEmail: string;
    parentEmail: string;
  }) => {
    updateUserInfo
      .mutateAsync({
        givenName: trimString(givenName),
        familyName: trimString(familyName),
        gender,
        partnerEmail: trimString(partnerEmail) || null,
        parentEmail: trimString(parentEmail) || null,
      })
      .then((data) => {
        userInfo.refetch();
        dispatch(
          setInfo({
            variant: "confirm",
            text: "Infomazioni salvate con successo",
          })
        );
      })
      .catch((e) => {
        if (e?.shape?.errorCode === "EmailAlreadyExists") {
          if (userInfo?.data?.user?.therapyType === "underage") {
            userInfoFormik.setErrors({
              parentEmail: "L'email inserita è già in uso",
            });
          } else if (userInfo?.data?.user?.therapyType === "couple") {
            userInfoFormik.setErrors({
              emailPartner: "L'email inserita è già in uso",
            });
          } else {
            console.error("ERROR", e?.shape?.message);
            raiseException(e);
            dispatch(
              setInfo({
                variant: "error",
                text: "Si è verificato un errore durante il salvataggio delle informazioni",
              })
            );
          }
        } else {
          console.error("ERROR", e.shape.message);
          dispatch(
            setInfo({
              variant: "error",
              text: "Si è verificato un errore durante il salvataggio delle informazioni",
            })
          );
        }
      });
  };

  useEffect(() => {
    if (userInfo.data) {
      setUserGivenName(userInfo.data.user.given_name!);
      setUserFamilyName(userInfo.data.user?.family_name!);
      setUserGender(userInfo.data.user?.gender as any);
      setUserEmail(userInfo.data.user?.email!);
      setPartnerEmail(userInfo.data.user.partnerEmail! || '');
      setEmailParent(userInfo.data.user.parentEmail! || '');
      userInfoFormik.setValues({
        givenName: userInfo.data.user.given_name!,
        familyName: userInfo.data.user.family_name!,
        gender: userInfo.data.user.gender!,
        emailPartner:
          userInfo.data.user.therapyType === "couple"
            ? userInfo.data.user.partnerEmail!
            : "",
        parentEmail:
          userInfo.data.user.therapyType === "underage"
            ? userInfo.data.user.parentEmail!
            : "",
      });
    }
  }, [userInfo.data]);

  useEffect(() => {
    if (updateUserInfo.status === "loading") {
      setLoadingModalIsOpen(true);
    } else {
      setLoadingModalIsOpen(false);
    }
  }, [updateUserInfo]);

  const closeConfirmationCodeModal = async () => {
    try {
      await userInfo.refetch();
    } catch (e: any) {
      raiseException(e);
    }
    setConfirmationCodeModalIsOpen(false);
  }

  return (
    <div className={styles.userPersonalInfo}>
      {loadingModalIsOpen && (
        <Modal className="loadingModal">
          <Spin
            indicator={
              <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
            }
          />
        </Modal>
      )}
      {modifyPwModalIsOpen && (
        <Modal close={() => {
          setModifyPwModalIsOpen(false);
        }}>
          <form
            onSubmit={passwordFormik.handleSubmit}
            className={styles.modifyModalContent}
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <FormHeader
              closeModal={() => setModifyPwModalIsOpen(false)}
              closeButton
              title="Modifica password"
            />
            <div className={styles.inputWrapper}>
              <Input
                type="password"
                name="password"
                value={passwordFormik.values.password}
                onChange={passwordFormik.handleChange}
                error={
                  (passwordFormik.errors.password &&
                    passwordFormik.touched.password &&
                    passwordFormik.errors.password) as string
                }
                label="Password attuale"
              />
              <Input
                type="password"
                name="newPassword"
                value={passwordFormik.values.newPassword}
                onChange={passwordFormik.handleChange}
                error={
                  (passwordFormik.errors.newPassword &&
                    passwordFormik.touched.newPassword &&
                    passwordFormik.errors.newPassword) as string
                }
                // onChange={(e) => setUserNewPassword(e.target.value)}
                label="Nuova passoword"
              />
              <Input
                type="password"
                name="checkNewPassword"
                value={passwordFormik.values.checkNewPassword}
                onChange={passwordFormik.handleChange}
                error={
                  (passwordFormik.errors.checkNewPassword &&
                    passwordFormik.touched.checkNewPassword &&
                    passwordFormik.errors.checkNewPassword) as string
                }
                label="Ripeti nuova password"
              />
            </div>
            {changePwOk === "ok" && (
              <InfoBox
                className={styles.infoBoxModal}
                text={"Password aggiornata correttamente!"}
                variant="confirm"
              />
            )}
            {changePwOk === "notOk" && (
              <InfoBox
                className={styles.infoBoxModal}
                text={"Errore nell'aggiornamento password!"}
                variant="error"
              />
            )}
            {changePwOk === "notOld" && (
              <InfoBox
                className={styles.infoBoxModal}
                text={"La password attuale non è corretta"}
                variant="error"
              />
            )}
            {changePwOk === "maxAttempt" && (
              <InfoBox
                className={styles.infoBoxModal}
                text={"Tentativi massimi raggiunti. Riprova più tardi"}
                variant="error"
              />
            )}
            {changePwOk !== "ok" && (
              <Button type="submit">Aggiorna password</Button>
            )}
            {changePwOk === "ok" && (
              <Button
                type="button"
                onClick={() => {
                  setModifyPwModalIsOpen(false);
                }}
              >
                {changePwOk === "ok" ? "Conferma!" : "Aggiorna password"}
              </Button>
            )}
          </form>
        </Modal>
      )}
      {modifyEmailModalIsOpen && (
        <Modal close={() => {
          setModifyEmailModalIsOpen(false);
        }}>
          <form
            onSubmit={emailFormik.handleSubmit}
            className={styles.modifyModalContent}
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <FormHeader
              closeModal={() => setModifyEmailModalIsOpen(false)}
              closeButton
              title="Modifica email"
            />
            <div className={styles.inputWrapper}>
              <Input disabled value={userEmail} label="Email attuale" />
              <Input
                type="email"
                name="email"
                value={emailFormik.values.email}
                onChange={emailFormik.handleChange}
                error={
                  (emailFormik.errors.email &&
                    emailFormik.touched.email &&
                    emailFormik.errors.email) as string
                }
                // value={userNewEmail}
                // onChange={(e) => setUserNewEmail(e.target.value)}
                label="Nuova email"
              />
            </div>
            {changeEmailError && (
              <Alert
                closeIcon={null}
                type="error"
                message={changeEmailError}
                closable
              />
            )}
            <InfoBox
              className={styles.infoBoxModal}
              text={
                "Invieremo un’email con un link di conferma sul nuovo indirizzo email che hai inserito."
              }
            />
            <Button
              // onClick={() => {
              //   onChangeEmail();
              // }}
              type="submit"
            >
              Invia email di conferma
            </Button>
          </form>
        </Modal>
      )}
      {confirmationCodeModalIsOpen && (
        <Modal close={closeConfirmationCodeModal}>
          <div className={styles.modifyModalContent} onClick={e => {
            e.stopPropagation()
          }}>
            <FormHeader
              closeModal={closeConfirmationCodeModal}
              closeButton
              title="Conferma la tua mail"
            />
            <div className={styles.modalDescription}>
              Abbiamo inviato una mail a <span>{emailFormik.values.email}</span>
              . Apri il link che trovi al suo interno per aggiornare l'email.
            </div>
            {/* <Button
              onClick={async () => {
                try {
                  await userInfo.refetch();
                } catch (e) {}
                setConfirmationCodeModalIsOpen(false);
              }}
            >
              Ho cliccato sul link
            </Button> */}
            <Button
              onClick={async () => {
                await resendEmailVerificationCode.mutateAsync().catch((e) => {
                  raiseException(e);
                  console.error(e);
                });
              }}
            >
              Non hai ricevuto l'email? Rinviala
            </Button>
          </div>
        </Modal>
      )}
      {delProfileModalIsOpen && (
        <Modal close={() => {
          setDelProfileModalIsOpen(false);
        }}>
          <div className={styles.modifyModalContent} onClick={e => {
            e.stopPropagation()
          }}>
            <FormHeader
              closeModal={() => setDelProfileModalIsOpen(false)}
              closeButton
              title="Vuoi davvero eliminare il profilo?"
            />
            <div className={styles.modalDescription}>
              Cancellando il profilo non potrai più accedere all’app e al sito.
              Se hai già pagato una seduta che si terrà entro le prossime 24 ore
              non potremo rimborsartela.
            </div>
            <div className={styles.motiveWrapper}>
              <div
                className={`${styles.delMotive} ${
                  delMotive === "Motivi economici" && styles.active
                }`}
                onClick={() => setDelMotive("Motivi economici")}
              >
                Motivi economici
              </div>
              <div
                className={`${styles.delMotive} ${
                  delMotive === "Preferisco la terapia dal vivo" &&
                  styles.active
                }`}
                onClick={() => setDelMotive("Preferisco la terapia dal vivo")}
              >
                Preferisco la terapia dal vivo
              </div>
              <div
                className={`${styles.delMotive} ${
                  delMotive ===
                    "Mi sono trovato male con il mio psicologo Mymentis" &&
                  styles.active
                }`}
                onClick={() =>
                  setDelMotive(
                    "Mi sono trovato male con il mio psicologo Mymentis"
                  )
                }
              >
                Mi sono trovato male con il mio psicologo Mymentis
              </div>
              <div
                className={`${styles.delMotive} ${
                  delMotive === "Altro" && styles.active
                }`}
                onClick={() => setDelMotive("Altro")}
              >
                Altro
              </div>
            </div>
            <div className={styles.btnWrapper}>
              <Button
                variant="secondary"
                onClick={() => {
                  setDelMotive("");
                  setDelProfileModalIsOpen(false);
                }}
              >
                No, ci penso su
              </Button>
              <Button
                disabled={!delMotive}
                onClick={async () => {
                  await deleteAccount.mutateAsync({ reason: delMotive });
                  // document.cookie = `accessToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
                  // document.cookie = `refreshToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
                  localStorage.removeItem("accessToken");
                  localStorage.removeItem("greyBlock");
                  localStorage.removeItem("refreshToken");
                  localStorage.removeItem("userId");
                  navigate(routes.Home.path);
                }}
              >
                Sì, voglio eliminarlo
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <h1 className={styles.title}>Informazioni personali</h1>
      <div className={styles.infoWrapper}>
        <form className={styles.topPart} onSubmit={userInfoFormik.handleSubmit}>
          <div className={styles.userName}>
            <Input
              label="Nome"
              name="givenName"
              value={userGivenName}
              onChange={(e) => {
                setUserGivenName(e.target.value);
                userInfoFormik.handleChange(e);
              }}
              error={
                (userInfoFormik.errors.givenName &&
                  userInfoFormik.touched.givenName &&
                  userInfoFormik.errors.givenName) as string
              }
            />
            <Input
              label="Cognome"
              name="familyName"
              value={userFamilyName}
              onChange={(e) => {
                setUserFamilyName(e.target.value);
                userInfoFormik.handleChange(e);
              }}
              error={
                (userInfoFormik.errors.familyName &&
                  userInfoFormik.touched.familyName &&
                  userInfoFormik.errors.familyName) as string
              }
            />
          </div>
          <div className={styles.splitInput}>
            <Input
              label="Genere"
              select
              options={[
                { name: "Uomo", value: "male" },
                { name: "Donna", value: "female" },
                { name: "Altro", value: "other" },
              ]}
              name="gender"
              value={userGender}
              onChange={(e) => {
                setUserGender(e.target.value);
                userInfoFormik.handleChange(e);
              }}
              error={
                (userInfoFormik.errors.gender &&
                  userInfoFormik.touched.gender &&
                  userInfoFormik.errors.gender) as string
              }
            />
            {userInfo?.data?.user?.therapyType === "couple" && (
              <Input
                label="Email partner"
                name="emailPartner"
                type="email"
                value={partnerEmail}
                onChange={(e) => {
                  setPartnerEmail(e.target.value);
                  userInfoFormik.handleChange(e);
                }}
                error={
                  (userInfoFormik.errors.emailPartner &&
                    userInfoFormik.touched.emailPartner &&
                    userInfoFormik.errors.emailPartner) as string
                }
              />
            )}
            {userInfo?.data?.user?.therapyType === "underage" && (
              <Input
                label="Email genitore"
                name="parentEmail"
                type="email"
                value={emailParent}
                onChange={(e) => {
                  setEmailParent(e.target.value);
                  userInfoFormik.handleChange(e);
                }}
                error={
                  (userInfoFormik.errors.parentEmail &&
                    userInfoFormik.touched.parentEmail &&
                    userInfoFormik.errors.parentEmail) as string
                }
              />
            )}
          </div>
          <button
            className={styles.hiddenButton}
            ref={submitButtonRef}
            type="submit"
          ></button>
        </form>
        <div className={styles.bottomPart}>
          <div className={styles.inputContainer}>
            <div className={styles.inputName}>Email</div>
            <div className={styles.inputToModify}>
              <Input label="Email attuale" disabled value={userEmail} />
              <Button
                variant="tertiary"
                onClick={() => setModifyEmailModalIsOpen(true)}
              >
                Modifica
              </Button>
            </div>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.inputName}>Password</div>
            <div className={styles.inputToModify}>
              <Input
                label="Password attuale"
                disabled
                type="password"
                value={"**************"}
              />
              <Button
                variant="tertiary"
                onClick={() => {
                  setModifyPwModalIsOpen(true);
                }}
              >
                Modifica
              </Button>
            </div>
          </div>
          <div className={styles.btnWrapper}>
            <Button
              variant="secondary"
              onClick={() => {
                if (userInfo.data) {
                  setUserGivenName(userInfo.data.user.given_name!);
                  setUserFamilyName(userInfo.data.user?.family_name!);
                  setUserGender(userInfo.data.user?.gender as any);
                  setUserEmail(userInfo.data.user?.email!);
                  setPartnerEmail(userInfo.data.user.partnerEmail!);
                  setEmailParent(userInfo.data.user.parentEmail!);
                  userInfoFormik.setValues({
                    givenName: userInfo.data.user.given_name!,
                    familyName: userInfo.data.user.family_name!,
                    gender: userInfo.data.user.gender!,
                    emailPartner:
                      userInfo.data.user.therapyType === "couple"
                        ? userInfo.data.user.partnerEmail!
                        : "",
                    parentEmail:
                      userInfo.data.user.therapyType === "underage"
                        ? userInfo.data.user.parentEmail!
                        : "",
                  });
                }
              }}
            >
              Annulla
            </Button>
            <Button
              onClick={() => {
                submitButtonRef.current?.click();
              }}
            >
              Salva
            </Button>
          </div>
        </div>
        <Button
          className={styles.deleteProfile}
          variant="tertiary"
          onClick={() => setDelProfileModalIsOpen(true)}
        >
          Elimina profilo
        </Button>
      </div>
    </div>
  );
};
