import { Fragment, useEffect, useRef, useState } from "react";
import styles from "./InformedConsent.module.scss";
import FormHeader from "components/atoms/FormHeader";
import Input from "components/atoms/Input";
import Button from "components/atoms/Button";
import { trpc } from "utils/trpc";
import { useParams } from "react-router-dom";
import { decodeB64 } from "utils/decodeB64";
import GenericForm from "components/molecules/GenericForm";
import routes from "routes";
import { useFormik } from "formik";
import * as yup from "yup";
import { analyticsService } from "../../utils/analyticsService";
import { raiseException } from "../../utils/raiseException";
import { DatePicker } from "antd";
import it from "antd/es/date-picker/locale/it_IT";
import dayjs from "dayjs";

const yearInMillis = 365 * 24 * 60 * 60 * 1000;
const minDate = new Date(Date.now() - 120 * yearInMillis);
const maxDate = new Date(Date.now() - 18 * yearInMillis);

export const InformedConsent = ({ className }) => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const params = useParams();
  const [token, setToken] = useState<string>();
  const [therapyType, setTherapyType] = useState<
    "single" | "couple" | "underage" | "psychiatry"
  >();
  const [pageState, setPageState] = useState<
    "processing" | "success" | "error"
  >("processing");
  const [therapist, setTherapist] = useState<{
    given_name: string;
    gender: string;
    family_name: string;
    licenceRegisterPlace: string;
    licenceRegisterWith: string;
    licenceRegisterNumber: string;
  }>();
  const [informedConsentData, setInformedConsentData] = useState<{
    givenName: string;
    familyName: string;
    taxId: string;
    placeOfBirth: string;
    dateOfBirth: Date | null;
    residence: string;
    address: string;
    acceptConditions: boolean;
  }>({
    givenName: "",
    familyName: "",
    taxId: "",
    placeOfBirth: "",
    dateOfBirth: null,
    residence: "",
    address: "",
    acceptConditions: false,
  });
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
  const firstPageSubmitRef = useRef<HTMLButtonElement>(null);
  const isMobile = window.matchMedia('(max-width: 767px)').matches;
  const submitSentInformedConsent =
    trpc.mainService.patient.submitSentInformedConsent.useMutation();

  const handleDateChange = (date: any) => {
    setDateOfBirth(date ? date.toDate() : null);
    formik.setFieldValue("dateOfBirth", date ? date.toDate() : null);
  };

  const disableDate = (current: any) => {
    return current && (current < dayjs(minDate) || current > dayjs(maxDate));
  };

  const formik = useFormik({
    initialValues: {
      givenName: "",
      familyName: "",
      taxId: "",
      placeOfBirth: "",
      dateOfBirth: null as Date | null,
      residence: "",
      address: "",
    },
    validationSchema: yup.object().shape({
      givenName: yup.string().required("Il nome è un campo obbligatorio"),
      familyName: yup.string().required("Il cognome è un campo obbligatorio"),
      taxId: yup.string().required("Il codice fiscale è un campo obbligatorio"),
      placeOfBirth: yup
        .string()
        .required("Il luogo di nascita è un campo obbligatorio"),
      dateOfBirth: yup
        .date()
        .nullable()
        .transform((value, originalValue) => {
          return originalValue ? new Date(originalValue) : null;
        })
        .min(minDate, "Data troppo lontana")
        .max(maxDate, "Deve essere maggiorenne")
        .required("La data di nascita è un campo obbligatorio"),
      residence: yup.string().required("La residenza è un campo obbligatorio"),
      address: yup.string().required("La via è un campo obbligatorio"),
    }),
    onSubmit: (values, actions) => {
      setPage(page + 1);
    },
  });

  useEffect(() => {
    analyticsService({ 'event': 'virtualPageview', 'pageUrl': `/consenso-informato/`, 'pageTitle': 'Informed Consent' });
  }, []);

  useEffect(() => {
    (async () => {
      if (loading) {
        const decodeParam = decodeB64(params.code!);
        if (
          !decodeParam?.token ||
          !decodeParam?.therapist ||
          !decodeParam?.therapyType
        ) {
          setPageState("error");
        } else {
          setToken(decodeParam.token);
          setTherapist(decodeParam.therapist);
          setTherapyType(decodeParam.therapyType);
        }
        setLoading(false);
      }
    })();
  }, [loading]);

  return (
    <div className={`${styles.informedConsent} ${className}`}>
      {(() => {
        if (loading) {
          return "";
        } else {
          if (pageState === "processing") {
            return (
              <div className={styles.informedConsentForm}>
                <FormHeader title="Consenso informato" closeModal={() => {}} />
                {page === 1 && (
                  <Fragment>
                    <div className={styles.TherapistParty}>
                      {therapyType === "underage"
                        ? "Il genitore:"
                        : "Il Paziente:"}
                    </div>
                    <form
                      className={styles.inputWrapper}
                      onSubmit={formik.handleSubmit}
                    >
                      <div className={styles.inputRow}>
                        <Input
                          label="Nome"
                          name="givenName"
                          value={formik.values.givenName}
                          onChange={formik.handleChange}
                          error={
                            (formik.errors.givenName &&
                              formik.touched.givenName &&
                              formik.errors.givenName) as string
                          }
                        />
                        <Input
                          label="Cognome"
                          name="familyName"
                          value={formik.values.familyName}
                          onChange={formik.handleChange}
                          error={
                            (formik.errors.familyName &&
                              formik.touched.familyName &&
                              formik.errors.familyName) as string
                          }
                        />
                      </div>
                      <div className={styles.inputRow}>
                        <Input
                          label="Luogo di nascita"
                          name="placeOfBirth"
                          value={formik.values.placeOfBirth}
                          onChange={formik.handleChange}
                          error={
                            (formik.errors.placeOfBirth &&
                              formik.touched.placeOfBirth &&
                              formik.errors.placeOfBirth) as string
                          }
                        />

                        <div
                          className={`${styles.smallerInput} ${
                            styles.datePicker
                          } ${
                            formik.errors.dateOfBirth &&
                            formik.touched.dateOfBirth &&
                            formik.errors.dateOfBirth
                              ? `${styles.error} error`
                              : ""
                          }`}
                        >
                          <span className={styles.label}>Data di nascita</span>
                          <DatePicker
                            locale={it}
                            inputReadOnly={isMobile}
                            value={dateOfBirth ? dayjs(dateOfBirth) : null}
                            defaultPickerValue={dayjs(maxDate)}
                            onChange={handleDateChange}
                            disabledDate={disableDate}
                            showNow={false}
                            format={{
                              format: 'DD/MM/YYYY',
                              type: !isMobile ? 'mask' : undefined,
                            }}
                            status={formik.errors.dateOfBirth && formik.touched.dateOfBirth ? "error" : ""}
                          />
                          <span className={styles.label}>
                            {(() => {
                              if (
                                formik.errors.dateOfBirth &&
                                formik.touched.dateOfBirth &&
                                formik.errors.dateOfBirth
                              ) {
                                if (
                                  formik.errors.dateOfBirth.startsWith(
                                    "dateOfBirth"
                                  )
                                ) {
                                  return "Data invalida";
                                } else {
                                  return formik.errors.dateOfBirth;
                                }
                              }
                            })()}
                          </span>
                        </div>
                      </div>
                      <div className={styles.inputRow}>
                        <Input
                          label="Codice fiscale"
                          name="taxId"
                          value={formik.values.taxId}
                          onChange={formik.handleChange}
                          error={
                            (formik.errors.taxId &&
                              formik.touched.taxId &&
                              formik.errors.taxId) as string
                          }
                        />
                      </div>
                      <div className={styles.inputRow}>
                        <Input
                          label="Città di residenza"
                          name="residence"
                          value={formik.values.residence}
                          onChange={formik.handleChange}
                          error={
                            (formik.errors.residence &&
                              formik.touched.residence &&
                              formik.errors.residence) as string
                          }
                        />
                        <Input
                          label="Indirizzo e civico"
                          name="address"
                          value={formik.values.address}
                          onChange={formik.handleChange}
                          error={
                            (formik.errors.address &&
                              formik.touched.address &&
                              formik.errors.address) as string
                          }
                        />
                      </div>
                      {therapyType === "underage" && (
                        <div className={styles.TherapistParty}>
                          nell’esercizio della responsabilità genitoriale
                          sulla/sul minore (di seguito, il <span>Paziente</span>
                          ):
                        </div>
                      )}
                      <div className={styles.TherapistParty}>
                        Affidandosi{" "}
                        {therapist?.gender === "female"
                          ? "alla dott.ssa"
                          : "al dott."}{" "}
                        {therapist?.given_name} {therapist?.family_name}{" "}
                        {therapist?.gender === "female"
                          ? "Iscritta"
                          : "Iscritto"}{" "}
                        all’Albo degli Psicologi della Regione{" "}
                        {therapist?.licenceRegisterPlace.replace("Â", "")},
                        assicurato con Polizza RC professionale sottoscritta con{" "}
                        {therapist?.licenceRegisterWith} n.
                        {therapist?.licenceRegisterNumber} (di seguito, il{" "}
                        <span>Professionista</span>)
                      </div>
                      <button
                        ref={firstPageSubmitRef}
                        type="submit"
                        className={styles.hiddenButton}
                      ></button>
                    </form>
                  </Fragment>
                )}

                {page === 2 &&
                  (therapyType === "couple" ? (
                    <div className={styles.information}>
                      <span>I Pazienti sono informati che:</span>
                      <ol>
                        <li>
                          il Professionista è strettamente tenuto ad attenersi
                          al Codice Deontologico degli Psicologi Italiani;
                        </li>
                        <li>
                          la prestazione offerta riguarda la consulenza in
                          ambito psicologico o psicoterapeutico attraverso
                          colloqui in modalità videochiamata tramite l’utilizzo
                          della piattaforma online “Mymentis”, nelle date
                          concordate tra Paziente e Professionista;
                        </li>
                        <li>
                          la prestazione è da considerarsi complessa in quanto è
                          previsto il coinvolgimento di altri professionisti
                          quali psicologi e/o psicoterapeuti;
                        </li>
                        <li>
                          la prestazione è finalizzata ad attività professionali
                          di promozione e tutela della salute e del benessere di
                          persone, gruppi, organismi sociali e comunità.
                          Comprende, di norma, tutte le attività previste ai
                          sensi dell&#39;art. 1 della L. n.56/1989;
                        </li>
                        <li>
                          per il conseguimento dell’obiettivo saranno utilizzati
                          prevalentemente gli strumenti gestionali, di
                          messaggistica e videochiamata disponibili sulla
                          piattaforma online “Mymentis”;
                        </li>
                        <li>
                          la durata globale dell’intervento sarà definita di
                          comune accordo tra i Pazienti e il Professionista;
                        </li>
                        <li>
                          in qualsiasi momento è possibile interrompere il
                          rapporto comunicando alla/al Professionista la volontà
                          di interruzione;
                        </li>
                        <li>
                          {therapist?.gender === 'male' ? 'il' : 'la'} Professionista può valutare ed eventualmente
                          proporre l’interruzione del rapporto quando constata
                          che non vi sia alcun beneficio dall’intervento e non è
                          ragionevolmente prevedibile che ve ne saranno dal
                          proseguimento dello stesso. Se richiesto può fornire
                          le informazioni necessarie a ricercare altri e più
                          adatti interventi (art. 27 del Codice Deontologico
                          degli Psicologi Italiani);
                        </li>
                        <li>
                          le Parti sono tenute alla scrupolosa osservanza delle
                          date e degli orari degli appuntamenti che vengono
                          concordati oppure all’inizio di ogni rapporto di
                          consulenza e/o di psicoterapia. In caso di
                          sopravvenuta impossibilità di rispettare
                          l’appuntamento fissato, la Parte impossibilitata è
                          tenuta a darne notizia all’altra in tempi congrui.
                          Entrambe le Parti si impegnano a rendere attivi e
                          raggiungibili i propri recapiti rispettivamente
                          forniti;
                        </li>
                        <li>
                          il compenso da corrispondere per la prestazione
                          professionale psicologica è indicato sulla piattaforma
                          online “Mymentis”. Si precisa che il compenso non può
                          essere condizionato all’esito o ai risultati
                          dell’intervento professionale.;
                        </li>
                        <li>
                          Le attività di trattamento dei dati personali vengono
                          effettuate in conformità con le disposizioni del Reg.
                          UE 2016/679 e del d.lgs. n. 196/2003 come meglio
                          specificato nell’informativa privacy consegnata ai
                          Pazienti.
                        </li>
                      </ol>
                      <p>
                        Visto e compreso tutto quanto sopra indicato, I
                        Pazienti, avendo ricevuto apposita informativa
                        professionale e informazioni adeguate in relazione a
                        costi, fini e modalità della stessa dichiarano e
                        accettano di esprimere liberamente il proprio consenso
                        informato per la prestazione professionale psicologica
                        ivi descritta.
                      </p>
                      <Input
                        checked={informedConsentData.acceptConditions}
                        onChange={(e) =>
                          setInformedConsentData({
                            ...informedConsentData,
                            acceptConditions: e.target.checked,
                          })
                        }
                        className={styles.consentCheckbox}
                        type="checkbox"
                        checkboxId="Dichiaro di aver letto e compreso"
                        checkboxLabel={
                          <span>
                            Dichiaro di aver letto e compreso{" "}
                            <span
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                              onClick={() =>
                                window.open(
                                  routes.PatientTherapistPrivacy.path,
                                  "_blank"
                                )
                              }
                            >
                              l'informativa privacy
                            </span>{" "}
                            e di accettare il testo del consenso informato
                          </span>
                        }
                      />
                    </div>
                  ) : (
                    therapyType === "underage" && (
                      <div className={styles.information}>
                        <span>Il Paziente è informato che:</span>
                        <ol>
                          <li>
                            il Professionista è strettamente tenuto ad attenersi
                            al Codice Deontologico degli Psicologi Italiani;
                          </li>
                          <li>
                            la prestazione offerta riguarda la consulenza in
                            ambito psicologico o psicoterapeutico attraverso
                            colloqui in modalità videochiamata tramite
                            l’utilizzo della piattaforma online “Mymentis”,
                            nelle date concordate tra Paziente e Professionista;
                          </li>
                          <li>
                            la prestazione è da considerarsi complessa in quanto
                            è previsto il coinvolgimento di altri professionisti
                            quali psicologi e/o psicoterapeuti;
                          </li>
                          <li>
                            la prestazione è finalizzata ad attività
                            professionali di promozione e tutela della salute e
                            del benessere di persone, gruppi, organismi sociali
                            e comunità. Comprende, di norma, tutte le attività
                            previste ai sensi dell&#39;art. 1 della L.
                            n.56/1989;
                          </li>
                          <li>
                            per il conseguimento dell’obiettivo saranno
                            utilizzati prevalentemente gli strumenti gestionali,
                            di messaggistica e videochiamata disponibili sulla
                            piattaforma online “Mymentis”;
                          </li>
                          <li>
                            la durata globale dell’intervento sarà definita di
                            comune accordo tra il Paziente e il Professionista;
                          </li>
                          <li>
                            in qualsiasi momento è possibile interrompere il
                            rapporto comunicando alla/al Professionista la
                            volontà di interruzione;
                          </li>
                          <li>
                            {therapist?.gender === 'male' ? 'il' : 'la'} Professionista può valutare ed eventualmente
                            proporre l’interruzione del rapporto quando constata
                            che non vi sia alcun beneficio dall’intervento e non
                            è ragionevolmente prevedibile che ve ne saranno dal
                            proseguimento dello stesso. Se richiesto può fornire
                            le informazioni necessarie a ricercare altri e più
                            adatti interventi (art. 27 del Codice Deontologico
                            degli Psicologi Italiani);
                          </li>
                          <li>
                            le Parti sono tenute alla scrupolosa osservanza
                            delle date e degli orari degli appuntamenti che
                            vengono concordati oppure all’inizio di ogni
                            rapporto di consulenza e/o di psicoterapia. In caso
                            di sopravvenuta impossibilità di rispettare
                            l’appuntamento fissato, la Parte impossibilitata è
                            tenuta a darne notizia all’altra in tempi congrui.
                            Entrambe le Parti si impegnano a rendere attivi e
                            raggiungibili i propri recapiti rispettivamente
                            forniti;
                          </li>
                          <li>
                            il compenso da corrispondere per la prestazione
                            professionale psicologica è indicato sulla
                            piattaforma online “Mymentis”. Si precisa che il
                            compenso non può essere condizionato all’esito o ai
                            risultati dell’intervento professionale.;
                          </li>
                          <li>
                            Le attività di trattamento dei dati personali
                            vengono effettuate in conformità con le disposizioni
                            del Reg. UE 2016/679 e del d.lgs. n. 196/2003 come
                            meglio specificato nell’informativa privacy
                            consegnata al Paziente.
                          </li>
                        </ol>
                        <p>
                          Visto e compreso tutto quanto sopra indicato, {therapist?.gender === 'male' ? 'il' : 'la'}
                          Paziente, avendo ricevuto apposita informativa
                          professionale e informazioni adeguate in relazione a
                          costi, fini e modalità della stessa dichiara e accetta
                          di esprimere liberamente il proprio consenso
                          informato, nell’esercizio della responsabilità
                          genitoriale sulla/sul minore, per la prestazione
                          professionale psicologica ivi descritta.
                        </p>
                        <Input
                          checked={informedConsentData.acceptConditions}
                          onChange={(e) =>
                            setInformedConsentData({
                              ...informedConsentData,
                              acceptConditions: e.target.checked,
                            })
                          }
                          className={styles.consentCheckbox}
                          type="checkbox"
                          checkboxId="Dichiaro di aver letto e compreso"
                          checkboxLabel={
                            <span>
                              Dichiaro di aver letto e compreso{" "}
                              <span
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                                onClick={() =>
                                  window.open(
                                    routes.PatientTherapistPrivacy.path,
                                    "_blank"
                                  )
                                }
                              >
                                l'informativa privacy
                              </span>{" "}
                              e di accettare il testo del consenso informato
                            </span>
                          }
                        />
                      </div>
                    )
                  ))}

                <div className={styles.btnWrapper}>
                  {page > 1 && (
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setPage(page - 1);
                      }}
                    >
                      Indietro
                    </Button>
                  )}
                  {page < 2 && (
                    <Button
                      onClick={async () => {
                        const result = await formik.validateForm();
                        if (Object.keys(result).length === 0) {
                          firstPageSubmitRef.current?.click();
                        } else {
                          formik.setErrors({
                            ...result,
                          });
                          formik.setTouched({
                            givenName: true,
                            familyName: true,
                            taxId: true,
                            placeOfBirth: true,
                            dateOfBirth: true,
                            residence: true,
                            address: true,
                          });
                        }
                      }}
                    >
                      Avanti
                    </Button>
                  )}
                  {page === 2 && (
                    <Button
                      disabled={!informedConsentData.acceptConditions}
                      onClick={async () => {
                        try {
                          await submitSentInformedConsent.mutateAsync({
                            token: token!,
                            givenName: formik.values.givenName,
                            familyName: formik.values.familyName,
                            taxId: formik.values.taxId,
                            placeOfBirth: formik.values.placeOfBirth,
                            dateOfBirth: formik.values.dateOfBirth!,
                            residence: formik.values.residence,
                            address: formik.values.address,
                          });
                          setPageState("success");
                        } catch (e: any) {
                          raiseException(e);
                          setPageState("error");
                        }
                      }}
                    >
                      Invia
                    </Button>
                  )}
                </div>
              </div>
            );
          } else if (pageState === "success") {
            return (
              <GenericForm
                className={styles.informedConsentResult}
                title={"Consenso informato"}
                description={
                  "Hai compilato con successo il consenso informato. Ora puoi chiudere questa pagina."
                }
              />
            );
          } else if (pageState === "error") {
            return (
              <GenericForm
                className={styles.informedConsentResult}
                title={"Link invalido"}
                description={
                  "Questo link per il consenso informato è invalido. Assicurati di non aver già compilato il consenso informato o di aver aperto il link giusto."
                }
              />
            );
          }
        }
      })()}
    </div>
  );
};
