const priceWithDiscount = async (newPrice: number, therapyType: string, vatNumberType: string, isFirstAppointment: any) => {
  let transferAmount: number;

  if (therapyType === 'couple') {
    transferAmount = 5000;
  } else if (therapyType === 'psychiatry') {
    isFirstAppointment ? transferAmount = 11000 : transferAmount = 9000;
  } else {
    transferAmount = 4000;
  }

  let finalAmount: number;

  if (vatNumberType === 'ordinaria') {
    const amountWithoutEnpap = therapyType !== 'psychiatry' ? Math.ceil(transferAmount / 1.02) : transferAmount;
    finalAmount = transferAmount - Math.floor(amountWithoutEnpap * 0.2);
  } else {
    finalAmount = transferAmount;
  }

  const transferToTherapistValue = finalAmount > newPrice ? newPrice : finalAmount;

  let additionalTransferToTherapist = 0;

  if ((finalAmount - newPrice) > 0) {
    additionalTransferToTherapist = finalAmount - newPrice;
  }

  return { patientPayValue: newPrice, transferToTherapistValue , additionalTransferToTherapist, transferAmount }
}

export default priceWithDiscount;
