export const getToken = (token: string) => {
  // let tokenToGet = document.cookie
  //   .split(";")
  //   .find((i) => i.includes(token));

  // const indexFromCut = tokenToGet?.indexOf("=")! + 1;
  // tokenToGet = tokenToGet?.substring(indexFromCut!);

  // return tokenToGet;
  try {
    return localStorage.getItem(token);
  } catch (error) {
    console.log('Error', error)
  }
};

export const isLocalStorageAvailable = () => {
  try {
    const testForStorageAvailabilityKey = '__storage_availability__';
    localStorage.setItem(testForStorageAvailabilityKey, testForStorageAvailabilityKey);
    localStorage.removeItem(testForStorageAvailabilityKey);
    return true;
  } catch (e) {
    console.log('error', e);
    return false;
  }
}
