import {
  CalendarDaysIcon,
  ClockIcon,
  HeartIcon,
    ChatBubbleBottomCenterIcon,
} from "@heroicons/react/24/solid";
import Button from "../../atoms/Button";
import introGreenShape from "theme/images/introGreenShape.png";
import styles from "./IntroSurvey.module.scss";
import { useEffect } from "react";
import { analyticsService } from "../../../utils/analyticsService";
import { envs } from "../../../utils/envs";

const appSiteUrl = envs.REACT_APP_SITE_URL;

export const IntroSurvey = ({ startSurvey }: any) => {

  useEffect(() => {
    analyticsService({
      'event': 'virtualPageview',
      'pageUrl': '/questionario/introduzione',
      'pageTitle': 'Intro Survey'
    });
  }, []);

  return (
    <div className={styles.introSurvey}>
      <div className={styles.top}>
        <div className={styles.greenShape}>
          <img src={introGreenShape} alt="background-shape"></img>
        </div>
        <div className={styles.card}>
          <div className={styles.title}>Ti faremo alcune domande</div>
          <div className={styles.contentWrapper}>
            <div className={styles.cardElement}>
              <div className={styles.icon}>
                <ClockIcon />
              </div>
              <div>
                Il questionario durerà al
                <span style={{ fontWeight: 600 }}> massimo 5 minuti</span>
              </div>
            </div>
            <div className={styles.cardElement}>
              <div className={styles.icon}>
                <HeartIcon />
              </div>
              <div>
                Selezioneremo il terapeuta{" "}
                <span style={{fontWeight: 600}}>più adatto a te</span>
              </div>
            </div>
            <div className={styles.cardElement}>
              <div className={styles.icon}>
                <CalendarDaysIcon />
              </div>
              <div>
                Potrai fissare un primo incontro gratuito
                <span style={{ fontWeight: 600 }}> senza impegno</span>
              </div>
            </div>
          </div>
          <Button onClick={startSurvey} className={styles.startBtn}>
            Cominciamo
          </Button>
        </div>
        <img
          className={styles.people}
          src="/assets/images/landingPageImgs/surveyImg.png"
          alt="people"
        />
      </div>
      <div className={styles.bottom}>
        <div className={styles.bottomContent}>
          <div className={styles.icon}>
            <ChatBubbleBottomCenterIcon/>
          </div>
          <div> Scopri <span onClick={() => window.open(`${appSiteUrl}/chi-siamo`, '_blank')} style={{
            color: "#0354aa",
            fontWeight: 600,
            cursor: "pointer",
          }}>Chi siamo</span></div>
        </div>
      </div>
    </div>
  );
};
