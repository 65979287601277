export const disableAppointmentButton = (paymentIntentId, isTherapist, isPatient, createdByPatient, status, commonConditions, isPast) => {
  // Conditions for a therapist
  const therapistEnableConditions = isTherapist && (
    (status === 'payed_to_be_confirmed' && createdByPatient) ||
    (status === 'waiting_for_confirm' && createdByPatient && !paymentIntentId) ||
    status === "ready"
  );

  const therapistDisableConditions = isTherapist && (
    (status === 'waiting_for_confirm' && !createdByPatient) ||
    status === "completed"
  );

  // Patient conditions
  const patientEnableConditions = isPatient && (
    (status === 'waiting_for_confirm' && !createdByPatient) ||
    (status === 'waiting_for_confirm' && createdByPatient && paymentIntentId) ||
    (status === 'waiting_for_confirm' && !createdByPatient && paymentIntentId) ||
    status === "ready"
  );

  const patientDisableConditions = isPatient && (
    (status === 'payed_to_be_confirmed' && createdByPatient) ||
    (status === 'waiting_for_confirm' && createdByPatient && !paymentIntentId) ||
    status === "completed"
  );

  return commonConditions ||
    (therapistDisableConditions && !therapistEnableConditions) ||
    (patientDisableConditions && !patientEnableConditions) ||
    isPast
}
