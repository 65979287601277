export const firstAppointmentExist = (appointments: any, therapistId: string) => {
  return appointments.some((item: any) =>
    item.isFirstAppointment === "x" &&
    item.therapist.id === therapistId
  );
}

export const checkCompletedAppointmentExist = (appointments: any, therapistId: string) => {
  return appointments.some((item: any) =>
    item.status === 'completed' &&
    item.therapist.id === therapistId
  );
}
