import { useEffect, useState } from "react";
import { trpc } from "../../utils/trpc";
import Button from "../atoms/Button";
import styles from "./PatientPaymentForm.module.scss";
import { raiseException } from "../../utils/raiseException";
import InfoBox from "../atoms/InfoBox";
import Input from "../atoms/Input";
import Checkbox from "../atoms/Checkbox";
import { useFormik } from "formik";
import * as yup from "yup";
import trimString from "../../utils/trimString";
import { setInfo } from "../../store/slices/infoSlice";
import { useAppDispatch } from "../../customHooks/reduxHooks";
import dayjs from "dayjs";
import "dayjs/locale/it";
import { DatePicker } from "antd";
import it from "antd/es/date-picker/locale/it_IT";

interface Props {
  getBillingDetailsResponse: any;
  isModal: any;
  userInfo: any;
  setLoadingModalIsOpen: any;
  closeModal: any;
}

export const PatientPaymentForm = ({
  getBillingDetailsResponse,
  isModal,
  userInfo,
  setLoadingModalIsOpen,
  closeModal
}: Props) => {

  const yearInMillis = 365 * 24 * 60 * 60 * 1000;
  const minDate = new Date(Date.now() - 120 * yearInMillis);
  const maxDate = new Date(Date.now() - 18 * yearInMillis);


  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);

  const dispatch = useAppDispatch();
  const isMobile = window.matchMedia('(max-width: 767px)').matches;

  const disableDate = (current: any) => {
    return current && (current < dayjs(minDate) || current > dayjs(maxDate));
  };


  const handleDateChange = (date: any) => {
    setDateOfBirth(date ? date.toDate() : null);
    formik.setFieldValue("dateOfBirth", date ? date.toDate() : null);
  };

  const updateBillingDetails =
    trpc.mainService.patient.updateBillingDetails.useMutation();

  const saveBillingDetails = async ({
    givenName,
    familyName,
    address,
    city,
    cap,
    country,
    taxId,
    dateOfBirth,
    expensesDeduction
  }: {
    givenName: string;
    familyName: string;
    address: string;
    city: string;
    cap: string;
    country: string;
    taxId: string;
    dateOfBirth: Date;
    expensesDeduction: boolean;
  }) => {
    try {
      await updateBillingDetails.mutateAsync({
        givenName: trimString(givenName),
        familyName: trimString(familyName),
        address: trimString(address),
        city: trimString(city),
        cap: trimString(cap),
        country: trimString(country),
        taxId: trimString(taxId),
        dateOfBirth,
        expensesDeduction,
      });
      await getBillingDetailsResponse.refetch();
      if (setLoadingModalIsOpen) {
        setLoadingModalIsOpen(false);
      }
      dispatch(
        setInfo({
          variant: "confirm",
          text: "Infomazioni salvate con successo",
        })
      );
    } catch (e: any) {
      raiseException(e);
      if (setLoadingModalIsOpen) {
        setLoadingModalIsOpen(false);
      } else {
        closeModal();
      }
      dispatch(
        setInfo({
          variant: "error",
          text: "Si è verificato un errore durante il salvataggio delle informazioni",
        })
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      givenName:
        getBillingDetailsResponse?.data?.billingDetails.given_name ?? "",
      familyName:
        getBillingDetailsResponse?.data?.billingDetails.family_name ?? "",
      address: getBillingDetailsResponse?.data?.billingDetails.address ?? "",
      city: getBillingDetailsResponse?.data?.billingDetails.city ?? "",
      zipCode: getBillingDetailsResponse?.data?.billingDetails.cap ?? "",
      country: getBillingDetailsResponse?.data?.billingDetails.country ?? "",
      taxId: getBillingDetailsResponse?.data?.billingDetails.taxId ?? "",
      dateOfBirth:
        getBillingDetailsResponse?.data?.billingDetails.dateOfBirth ??
        (null as Date | null),
      expensesDeduction: getBillingDetailsResponse?.data?.expensesDeduction,
    },
    validationSchema: yup.object().shape({
      givenName: yup.string().when("expensesDeduction", {
        is: true,
        then: (schema) => schema.required("Il nome è un campo obbligatorio"),
        otherwise: (schema) => schema,
      }),
      familyName: yup.string().when("expensesDeduction", {
        is: true,
        then: (schema) => schema.required("Il cognome è un campo obbligatorio"),
        otherwise: (schema) => schema,
      }),
      address: yup.string().when("expensesDeduction", {
        is: true,
        then: (schema) =>
          schema.required("L'indirizzo è un campo obbligatorio"),
        otherwise: (schema) => schema,
      }),
      city: yup.string().when("expensesDeduction", {
        is: true,
        then: (schema) => schema.required("La città è un campo obbligatorio"),
        otherwise: (schema) => schema,
      }),
      zipCode: yup.string().when("expensesDeduction", {
        is: true,
        then: (schema) => schema.required("Il cap è un campo obbligatorio"),
        otherwise: (schema) => schema,
      }),
      country: yup.string().when("expensesDeduction", {
        is: true,
        then: (schema) => schema.required("Lo stato è un campo obbligatorio"),
        otherwise: (schema) => schema,
      }),
      taxId: yup.string().when("expensesDeduction", {
        is: true,
        then: (schema) =>
          schema.required("Il codice fiscale è un campo obbligatorio"),
        otherwise: (schema) => schema,
      }),
      dateOfBirth: yup
        .date()
        .nullable()
        .transform((value, originalValue) => {
          return originalValue ? new Date(originalValue) : null;
        })
        .min(minDate, "Data troppo lontana")
        .max(maxDate, "Deve essere maggiorenne")
        .required("La data di nascita è un campo obbligatorio"),
      expensesDeduction: yup.boolean(),
    }),
    onSubmit: (values) => {
      saveBillingDetails({
        givenName: values.givenName,
        familyName: values.familyName,
        address: values.address,
        city: values.city,
        cap: values.zipCode,
        country: values.country,
        taxId: values.taxId.toUpperCase(),
        dateOfBirth: values.dateOfBirth as any,
        expensesDeduction: values.expensesDeduction as boolean
      });
    },
  });

  useEffect(() => {
    if (getBillingDetailsResponse.data) {
      formik.setValues({
        givenName:
          getBillingDetailsResponse.data.billingDetails.given_name ?? "",
        familyName:
          getBillingDetailsResponse.data.billingDetails.family_name ?? "",
        address: getBillingDetailsResponse.data.billingDetails.address ?? "",
        city: getBillingDetailsResponse.data.billingDetails.city ?? "",
        zipCode: getBillingDetailsResponse.data.billingDetails.cap ?? "",
        country: getBillingDetailsResponse.data.billingDetails.country ?? "",
        taxId: getBillingDetailsResponse.data.billingDetails.taxId ?? "",
        dateOfBirth:
          (getBillingDetailsResponse.data.billingDetails.dateOfBirth as any) ??
          null,
        expensesDeduction:
          getBillingDetailsResponse.data.expensesDeduction ?? false,
      });
    }
    if (getBillingDetailsResponse?.data?.billingDetails.dateOfBirth) {
      const dateOfBirth = new Date(
        getBillingDetailsResponse?.data?.billingDetails.dateOfBirth
      );
      setDateOfBirth(dateOfBirth);
    }
  }, [getBillingDetailsResponse.data]);


  const cancelSaveBillingDetails = async () => {
    if (getBillingDetailsResponse.isSuccess) {
      formik.setValues({
        givenName:
          getBillingDetailsResponse.data.billingDetails.given_name ?? "",
        familyName:
          getBillingDetailsResponse.data.billingDetails.family_name ?? "",
        address: getBillingDetailsResponse.data.billingDetails.address ?? "",
        city: getBillingDetailsResponse.data.billingDetails.city ?? "",
        zipCode: getBillingDetailsResponse.data.billingDetails.cap ?? "",
        country: getBillingDetailsResponse.data.billingDetails.country ?? "",
        taxId: getBillingDetailsResponse.data.billingDetails.taxId ?? "",
        dateOfBirth:
          (getBillingDetailsResponse.data.billingDetails.dateOfBirth as any) ??
          null,
        expensesDeduction:
          getBillingDetailsResponse.data.expensesDeduction ?? false,
      });
    }
    if (getBillingDetailsResponse?.data?.billingDetails.dateOfBirth) {
      const dateOfBirth = new Date(
        getBillingDetailsResponse?.data?.billingDetails.dateOfBirth
      );
      setDateOfBirth(dateOfBirth);
    }
  };

  return (
    <form className={isModal ? styles.widget : ''}
          onSubmit={formik.handleSubmit}>
      <div className={styles.billingDetailsForm}>
        <InfoBox
          title='Inserisci i dati per la fattura'
          variant='infoPayment'
          text={userInfo?.therapyType &&
            <p className={styles.infoPaymentText}>
              {userInfo?.therapyType === 'single' ? 'Completa i seguenti campi con i dati di fatturazione. Assicurati che corrispondano a quelli della persona che parteciperà alla seduta.'
                : userInfo?.therapyType === 'underage' ? 'Completa i seguenti campi con i dati di fatturazione del minore che partecipa alla seduta. Invece, se desideri effettuare la detrazione fiscale a nome del genitore/tutore inserisci i dati di chi effettua il pagamento.'
                  : userInfo?.therapyType === 'couple' ? 'Completa i seguenti campi con i dati di fatturazione della persona che partecipa alla seduta. Invece, se desideri effettuare la detrazione fiscale a nome del partner inserisci i dati di chi effettua il pagamento.' : ''
              }
            </p>
          }
        />
        <div className={styles.billingDetailsInputContainer}>
          <Input
            className={styles.billingDetailsInput}
            label="Nome"
            type="text"
            name="givenName"
            value={formik.values.givenName}
            onChange={(e) => {
              formik.setFieldValue('givenName', e.target.value);
              formik.setFieldTouched('givenName', true);
            }}
            error={
              (formik.errors.givenName &&
                formik.touched.givenName &&
                formik.errors.givenName) as string
            }
          />
          <Input
            className={styles.billingDetailsInput}
            label="Cognome"
            type="text"
            name="familyName"
            value={formik.values.familyName}
            onChange={formik.handleChange}
            error={
              (formik.errors.familyName &&
                formik.touched.familyName &&
                formik.errors.familyName) as string
            }
          />
        </div>
        <div className={styles.billingDetailsInputContainer}>
          <Input
            className={styles.billingDetailsInput}
            label="Indirizzo e numero civico"
            type="text"
            name="address"
            value={formik.values.address}
            onChange={formik.handleChange}
            error={
              (formik.errors.address &&
                formik.touched.address &&
                formik.errors.address) as string
            }
          />
          <Input
            className={styles.billingDetailsInput}
            label="Città"
            type="text"
            name="city"
            value={formik.values.city}
            onChange={formik.handleChange}
            error={
              (formik.errors.city &&
                formik.touched.city &&
                formik.errors.city) as string
            }
          />
        </div>
        <div className={styles.billingDetailsInputContainer}>
          <Input
            className={styles.billingDetailsInput}
            label="CAP"
            type="text"
            name="zipCode"
            value={formik.values.zipCode}
            onChange={formik.handleChange}
            error={
              (formik.errors.zipCode &&
                formik.touched.zipCode &&
                formik.errors.zipCode) as string
            }
          />
          <Input
            className={styles.billingDetailsInput}
            label="Stato"
            type="text"
            name="country"
            value={formik.values.country}
            onChange={formik.handleChange}
            error={
              (formik.errors.country &&
                formik.touched.country &&
                formik.errors.country) as string
            }
          />
        </div>
        <Input
          className={styles.billingDetailsInput}
          label="Codice fiscale"
          type="text"
          name="taxId"
          value={formik.values.taxId.toUpperCase()}
          onChange={formik.handleChange}
          error={
            (formik.errors.taxId &&
              formik.touched.taxId &&
              formik.errors.taxId) as string
          }
        />
        <div className={styles.dateWrapper}>
          <div
            className={`${styles.label} ${
              formik.errors.dateOfBirth &&
              formik.touched.dateOfBirth &&
              formik.errors.dateOfBirth
                ? styles.errorLabel
                : ""
            }`}
          >
            Data di nascita
          </div>
          <DatePicker
            locale={it}
            inputReadOnly={isMobile}
            value={dateOfBirth ? dayjs(dateOfBirth) : null}
            onChange={handleDateChange}
            disabledDate={disableDate}
            defaultPickerValue={dayjs(maxDate)}
            placement="topRight"
            showNow={false}
            format={{
              format: 'DD/MM/YYYY',
              type: !isMobile ? 'mask' : undefined,
            }}
            status={formik.errors.dateOfBirth && formik.touched.dateOfBirth ? "error" : ""}
          />
          <div
            className={`${styles.label} ${
              formik.errors.dateOfBirth &&
              formik.touched.dateOfBirth &&
              formik.errors.dateOfBirth
                ? styles.errorLabel
                : ""
            }`}
          >
            {formik.errors.dateOfBirth && formik.touched.dateOfBirth && (
              formik.errors.dateOfBirth as string
            )}
          </div>
        </div>
      </div>

      <div className={styles.expensesDeductionCheckboxContainer}>
        <Checkbox
          checked={formik.values.expensesDeduction}
          name="expensesDeduction"
          onChange={(e) =>
            formik.setValues({
              ...formik.values,
              expensesDeduction: e.target.checked,
            })
          }
        />
        <div>
          <p className={styles.boldText}>
            Vuoi detrarre le spese delle sedute?
          </p>
          <p className={styles.checkboxText}>
            Sì, acconsento alla condivisione di queste informazioni con il
            Sistema Tessera Sanitaria (STS). L'invio dei dati al STS permette,
            se risiedi in Italia, di richiedere la detrazione delle spese.
          </p>
        </div>
      </div>
      <div className={`${styles.formButtonsContainer} ${isModal && styles.modal}`}>
        <Button
          type="button"
          onClick={cancelSaveBillingDetails}
          variant="secondary"
        >
          Annulla
        </Button>
        <Button type="submit">Salva</Button>
      </div>
    </form>
  );
};
